import React, { useMemo } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

function FormFooter(props) {
    const { className, submitBtnTitle, onCancel = () => {}, handleConfirm = () => {}, placement } = props
    const { t: ln_t } = useTranslation()

    const place = useMemo(() => (placement ? `justify-content-${placement}` : 'justify-content-end'), [placement])
    return (
        <div className={`${className} d-flex align-items-center ${place}`}>
            <Button type="button" className="mr-2" onClick={() => onCancel()}>{ln_t('util.cancel')}</Button>
            <Button type="primary" htmlType="submit" onClick={() => handleConfirm()}>{submitBtnTitle}</Button>
        </div>
    )
}

export default FormFooter
