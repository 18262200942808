import { useState, useEffect } from 'react'
  
const useParams = (extraInitParams = {}, isReady = true) => {
    const initFetch = {
        maxResult: 10,
        page: 1,
        sort: '-updatedAt',
        ...extraInitParams
    }

    const initTable = {
        showSizeChanger: false,
        showQuickJumper: false,
        pageSize: initFetch.maxResult || 0,
        current: 1,
        total: 0,
    }
    
    const [tableParams, setTableParams] = useState(initTable)
    const [fetchParams, setFetchParams] = useState(initFetch)
    const [list, setList] = useState([])
    const [ready, setReady] = useState(isReady)
    const [updatedRowId, setUpdatedRowId] = useState(-1)
    const [selectedItem, setSelectedItem] = useState([])
    const [searchParams, setSearchParams] = useState()

    const handleTableParams = (type, payload = {}) => {
        if (type === 'init') setTableParams({ ...initTable, ...payload })
        if (type === 'patch') setTableParams({ ...tableParams, ...payload })
    }
        
    const handleFetchParams = (type, payload = {}) => {
        if (type === 'init') setFetchParams({ ...initFetch, ...payload })
        if (type === 'initPatch') setFetchParams({ ...fetchParams, ...initFetch, ...payload })
        if (type === 'patch') setFetchParams({ ...fetchParams, ...payload })
    }

    const deletedCallback = () => {
        const n = {}
        if (fetchParams?.startIndex && list?.length === 1) {
            n.startIndex = fetchParams.startIndex - fetchParams.maxResults
        }
        handleFetchParams('patch', n)
    }

    const patchListRow = (record = {}, patchParams = {}, key = '') => {
        const index = list?.findIndex(item => item[key] === record[key])
        // console.log(list, record, index)
        if (index !== -1) {
            const n = [...list]
            n[index] = { ...record, ...patchParams }
            // console.log(record, patchParams, {...record, ...patchParams})
            setList([...n])
            setUpdatedRowId(record[key])
        }
    }

    useEffect(() => {
        if (updatedRowId !== -1) {
            const t = setTimeout(() => setUpdatedRowId(-1), 10000)
            return () => clearTimeout(t)
        }
    }, [updatedRowId])
  
    return {
        tableParams, 
        fetchParams, 
        list, 
        ready, 
        updatedRowId,
        selectedItem,
        searchParams,
        handleTableParams, 
        handleFetchParams, 
        setList, 
        patchListRow, 
        setReady,
        setUpdatedRowId,
        deletedCallback,
        setSelectedItem,
        setSearchParams
    }
}

export default useParams
