import { CaretLeftFilled } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

function NewsPreview(props) {
    const { ln_t, location, history } = props
    const { data } = location.state
    const [previewContent, setPreviewContent] = useState()

    const handleBack = () => history.push('/affiliate/news')

    useEffect(() => {
        if (!data?.news?.content) history.push('/affiliate/news')
        setPreviewContent(data.news.content)
    }, [])

    return (
        <>
            <div className="news-preview-header">
                <div className="title">{data?.news?.title}</div>
                <Button type="text" className="back-button" onClick={() => handleBack()}>{ln_t('news.back')}</Button>
            </div>
            <div className="news-preview">
                <div dangerouslySetInnerHTML={{ __html: previewContent }} />
                <div id="news-preview" />
            </div>
        </>
    )
}

export default NewsPreview
