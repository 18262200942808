import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Modal, Button } from 'antd'
import { pick } from 'lodash'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import { getTransactionDetails } from '../../../apis/payment'
import { fetchOrderDetails } from '../../../apis/order'

const formatAccount = account => {
    const l = account.length
    return account.split('').map((item, index) => {
        if (((index + 1) % 4) || index + 1 === l) return item
        return `${item} - `
    }).join('')
}

function TranscationDetailTable(props) {
    const { ln_t, userInfo, isModalVisible, setModalVisible, changeModal, dataParam, setIsLoading, download } = props
    const { nowEnterprise } = userInfo || {}

    const params = useParams({ sort: '-confirmedDate', ...dataParam })

    const { fetchParams, handleFetchParams } = params

    const handleDownload = async params => {
        setIsLoading(true)
        const result = await fetchOrderDetails(nowEnterprise?.enterprise, { sort: '-updatedAt', maxResult: 1000, ...params })
        if (result?.status === 200) {
            const formatDate = date => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '')
            const formattedResult = result.data.results.map(({ orderDate, expectedToConfirm, createdAt, updatedAt, withdrawalDate, confirmedDate, ...other }) => ({
                ...other,
                orderDate: formatDate(orderDate),
                expectedToConfirm: formatDate(expectedToConfirm),
                createdAt: formatDate(createdAt),
                updatedAt: formatDate(updatedAt),
                withdrawalDate: formatDate(withdrawalDate),
                confirmedDate: formatDate(confirmedDate),
            }))
            const neededCols = ['ouid', 'transactionId', 'orderDate', 'orderStatus', 'product', 
                'description', 'amount', 'referralRate', 'referralAmount', 'expectedToConfirm', 'createdAt', 'updatedAt', 
                'withdrawalDate', 'status', 'confirmedDate', 'affiliateBankAccount', 'confirmedByName']
            const customNaming = { ouid: ln_t('affiliate.id'), name: ln_t('affiliate.name') }
            const colNames = Object.assign({}, ...neededCols.map(col => ({ [col]: customNaming[col] || ln_t(`order.${col}`) })))
            const n = formattedResult.map(item => pick(item, neededCols))
            download([[colNames, ...n]], `${ln_t('payment.tabMenu.paymentHistory')}_${ln_t('payment.orderDetails')}`, true)
        }
        setIsLoading(false)
    }

    const columns = [
        {
            title: ln_t('affiliate.id'),
            dataIndex: 'ouid',
        },
        {
            title: ln_t('affiliate.accountName'),
            dataIndex: 'bankAccountName',
        },
        {
            title: ln_t('payment.bank'),
            dataIndex: 'bank',
        },    
        {
            title: ln_t('payment.branchCode'),
            dataIndex: 'branchCode',
        },            
        {
            title: ln_t('payment.bankAccount'),
            dataIndex: 'affiliateBankAccount',
            render: (e, record) => (e ? formatAccount(e) : '-')
        },
        {
            title: ln_t('payment.referralAmount'),
            dataIndex: 'referralAmount',
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('payment.withdrawalDate'),
            dataIndex: 'withdrawalDate',
            type: 'time',
            format: 'MM/DD/YYYY'
        },    
        {
            title: ln_t('payment.orderDetails'),
            dataIndex: 'orderCount',
            render: (e, record) => (
                e === 0 ? '' 
                    : (
                        <div className="primary-link" onClick={() => changeModal(ln_t('payment.orderDetail'), 'orderDetail', { affiliate: record.affiliate, withdrawalDate: record.withdrawalDate })}>
                            {e} {ln_t('payment.detailUnit')}
                        </div>
                    )),
        },                      
        {
            title: ln_t('util.download'),
            dataIndex: 'orderCount',
            render: (e, record) => (
                e === 0 ? '-' 
                    : (
                        <div className="primary-link" onClick={() => handleDownload({ affiliate: record.affiliate, withdrawalDate: record.withdrawalDate })}>
                            {ln_t('util.download')}
                        </div>
                    )),
        },                  
    ]

    const apiFunc = async () => getTransactionDetails(nowEnterprise?.enterprise, fetchParams)

    return (
        <MainTable 
            {...props}
            className="transcation-detail"
            tableName="transcationDetail"
            columns={columns}
            rowKey="transcationDetailId"
            apiFunc={apiFunc}
            params={params}
        />
    )
}

export default TranscationDetailTable
