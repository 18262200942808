import React, { useState } from 'react'
import { Form, Input, DatePicker, Select, Button } from 'antd'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

function SearchForm(props) {
    const [form] = Form.useForm()
    const { ln_t, history, params, className, categories } = props
    const { handleFetchParams, fetchParams } = params

    const getStatusParams = mode => {
        if (mode === 'all') return { isPublished: undefined, campaignStart_before: undefined, campaignEnd_after: undefined, campaignEnd_before: undefined }
        if (mode === 'published' || mode === 'draft') return { isPublished: mode === 'published' }
        const today = moment().format('YYYY-MM-DD')
        const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD')
        if (mode === 'active') return { campaignStart_before: today, campaignEnd_after: today }
        return { campaignEnd_before: yesterday }
    }

    const onFinish = fieldValues => {
        const { dateRange = [], isPublished, ...other } = fieldValues
        const format = time => time.format('YYYY-MM-DD')

        const [dateForm, dateTo] = dateRange
        const dateFilter = dateForm && dateTo ? { campaignStart_after: format(dateForm), campaignEnd_before: format(dateTo) } : {}

        handleFetchParams('patch', { ...other, ...getStatusParams(isPublished), ...dateFilter })
    }

    return (
        <>
            <Form 
                form={form}
                className={`d-flex flex-wrap round-selector align-items-start ${className}`}
                onFinish={onFinish}
            >
                <Form.Item name="title__in" label={ln_t('news.title')}>
                    <Input placeholder={`${ln_t('util.search')}...`} allowClear className="mb-1 mr-2" style={{ width: '240px' }} />
                </Form.Item>
                <Form.Item label={ln_t('news.date')} name="dateRange" className="mb-1 mr-2">
                    <RangePicker ranges={{
                        '1 week': [moment().subtract(1, 'week'), moment()],
                        '1 month': [moment().subtract(1, 'month'), moment()],
                        '3 months': [moment().subtract(3, 'months'), moment()],
                        '6 months': [moment().subtract(6, 'months'), moment()],
                        '1 year': [moment().subtract(364, 'days'), moment()],
                    }}
                    />
                </Form.Item>
                <Form.Item name="isPublished" label={ln_t('news.status')} style={{ marginRight: '10px' }}>
                    <Select defaultValue="all" style={{ width: 120 }}>
                        <Option value="all">{ln_t('news.all')}</Option>
                        <Option value="published">{ln_t('news.published')}</Option>
                        <Option value="draft">{ln_t('news.draft')}</Option>
                        <Option value="active">{ln_t('news.active')}</Option>
                        <Option value="expired">{ln_t('news.expired')}</Option>
                    </Select>
                </Form.Item>
                <button className="main-button mb-2" type="submit">{ln_t('util.search')}</button>
                <button className="main-button mb-2" type="button" onClick={() => history.push('/enterprise/setting/news/new')} style={{ marginLeft: 'auto' }}>
                    {ln_t('news.addNews')}
                </button>
            </Form>
            <div style={{ display: 'flex', gap: '8px' }}>
                <div>{ln_t('news.category')} :</div>
                <div style={{ display: 'flex', gap: '8px', marginBottom: '10px', flexWrap: 'wrap' }}>
                    {[{ id: undefined, label: ln_t('news.all') }, ...categories || []]?.map(i => (
                        <div
                            key={i.id}
                            className={`rounded-tag ${fetchParams?.category === i.id ? 'active' : ''}`}
                            onClick={() => handleFetchParams('patch', { category: i.id })}
                        >
                            {i.label}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default SearchForm
