import React from 'react'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import { fetchWithdrawList } from '../../../apis/affiliateWeb'
import SearchForm from './SearchForm'

function WithdrawRecord(props) {
    const { ln_t, userInfo } = props
    const { nowEnterprise, roleId } = userInfo || {}

    const params = useParams({ sort: '-requestedDate', maxResult: 8 })
    const { fetchParams } = params

    const columns = [
        {
            title: ln_t('earning.withdraw.requestedDate'),
            dataIndex: 'requestedDate',
            sorter: true,
        },
        {
            title: ln_t('earning.withdraw.bankAccount'),
            dataIndex: 'affiliateBankAccount',
            sorter: true,
            render: e => (e ? `********${e.slice(e.length - 4)}` : '-')
        },
        {
            title: ln_t('earning.withdraw.amount'),
            dataIndex: 'amount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('earning.withdraw.status'),
            dataIndex: 'status',
            sorter: true,
            render: e => ln_t(`util.orderStatus.${e}`)
        },
    ]

    const apiFunc = async () => fetchWithdrawList(roleId, nowEnterprise?.enterprise, fetchParams)

    return (
        <MainTable 
            {...props}
            className="withdraw-list"
            tableName="withdraw"
            columns={columns}
            rowKey="requestedDate"
            apiFunc={apiFunc}
            params={params}
            useHeader
            headerRender={(
                <SearchForm 
                    {...props} 
                    {...params} 
                    dateConf={{
                        label: ln_t('earning.withdraw.requestedDate'),
                        name: 'withdrawalDate'
                    }}
                    statusOptions={[
                        { value: 'ALL', label: ln_t('util.status.all') },
                        { value: 'REQUESTED', label: ln_t('util.orderStatus.REQUESTED') },
                        { value: 'PAID', label: ln_t('util.orderStatus.PAID') },
                    ]}
                />
            )}
        />
    )
}

export default WithdrawRecord
