import React from 'react'
import '../Main.less'

function Terms(props) {
    const { size } = props
    return (
        <section className={`rules-wrapper ${size}`}>
            <h5 className="mb-4 text-center">隱私權政策</h5>
            <ol className="parent">
                <li className="level-1">...
                    <ol>
                        <li>...</li>
                        <li>...</li>
                        <li>...</li>
                        <li>...</li>
                    </ol>
                </li>
                <li className="level-1">...
                    <ol>
                        <li>...
                            <ol>
                                <li>...</li>
                                <li>...</li>
                            </ol>
                        </li>
                        <li>...</li>
                    </ol>
                </li>
                <li className="level-1">...
                    <ol>
                        <li>...</li>
                        <li>...</li>
                    </ol>
                </li>
            </ol>
        </section>
    )
}

export default Terms
