import React, { useState } from 'react'
import { Input, Form, message, Button, Modal, Spin } from 'antd'
import { useParams } from 'react-router-dom'
import { setNewPassword } from '../../apis/auth'
import { ROUTELOGIN } from '../../constants/Routing'
import '../Enterprise/Setting/UserProfile.less'

function ForgetPassword(props) {
    const [form] = Form.useForm()

    const { token, uidb64 } = useParams()
    const { history, ln_t, modalConf, passwordFormat } = props

    const [isLoading, setIsLoading] = useState(false)
    
    const onFinish = async fieldValues => {
        setIsLoading(true)
        if (!token || !uidb64) message.info('message.info.noPermission')
        else {
            const params = {
                newPassword: window.btoa(fieldValues.newPassword),
                token,
                uidb64
            }
            const result = await setNewPassword(params)
            if (result?.status === 200) {
                Modal.info({
                    ...modalConf,
                    content: ln_t('message.success.resetPass'),
                    onOk: () => history.push(ROUTELOGIN[result?.data?.role || 'affiliate']),
                })
            } else message.error(result?.data?.message || 'server error')
        }
        setIsLoading(false)
    }

    const validation = {
        password: [{ required: true, message: ln_t('user.validation.newPassword') }, { pattern: passwordFormat, message: ln_t('user.validation.matchPattern') }],
        confirm: [{ required: true, message: ln_t('user.validation.confirmAgain') }],
    }

    return (
        <div className="reset-password">
            <div className="profile-inner">
                <Spin spinning={isLoading}>
                    <div className="lndata-logo cursor-pointer" onClick={() => history.push(ROUTELOGIN.affiliate)}>
                        <img src="/lndata_logo.png" alt="Lndata Logo" />
                    </div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        hideRequiredMark
                        validateTrigger="onChange"
                        labelAlign="left"
                        layout="vertical"
                    >
                        <Form.Item label={ln_t('user.form.newPassword')} name="newPassword" rules={[...validation.password]}>
                            <Input.Password
                                placeholder={validation.password[0].message} 
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            label={ln_t('user.form.confirmAgain')}
                            name="confirm"
                            rules={[...validation.confirm, ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(ln_t('message.error.pleaseMatchNewPass'))
                                },
                            })]}
                        >
                            <Input.Password
                                placeholder={validation.confirm[0].message} 
                                autoComplete="off"
                            />
                        </Form.Item>
                        <div>
                            <span>{ln_t('user.password.length')}<br />
                                {ln_t('user.password.format')}
                            </span>
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-5">
                            <Button type="primary" htmlType="submit">{ln_t('util.confirm')}</Button>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    )
}

export default ForgetPassword
