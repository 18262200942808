import React from 'react'
import { Form, DatePicker } from 'antd'
import moment from 'moment'

const { RangePicker } = DatePicker

function SearchForm(props) {
    const [form] = Form.useForm()

    const { ln_t, handleFetchParams, className } = props

    const onFinish = fieldValues => {
        const { confirmedDate } = fieldValues
        let n = {}
        const format = time => time.format('YYYY-MM-DD')
        if (confirmedDate?.length === 2) {
            const [dateForm, dateTo] = confirmedDate
            n = { confirmedDate_after: format(dateForm), confirmedDate_before: format(dateTo) }
        }
        handleFetchParams('init', n)
    }

    return (
        <Form 
            form={form}
            className={`d-flex flex-wrap round-selector ${className}`}
            onFinish={onFinish}
        >
            <Form.Item label={ln_t('payment.paymentDate')} name="confirmedDate" className="mb-1 mr-2">
                <RangePicker ranges={{
                    '1 week': [moment().subtract(1, 'week'), moment()],
                    '1 month': [moment().subtract(1, 'month'), moment()],
                    '3 months': [moment().subtract(3, 'months'), moment()],
                    '6 months': [moment().subtract(6, 'months'), moment()],
                    '1 year': [moment().subtract(364, 'days'), moment()],
                }}
                />
            </Form.Item>
            <button className="main-button mb-2" type="submit">{ln_t('util.search')}</button>
        </Form>
    )
}

export default SearchForm
