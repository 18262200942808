import React from 'react'
import { fetchValuableAffiliates } from '../../../apis/stats'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import SearchForm from './SearchForm'

function ValuableAffiliate(props) {
    const params = useParams({ sort: '-completedOrderCount' })
    const { ln_t, userInfo } = props
    const { nowEnterprise } = userInfo || {}
    const { fetchParams } = params

    const columns = [
        {
            title: ln_t('affiliate.id'),
            dataIndex: 'ouid',
        },
        {
            title: ln_t('affiliate.name'),
            dataIndex: 'name',
        },
        {
            title: ln_t('affiliate.completedOrderCount'),
            dataIndex: 'completedOrderCount',
            sorter: true,
            type: 'number',
        },
        {
            title: ln_t('affiliate.completedOrderConversionRate'),
            dataIndex: 'completedOrderConversionRate',
            sorter: true,
            render: e => `${e.toFixed(2).replace('.00', '')}%`
        },
        {
            title: ln_t('affiliate.tentativeOrderCount'),
            dataIndex: 'tentativeOrderCount',
            sorter: true,
            type: 'number',
        },   
        {
            title: ln_t('affiliate.tentativeOrderConversionRate'),
            dataIndex: 'tentativeOrderConversionRate',
            sorter: true,
            render: e => `${e.toFixed(2).replace('.00', '')}%`
        },   
        {
            title: ln_t('affiliate.clickCount'),
            dataIndex: 'clickCount',
            sorter: true,
            type: 'number',
        },     
    ]

    const apiFunc = async () => fetchValuableAffiliates(nowEnterprise?.enterprise, fetchParams)  

    return (
        <section>
            <h4 className="mb-4">{ln_t('dashboard.mostValuableAffiliates')}</h4>
            <MainTable 
                {...props}
                className="valuable-list"
                tableName="valuableAff"
                columns={columns}
                rowKey="ouid"
                apiFunc={apiFunc}
                params={params}
                useHeader
                headerRender={<SearchForm {...props} {...params} />}
            />
        </section>
    )
}

export default ValuableAffiliate
