import React from 'react'
import { Tabs } from 'antd'
import Personal from './Personal'
import Business from './Business'

function Affiliate(props) {
    const { ln_t } = props
    
    const tabMenu = [
        {
            title: ln_t('affiliate.tabMenu.personal'), 
            key: 'personal', 
            render: <Personal {...props} />
        },
        {
            title: ln_t('affiliate.tabMenu.business'), 
            key: 'business', 
            render: <Business {...props} />
        },
    ]

    return (
        <div className="card-container">
            <Tabs defaultActiveKey="personal" type="card">
                {tabMenu.map(({ key, title, render }) => (
                    <Tabs.TabPane key={key} tab={title}>
                        {render}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    )
}

export default Affiliate
