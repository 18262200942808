import { Divider } from 'antd'
import React, { useState, useEffect } from 'react'
import { fetchActiveEvent, fetchCampaignPromo } from '../../apis/affiliateWeb'

function ActiveEvent(props) {
    const { userInfo, ln_t, history, location } = props
    const { roleId, nowEnterprise } = userInfo || {}
    const { name } = nowEnterprise || {}
    const { enterprise } = nowEnterprise || {}

    const { state } = location || {}
    const { eventClosed } = state || {}

    const [datas, setDatas] = useState([])
    const [close, setClose] = useState(eventClosed)
    const [events, setEvents] = useState([])

    const getDatas = async () => {
        const results = await fetchActiveEvent(roleId, enterprise)
        const { keys, response } = results || {}
        if (Array.isArray(response)) {
            let tmp = []
            response.forEach((res, index) => {
                if (res?.status !== 200) return
                const key = keys[index]
                const { results } = res.data || {}
                if (!index) {
                    tmp = results?.[0]?.products?.map(item => ({ product: item.product_id, [key]: item.referralRate })) || []
                    setEvents(results.filter(res => res.name !== 'default'))
                } else tmp = tmp.map(item => ({ ...item, [key]: res.data?.find(pro => pro.product === item.product)?.referralRate }))
            })
            // tmp.pop() // remove later -> request from ezTravel to hide the domestic travel temporarily
            setDatas(tmp)
        }
    }

    useEffect(() => {
        getDatas()
    }, []) 

    return (
        <section className="active-event">
            <Divider>{ln_t('enterprise.currentRate')}</Divider>
            <div className="inner">
                {datas.map(data => {
                    const { product, defaultRate, nowRate } = data
                    const higher = nowRate > defaultRate
                    return (
                        <div 
                            key={`${product}-event`} 
                            className="d-flex justify-content-between w-100 mb-2 align-items-center product-item"
                        >
                            <span>{product}</span>
                            <div>
                                <span className={higher ? 'lower' : 'higher'}>{defaultRate}%</span>
                                {higher ? <span className="ml-2 higher">{nowRate}%</span> : ''}
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default ActiveEvent
