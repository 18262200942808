import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Spin, message, Modal } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Link, useParams, withRouter } from 'react-router-dom'
import { AFFILIATE, ROUTELOGIN } from '../../../constants/Routing'
import '../../Login/Login.less'
import { checkAffiliate } from '../../../apis/auth'
import useCheckEnterprise from '../../../hook/useCheckEnterprise'
import Logo from '../../../components/Affiliate/Logo'

function CheckEmail(props) {
    const { location, history, ln_t, langOptions, changeLang, modalConf } = props

    const { enterpriseName } = useParams()
    const enterpriseId = useCheckEnterprise(enterpriseName, history)

    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm()
    const { pathname } = location || {}

    const replacePath = string => pathname.replace(/checkEmail/, string)
    const goLogin = () => history.push(replacePath('login'))

    const onFinish = async fieldsValue => {
        setLoading(true)
        const { username: email } = fieldsValue
        const postData = {
            email,
            enterpriseId
        }

        const result = await checkAffiliate(postData)
        if (result?.status === 200) {
            const { status, errorCode } = result.data
            if (status === 'failed') {
                if (errorCode === 410) history.push({ pathname: replacePath('newUser'), state: { email } })
                if (errorCode === 420) {
                    Modal.info({
                        ...modalConf,
                        content: ln_t('message.info.emailAlreadyUnderEnterprisePleaseLogin'),
                        onOk: () => goLogin()
                    })
                }
            } else {
                Modal.info({
                    ...modalConf,
                    content: ln_t('message.success.addEnterpriseUserPleaseLogin'),
                    onOk: () => goLogin()
                })
            }
        } else message.info(ln_t('errorCode.500'))
        setLoading(false)
    }

    return (
        <main className="login-container">
            <section className="login-inner">
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <div className="logo-box cursor-pointer" onClick={() => goLogin()}>
                    <Logo {...props} />
                </div>
                <div className="title-text">{ln_t('login.createUser')}</div>
                <Spin spinning={loading}>  
                    <Form
                        form={form}
                        size="large"
                        onFinish={onFinish}
                        hideRequiredMark
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: ln_t('login.enterUsername') }]}
                        >
                            <Input 
                                autoComplete="off" 
                                prefix={<UserOutlined className="mr-2" />} 
                                placeholder={ln_t('login.username')} 
                                inputMode="email"
                            />
                        </Form.Item>

                        <div className="link mt-3 mb-5">
                            {ln_t('login.alreadyHaveAccount')}
                            <Link to={replacePath('login')}>
                                {ln_t('login.login')}
                            </Link>
                        </div>

                        <div className="login-btn-container">
                            <Button type="button" htmlType="submit">{ln_t('util.next')}</Button>
                        </div>
                    </Form>
                </Spin>
            </section>
        </main>
    )
}

export default withRouter(CheckEmail)
