import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { useParams, withRouter } from 'react-router-dom'
import { ENTERPRISE, ROUTELOGIN } from '../../constants/Routing'
import RuleAndTerms from '../../components/Terms/Main'
import useCheckEnterprise from '../../hook/useCheckEnterprise'
import '../Login/Login.less'

function DetailRules(props) {
    const { history, ln_t, langOptions, changeLang } = props

    const { enterpriseName } = useParams()
    useCheckEnterprise(enterpriseName, history, true, ENTERPRISE)

    const [activeTab, setActiveTab] = useState('brandRules')

    const onFinish = () => history.goBack()

    const onTabChange = key => setActiveTab(key)

    return (
        <main className="login-container">
            <section className="login-inner large">
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <RuleAndTerms activeKey={activeTab} onTabChange={onTabChange} />
                <div className="text-center mt-5">
                    <Button type="button" onClick={() => onFinish()}>{ln_t('util.goBack')}</Button>
                </div>
            </section>
        </main>
    )
}

export default withRouter(DetailRules)
