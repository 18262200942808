import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ENTERPRISE, ROUTEBASE } from '../constants/Routing'
import AccountManage from './Enterprise/Setting/AccountManage' 
import ProductManage from './Enterprise/Setting/ProductManage' 
import UserProfile from './Enterprise/Setting/UserProfile'
import ModPassword from './Enterprise/Setting/ModPassword'
import ModName from './Enterprise/Setting/ModName'
import NotFoundPage from './NotFoundPage/NotFoundPage'
import OrderList from './Enterprise/Order/Main'
import Traffic from './Enterprise/Traffic/Main'
import Payment from './Enterprise/Payment/Main'
import Affiliate from './Enterprise/Affiliate/Main'
import BankVerify from './Enterprise/IdVerify/Main'
import Dashboard from './Enterprise/Dashboard/Main'
import Campaign from './Enterprise/Campaign/Main'
import NewsEditor from './Enterprise/News/NewsEditor'
import News from './Enterprise/News/Main'
import NewsPreview from './Enterprise/News/Preview'

export default function EnterprisePages(props){
    const { userInfo } = props
    const { isAdmin } = userInfo || {}

    return (
        <Switch>
            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/dashboard`}
                render={(value => <Dashboard {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/affiliate`}
                render={(value => <Affiliate {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/order`}
                render={(value => <OrderList {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/traffic`}
                render={(value => <Traffic {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/payment`}
                render={(value => <Payment {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/idVerification`}
                render={(value => <BankVerify {...props} {...value} />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/user-profile`}
                render={(value => <UserProfile {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/user-profile/mod-password`}
                render={(value => <ModPassword {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[ENTERPRISE]}/user-profile/mod-name`}
                render={(value => <ModName {...props} {...value} />)}
            />

            {isAdmin 
                ? (
                    <>
                        <Route
                            exact
                            path={`${ROUTEBASE[ENTERPRISE]}/setting/user`}
                            render={(value => <AccountManage {...props} {...value} />)}
                        />
                        <Route
                            exact
                            path={`${ROUTEBASE[ENTERPRISE]}/setting/product`}
                            render={(value => <ProductManage {...props} {...value} />)}
                        />
                        <Route
                            exact
                            path={`${ROUTEBASE[ENTERPRISE]}/setting/campaign`}
                            render={(value => <Campaign {...props} {...value} />)}
                        />
                        <Route
                            exact
                            path={`${ROUTEBASE[ENTERPRISE]}/setting/news`}
                            render={(value => <News {...props} {...value} />)}
                        />
                        <Route
                            exact
                            path={`${ROUTEBASE[ENTERPRISE]}/setting/news/new`}
                            render={(value => <NewsEditor {...props} {...value} />)}
                        />
                        <Route
                            exact
                            path={`${ROUTEBASE[ENTERPRISE]}/setting/news/preview`}
                            render={(value => <NewsPreview {...props} {...value} />)}
                        />
                        <Route
                            exact
                            path={`${ROUTEBASE[ENTERPRISE]}/setting/news/edit`}
                            render={(value => <NewsEditor {...props} {...value} editMode />)}
                        />
                    </>
                ) 
                : ''}

            <Route path="*" render={() => <NotFoundPage />} />
        </Switch>
    )
}
