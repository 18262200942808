import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router'
import { Button, DatePicker, Divider, Form, Input, message, Modal, Select, Space, Switch, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
import { convertToHTML } from 'draft-convert'
import { convertPlugins } from './editor/objToHtml'
import InlineEditor from './editor/Editor'
import useDetectReload from '../../../hook/useDetectReload'
import { addNews, addNewsCategory, fetchNewsCategory, modNews } from '../../../apis/news'

const { RangePicker } = DatePicker

function NewsEditor(props) {
    const { ln_t, location, editMode = false, history, userInfo } = props
    const { nowEnterprise } = userInfo || {}
    const { blockNavigation, setBlockNavigation } = useDetectReload()
    const [form] = Form.useForm()
    const [contentStorage, setContentStorage] = useState()
    const [objBeforeConvert, setObjBeforeConvert] = useState()
    const [fieldsData, setFieldsData] = useState({ descriptions: '', isDeleted: false, enterprise: nowEnterprise?.enterprise })
    const [fields, setFields] = useState({})
    const [files, setFiles] = useState(false)
    const [readyToSubmit, setReadyToSubmit] = useState(false) 
    const [newsCat, setNewsCat] = useState([]) 
    const [newCat, setNewCat] = useState() 
    const [addCatLoading, setAddCatLoading] = useState(false) 
    const apiAddNews = async (enterpriseId, params) => addNews(enterpriseId, params)
    const apiModify = async (enterpriseId, id, params) => modNews(enterpriseId, id, params)

    const handlePreviewContent = () => {
        localStorage.setItem('preview', JSON.stringify({ html: fieldsData.content }))
        window.open('/enterprise/setting/news/preview')
    }

    const checkCompleteness = () => {
        if (fieldsData?.title && fieldsData.campaignEnd && fieldsData.campaignStart && fieldsData.descriptions) return true
        message.error(ln_t('message.error.newsNotComplete'))
        return false
    }

    const onSave = async isPublished => {
        setBlockNavigation(false)
        const reqBody = { ...fieldsData, isPublished }
        setFieldsData(reqBody)
        if (files?.length) {
            await Promise.all(files.map(async (obj, index, arr) => {
                const [tmpUrl, file] = Object.entries(obj)[0]
                const reader = new FileReader()
                if (!file) return
                reader.readAsDataURL(file)
                reader.onloadend = function () {
                    const base64data = reader.result
                    setFieldsData(prev => ({ ...prev, content: prev.content.replace(tmpUrl, base64data) }))
                    index === (files.length - 1) ? setReadyToSubmit(true) : ''
                }
            }))
        } else setReadyToSubmit(true)
    }

    const handleSave = async (isPublished = false) => {
        if (!checkCompleteness()) return
        if (isPublished) {
            Modal.confirm({
                centered: true,
                icon: false,
                title: ln_t('news.confirmPublish'),
                okText: ln_t('util.confirm'),
                cancelText: ln_t('util.cancel'),
                onOk: () => onSave(isPublished)
            })
        } else onSave(isPublished)
    }

    const handleSaveNew = async () => {
        const result = await apiAddNews(nowEnterprise?.enterprise, fieldsData)
        if (result.status === 201) {
            message.info(ln_t('message.success.addNews'))
            history.push('/enterprise/setting/news')
            setBlockNavigation(true)
        }
    }

    const handleEdit = async id => {
        const result = await apiModify(nowEnterprise?.enterprise, id, fieldsData)
        if (result.status === 200) {
            message.info(ln_t('message.success.edit'))
            history.push('/enterprise/setting/news')
            setBlockNavigation(true)
        }
    }

    useEffect(() => {
        if (!readyToSubmit) return
        editMode ? handleEdit(location.state.data.id) : handleSaveNew()
        setReadyToSubmit(false)
    }, [readyToSubmit])

    const handleFieldChange = (field, value) => {
        let newObj = {}
        if (field === 'date') {
            const format = (time, start) => {
                const clock = start ? { h: 0, m: 0, s: 0 } : { h: 23, m: 59, s: 0 }
                const temp = moment(time, 'YYYY-MM-DD HH:mm:ss ZZ').set(clock)
                return moment(temp, 'YYYY-MM-DD', true).format()
            }
            if (value?.length === 2) {
                const [startDate, endDate] = value
                newObj = { ...fieldsData, campaignStart: format(startDate, true), campaignEnd: format(endDate, false) }
            }
        } else {
            newObj = { ...fieldsData, [field]: value }
        }
        setFieldsData(newObj)
        return newObj
    }

    const getCategoryOpt = async () => {
        const res = await fetchNewsCategory(nowEnterprise?.enterprise, { maxResult: 999 })
        if (res?.status === 200) setNewsCat(res?.data?.results?.map(i => ({ value: i.id, label: i.label })))
    }
    const addCategory = async e => {
        e.preventDefault()
        if (!newCat || newCat === '') return
        setAddCatLoading(true)
        const res = await addNewsCategory(nowEnterprise?.enterprise, { label: newCat })
        if (res?.status === 201) {
            getCategoryOpt()
            setNewCat('')
        }
        setAddCatLoading(false)
    }

    useEffect(() => {
        if (!contentStorage) return
        const html = convertToHTML(convertPlugins.toHtml)(objBeforeConvert)
        handleFieldChange('content', html.replace(/ {4}/gi, '&emsp;'))
    }, [contentStorage])

    useEffect(() => {
        getCategoryOpt()
        setBlockNavigation(true)
        if (!editMode) return
        if (!location?.state?.data) history.push('/enterprise/setting/news')
        const format = time => moment(time, 'YYYY-MM-DD')
        const { campaignStart, campaignEnd, category, ...editData } = location.state.data
        const dateStart = format(campaignStart)
        const dateEnd = format(campaignEnd)
        const newFields = Object.keys(editData).map(key => ({ name: [key], value: editData[key] }))
        setFields([...newFields, { name: ['date'], value: [dateStart, dateEnd] }, { name: ['category'], value: category?.id }])
        setFieldsData({ ...location.state.data, campaignStart: dateStart, campaignEnd: dateEnd, category: category?.id })
    }, [])

    const disabledDate = date => date.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')

    const selectorRender = menu => (
        <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <div style={{ padding: '0 8px 4px', display: 'flex', gap: '4px' }}>
                <Input
                    placeholder={ln_t('news.categoryName')}
                    value={newCat}
                    onChange={e => setNewCat(e.target.value)}
                    onKeyDown={e => e.stopPropagation()}
                    style={{ width: '100%' }}
                />
                <Button type="text" icon={<PlusOutlined />} onClick={addCategory} style={{ display: 'flex', alignItems: 'center' }} loading={addCatLoading}>
                    {ln_t('news.addCategory')}
                </Button>
            </div>
        </>
    )

    return (
        <>
            <Prompt
                when={blockNavigation}
                message={ln_t('news.leaveAlert')}
            />
            <Form 
                labelCol={{ span: 3 }}
                className="news-form"
                form={form}
                fields={fields}
            >
                <div className="editor-buttons">
                    <Form.Item name="isPinned">
                        <Switch checked={fieldsData.isPinned} checkedChildren={ln_t('news.pinToTop')} unCheckedChildren={ln_t('news.pinToTop')} onChange={e => handleFieldChange('isPinned', e)} />
                    </Form.Item>
                    <Button type="link" onClick={() => history.push('/enterprise/setting/news')}>{ln_t('news.back')}</Button>
                    <Button type="link" onClick={() => handlePreviewContent(true)}>{ln_t('news.preview')}</Button>
                    {location?.state?.data?.isPublished ? '' : (
                        <Button type="link" onClick={() => handleSave()}>{ln_t('news.saveAsDraft')}</Button>
                    )}
                    <Button type="link" onClick={() => handleSave(true)}>{ln_t('news.saveAndPublish')}</Button>
                </div>
                <Form.Item name="title" label={ln_t('news.title')}>
                    <Input placeholder={ln_t('news.title')} allowClear className="mr-2" onChange={e => handleFieldChange('title', e.target.value)} />
                </Form.Item>
                <Form.Item name="descriptions" label={ln_t('news.description')}>
                    <TextArea allowClear onChange={e => handleFieldChange('descriptions', e.target.value)} />
                </Form.Item>
                <Form.Item label={ln_t('news.date')} name="date" className="mr-2">
                    <RangePicker
                        ranges={{
                            '1 week': [moment(), moment().add(1, 'week')],
                            '1 month': [moment(), moment().add(1, 'month')],
                            '3 months': [moment(), moment().add(3, 'months')],
                            '6 months': [moment(), moment().add(6, 'months')],
                            '1 year': [moment(), moment().add(364, 'days')],
                        }}
                        style={editMode ? { backgroundColor: '#efefef' } : {}}
                        onChange={e => handleFieldChange('date', e)}
                        disabledDate={disabledDate} 
                        disabled={editMode}
                    />
                </Form.Item>
                <Form.Item label={ln_t('news.category')} name="category" className="mr-2">
                    <Select
                        placeholder={ln_t('news.category')}
                        options={newsCat}
                        onChange={e => handleFieldChange('category', e)}
                        disabledDate={disabledDate} 
                        dropdownRender={selectorRender}
                    />
                </Form.Item>
                <InlineEditor 
                    contentStorage={contentStorage} 
                    setContentStorage={setContentStorage}
                    setObjBeforeConvert={setObjBeforeConvert}
                    editMode={editMode}
                    fieldsData={location?.state?.data}
                    postId={1}
                    // editStorage={editStorage}
                    files={files}
                    setFiles={setFiles}
                />
            </Form>
        </>
    )
}

export default NewsEditor
