import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import UnpaidList from './UnpaidList'
import PaidList from './PaidList'
import './tab.less'

const { TabPane } = Tabs

function Payment(props) {
    const { history, ln_t, location, userInfo } = props
    const { nowEnterprise } = userInfo || {}
    const { enterpriseId } = nowEnterprise || {}
    const [selectedItem, setSelectedItem] = useState([])

    const { state } = location || {}
    const { subTab: tab } = state || {}

    const [subTab, setSubTab] = useState()

    const onTabChange = key => {
        setSelectedItem([])
        setSubTab(key)
        history.replace({ state: { ...state, subTab: key } })
    }

    const tabMenu = [
        {
            title: ln_t('payment.tabMenu.newRequest'), 
            key: 'newRequest', 
            render: <UnpaidList {...props} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        },
        {
            title: ln_t('payment.tabMenu.paymentHistory'), 
            key: 'paymentHistory', 
            render: <PaidList {...props} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        },
    ]

    useEffect(() => {
        setSubTab(tab || 'newRequest')
    }, [])

    return (
        <div className="card-container">
            <Tabs activeKey={subTab} onChange={onTabChange} type="card">
                {tabMenu.map(item => {
                    const { key, title, render } = item || {}
                    return (
                        <TabPane key={key} tab={title}>
                            {key === subTab ? render : ''}
                        </TabPane>
                    )
                })}
            </Tabs>
        </div>
    )
}

export default Payment
