import React from 'react'
import './Table.less'
import { useTranslation } from 'react-i18next'

function TableFooter(props) {
    const { tableParams } = props
    const { t: ln_t } = useTranslation()

    return (
        Number(tableParams.total) 
            ? (
                <section className="list-count">
                    {`${ln_t('util.from')} ${(tableParams.current - 1) * tableParams.pageSize + 1} ${ln_t('util.to')} 
          ${(tableParams.current * tableParams.pageSize) > tableParams.total
                    ? tableParams.total : (tableParams.current * tableParams.pageSize)} ${ln_t('util.row')} 
          / ${ln_t('util.total')} ${tableParams.total} ${ln_t('util.rows')}`}
                </section>
            ) : ''
    )
}

export default TableFooter
