import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Spin, message, Modal } from 'antd'
import { useParams, withRouter } from 'react-router-dom'
import { AFFILIATE, ROUTELOGIN } from '../../../constants/Routing'
import { getOtp, verifyOtp } from '../../../apis/auth'
import useCheckEnterprise from '../../../hook/useCheckEnterprise'
import '../../Login/Login.less'

function OtpVerify(props) {
    const { location, history, ln_t, langOptions, changeLang, modalConf } = props

    const { enterpriseName } = useParams()
    useCheckEnterprise(enterpriseName, history)
    
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm()
    const { pathname, state } = location || {}
    const { email } = state || {}

    const replacePath = string => pathname.replace(/otpVerify/, string)
    const goLogin = () => history.push(replacePath('login'))

    const onFinish = async fieldsValue => {
        setLoading(true)
        const { otp } = fieldsValue
        const postData = {
            email,
            otp
        }

        const result = await verifyOtp(postData)
        if (result?.status === 200) {
            Modal.info({
                ...modalConf,
                content: ln_t('message.success.otpVerifythenLogin'),
                onOk: () => goLogin()
            })    
        } else message.info(result?.data.message || ln_t('errorCode.500'))

        setLoading(false)
    }

    const resend = async () => {
        const result = await getOtp({ email })
        if (result?.status === 200) message.info(ln_t('message.success.getOtp'))
        else message.info(result?.data.message || ln_t('message.error.serverError'))
    }

    useEffect(() => {
        if (!email) history.push(ROUTELOGIN[AFFILIATE])
    }, [])

    return (
        <main className="login-container">
            <section className="login-inner">
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <h2>{ln_t('user.verifyEmail')}</h2>
                <h6>{ln_t('message.info.otpAlreadySendTo')}{email}</h6>
                <h6>{ln_t('message.info.insertSixOtpNumber')}</h6>
                <Spin spinning={loading}>  
                    <Form
                        form={form}
                        size="large"
                        onFinish={onFinish}
                        hideRequiredMark
                    >
                        <Form.Item
                            name="otp"
                            rules={[{ required: true, message: ln_t('user.validation.otp') }]}
                            style={{ marginTop: '28px' }}
                        >
                            <Input 
                                autoComplete="off" 
                                placeholder={ln_t('user.validation.otp')} 
                                inputMode="numeric"
                            />
                        </Form.Item>
                        <div className="link mt-4 primary d-flex justify-content-end" onClick={() => resend()}>
                            {ln_t('login.resend')}
                        </div>

                        <div className="login-btn-container">
                            <Button type="button" htmlType="submit">{ln_t('util.confirm')}</Button>
                        </div>
                    </Form>
                </Spin>
            </section>
        </main>
    )
}

export default withRouter(OtpVerify)
