import React, { useRef, useEffect, useState } from 'react'
import { Input, Modal } from 'antd'
import { AlignCenterOutlined, AlignLeftOutlined, EditOutlined } from '@ant-design/icons'
import useResizeObserver from '@react-hook/resize-observer'
import styled from 'styled-components'

const AlignBar = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 3px;
    border: 1px solid #cccccc;
    display: none;
    align-items: center;
    .anticon {
      padding: 6px;
      cursor: pointer;
      transition: .4s;

      &:not(:last-child) {
        border-right: 1px solid #cccccc;
      }

      &:nth-child(1) {
        background-color: ${({ alignment }) => (alignment === 'left' ? '#f1f1f1' : 'transparent')};
      }

      &:nth-child(2) {
        background-color: ${({ alignment }) => (alignment === 'center' ? '#f1f1f1' : 'transparent')};
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }
`

const Wrapper = styled.div`
`

const Inner = styled.div`
    resize: both;
    border: 1px solid transparent;
    overflow: hidden;
    max-width: 100%;
    border-radius: 3px;
    position: relative;

    &:hover {
      border: 1px solid #cccccc;

      ${AlignBar} {
        display: flex;
      }
    }
`

const TitleWrapper = styled.div`
    font-size: 15px;
    color: #3E3E3E;
    text-align: center;
    width: fit-content;
    margin-top: .6rem;
    line-height: 1.4rem;

    .anticon {
        margin-left: 6px;
    }
`

function ResizeableLayout(props) {
    const { block, contentState, size, setSize, data, children, title, setTitle } = props

    const divRef = useRef()
    const titleRef = useRef('')
    const iconRef = useRef()
    const [alignment, setAlignment] = useState('center')

    const handleAlign = align => setAlignment(align)

    const handleContentChange = (obj = {}) => (
        contentState.replaceEntityData(block.getEntityAt(0), { ...data, ...obj })
    )

    const openModal = () => {
        const modal = Modal.confirm()
        modal.update({
            icon: false,
            centered: true,
            content: (
                <Input 
                    defaultValue={title} 
                    // eslint-disable-next-line no-return-assign
                    onChange={e => titleRef.current = e.target.value}
                    onPressEnter={e => {
                        setTitle(titleRef.current)
                        // handleContentChange({ title: titleRef.current })
                        modal.destroy()
                    }}
                />
            ),
            onOk: () => {
                setTitle(titleRef.current)
            // handleContentChange({ title: titleRef.current })
            }
        })
    }

    // useResizeObserver(divRef, entry => {
    //     const { height, width } = entry.contentRect
    //     console.log(height, width)
    //     console.log(divRef?.current?.offsetWidth, divRef?.current?.offsetHeight)
    //     // setSize({ height, width })
    // })

    useEffect(() => {
        handleContentChange({ size, alignment, title })
    // titleRef.current = title
    }, [size, alignment, title])

    useEffect(() => {
        const div = document.querySelectorAll('button.bc4rxid')
        if (div[5]) {
            div[5].click()
            setTimeout(() => div[5].click(), 10)
        }
    }, [title])

    useEffect(() => {
        if (data.size) setSize(data.size)
        if (data.alignment) setAlignment(data.alignment)
        if (data.title) setTitle(data.title)
    }, [data])

    const styleProps = {
        width: size.width, 
        margin: alignment === 'center' ? '0 auto' : '0'
    }

    return (
        <Wrapper>
            <Inner
                ref={divRef}
                style={{ ...size, ...styleProps }}
            >
                <AlignBar alignment={alignment}>
                    <AlignLeftOutlined onClick={() => handleAlign('left')} />
                    <AlignCenterOutlined onClick={() => handleAlign('center')} />
                </AlignBar>
                {children}
            </Inner>
            <TitleWrapper style={{ ...styleProps, margin: alignment === 'center' ? '.6rem auto 0' : '.6rem 0 0' }}>
                <span>{title || 'Edit iframe title'}</span>
                <EditOutlined ref={iconRef} onClick={() => openModal()} />
            </TitleWrapper>
        </Wrapper>
    )
}

export default ResizeableLayout
