import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const fetchEnterpriseUserProfile = async (enterpriseId, userId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/users/${userId}/`, MethodType.GET, params)

export const changePassword = async (enterpriseId, userId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/users/${userId}/change_password/`, MethodType.POST, params)

export const modUser = async (enterpriseId, userId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/users/${userId}/`, MethodType.PATCH, params)

export const fetchEnterpriseUsers = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/users/`, MethodType.GET, params)

export const addEnterpriseUser = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/users/`, MethodType.POST, params)

export const deleteEnterpriseUser = async (enterpriseId, userId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/users/${userId}/`, MethodType.DELETE, params)

export const fetchEnterpriseById = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/`, MethodType.GET, params)
