import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import { message, ConfigProvider } from 'antd'
import en from 'antd/lib/locale/en_US'
import zhTW from 'antd/lib/locale/zh_TW'
import { AFFILIATE, ENTERPRISE, ROUTEBASE, ROUTEBRAND, ROUTELOGIN, validEnterprises } from '../constants/Routing'
import { USERPROFILE } from '../constants/ActionTypes'
import AppContainer from '../components/Layout/AppContainer'
import Login from './Login/Login'
import EnterprisePages from './EnterprisePages'
import AffiliatePages from './AffiliatePages'
import SwitchBrand from './Affiliate/Switch'
import ForgetPassword from './ForgetPassword/ForgetPassword'
import CreateUser from './Affiliate/CreateUser/CreateUser'
import CheckAffiliateEmail from './Affiliate/CreateUser/CheckEmail'
import OtpVerify from './Affiliate/CreateUser/OtpVerify'
import Terms from './Affiliate/CreateUser/Terms'
import { handleDownload } from '../utils/downloadExcel'
import ConfirmRules from './Enterprise/ConfirmRules'
import DetailRules from './Enterprise/DetailRules'
import AccountType from './Affiliate/CreateUser/AccountType'
import TaxInformation from './Affiliate/CreateUser/TaxInformation'
import LoginNotFound from './Login/LoginNotFound'

const equal = (obj1, obj2) => obj1 === obj2
const language = ['en', 'zh-TW']

const antLang = {
    en,
    'zh-TW': zhTW
}

function App(props) {
    const err = useSelector(state => state.handleError)
    const userInfo = useSelector(state => state.userProfile, isEqual)
    const auth = useSelector(state => state.checkAuth, equal)

    const [width, setWidth] = useState(window.innerWidth)

    const dispatch = useDispatch()
    const { t: ln_t, i18n } = useTranslation()
    const { pathname } = window?.location || {}

    const popupConf = {
        okText: ln_t('util.confirm'),
        cancelText: ln_t('util.cancel')
    }

    const langOptions = language.filter(item => item !== (userInfo?.lang || 'zh-TW'))
        .map(value => ({ label: ln_t(`util.language.${value}`), value }))

    const changeLang = lang => {
        i18n.changeLanguage(lang)
        dispatch({ type: USERPROFILE.PATCH, payload: { lang } })
    }  
    
    const modalConf = {
        centered: true,
        icon: false,
        okText: ln_t('util.confirm'),
        cancelText: ln_t('util.cancel')
    } 

    const passwordFormat = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/g

    const download = (datas, fileTitle, customColNames) => {
        if (!datas?.length) message.info(ln_t('message.info.noDataToDownload'))
        else {
            const now = moment().format('YYYY-MM-DD HH:mm:ss')
            const fileName = `${fileTitle}_${now}`
            const sheets = [fileTitle]
            handleDownload(sheets, datas, fileName, customColNames)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
    }, [window.innerWidth]) 

    useEffect(() => {    
        changeLang(userInfo?.lang)
    }, [userInfo?.lang]) 

    const docConf = {
        eztravel: { title: ln_t('document.eztravel.title'), desc: ln_t('document.eztravel.desc') },
        def: { title: 'Lndata | Affiliate', desc: 'Lndata Affiliate' }
    }
    useEffect(() => {
        const key = pathname.includes('enterprise') ? 'def' : pathname.includes(ROUTEBRAND.EzTravel) || userInfo?.nowEnterprise.name === 'EzTravel' ? 'eztravel' : 'def'
        document.title = docConf[key].title
        if (document.getElementsByTagName('meta')?.description?.content) {
            document.getElementsByTagName('meta').description.content = docConf[key].desc
        }
    }, [pathname, userInfo])

    const routeProps = {
        ...props,
        dispatch,
        userInfo,
        popupConf,
        ln_t,
        width,
        langOptions,
        changeLang,
        modalConf,
        passwordFormat,
        auth,
        download
    }
  
    return (
        <ConfigProvider locale={antLang[userInfo?.lang]}>
            <Router>
                <Switch>
                    <Redirect exact path="/enterprise" to={ROUTELOGIN[ENTERPRISE]} />
                    <Redirect exact path="/:path" to={ROUTELOGIN[AFFILIATE]} />
                    <Route exact path="/user/resetpass/:token/:uidb64" render={(value => <ForgetPassword {...routeProps} {...value} />)} />
                    <Route exact path={ROUTELOGIN[ENTERPRISE]} render={(value => <Login {...routeProps} {...value} />)} />
                    <Route exact path="/affiliate/login" render={(value => <LoginNotFound {...routeProps} {...value} />)} />
                    <Route exact path="/affiliate/login/:enterpriseName" render={(value => <Login {...routeProps} {...value} />)} />

                    <Route exact path="/affiliate/checkEmail/:enterpriseName" render={(value => <CheckAffiliateEmail {...routeProps} {...value} />)} />
                    <Route exact path="/affiliate/newUser/:enterpriseName" render={(value => <CreateUser {...routeProps} {...value} />)} />
                    <Route exact path="/affiliate/otpVerify/:enterpriseName" render={(value => <OtpVerify {...routeProps} {...value} />)} />
                    <Route exact path="/affiliate/accountType/:enterpriseName" render={(value => <AccountType {...routeProps} {...value} />)} />
                    <Route exact path="/affiliate/taxInformation/:enterpriseName" render={(value => <TaxInformation {...routeProps} {...value} />)} />
                    <Route exact path="/affiliate/terms/:enterpriseName" render={(value => <Terms {...routeProps} {...value} />)} />

                    <Route exact path="/enterprise/terms" render={(value => <ConfirmRules {...routeProps} {...value} />)} />
                    <Route exact path="/enterprise/detailRules/:enterpriseName" render={(value => <DetailRules {...routeProps} {...value} />)} />

                    {userInfo?.role === 'affiliate' 
                        ? (
                            <Route 
                                exact 
                                path={`${ROUTEBASE[AFFILIATE]}/switch`} 
                                render={(value => <SwitchBrand {...routeProps} {...value} />)}
                            />
                        ) : ''}

                    <AppContainer {...routeProps} err={err} i18n={i18n}>
                        {userInfo?.role === 'enterprise' 
                            ? (
                                <Route 
                                    path={ROUTEBASE[ENTERPRISE]} 
                                    render={(value => <EnterprisePages {...routeProps} {...value} />)}
                                />
                            ) : ''}
                        {userInfo?.role === 'affiliate' 
                            ? (
                                <Route 
                                    path={ROUTEBASE[AFFILIATE]} 
                                    render={(value => <AffiliatePages {...routeProps} {...value} />)}
                                />
                            ) : ''}
                    </AppContainer>
                </Switch>
            </Router>
        </ConfigProvider>
    )
}

export default App
