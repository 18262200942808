import React, { useEffect, useState } from 'react'

function NewsPreview(props) {
    const { ln_t } = props
    const [previewContent, setPreviewContent] = useState({})

    useEffect(() => {
        const jsonStr = localStorage.getItem('preview')
        if (!jsonStr) return
        setPreviewContent(JSON.parse(jsonStr))
    }, [])

    const { tmpBannerImage, title, html } = previewContent || {}

    return (
        <div className="news-preview">
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    )
}

export default NewsPreview
