import React, { useEffect } from 'react'

function Logo(props) {
    const { location } = props
    const { pathname } = location || {}

    const enterprises = [
        { name: 'ezTravel', logo: '/eztravel_logo.png' },
        { name: 'lionTravel', logo: '/liontravel_logo.png' }
    ]

    return (
        <img
            className="adx-logo"
            src={enterprises.find(item => pathname.includes(item.name))?.logo || '/lndata_logo.png'}
            alt="Enterprise Logo"
        />
    )
}

export default Logo
