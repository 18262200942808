import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const fetchCampaignByType = async (enterpriseId, type, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/campaignSetting/?type=${type}`, MethodType.GET, params)

export const fetchRateNow = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/rateNow/`, MethodType.GET, params)

export const addCampaign = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/campaign/`, MethodType.POST, params)

export const deleteCampaign = async (enterpriseId, campaignId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/campaign/${campaignId}/`, MethodType.DELETE, params)

export const modCampaign = async (enterpriseId, campaignId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/campaign/${campaignId}/`, MethodType.PATCH, params)

export const fetchAllCampaignByEnterprise = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/campaign/?maxResult=2000`, MethodType.GET, params)
