import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const fetchProductSummary = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/dashboard/productSummary/`, MethodType.GET, params)

export const fetchValuableAffiliates = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/dashboard/mostValuableAffiliate/`, MethodType.GET, params)

export const fetchAffiliateProduct = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/dashboard/affiliateVsProduct/`, MethodType.GET, params)

export const fetchOrderTrending = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/dashboard/monthlyOrder/?maxResult=100`, MethodType.GET, params)

export const fetchClicksTrending = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/dashboard/clickTrend/`, MethodType.GET, params)

export const fetchAffiliateTrending = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/dashboard/affiliateTrend/`, MethodType.GET, params)
