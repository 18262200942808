import React from 'react'
import { useTranslation } from 'react-i18next'
import './NotFoundPage.less'

function NotFoundPage(props) {
    const { t: ln_t } = useTranslation()
    return (
        <section className="not-found-container">
            <span>Oh oh</span>
            <span>{ln_t('util.notFoundPage')}</span>
        </section>
    )
} 

export default NotFoundPage
