import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { Button, DatePicker, Input, InputNumber, message, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import { addCampaign, fetchCampaignByType, modCampaign } from '../../../apis/campaign'

function UpcomingCamp(props) {
    const { ln_t, userInfo, setIsLoading, subTab, colTitle, modalConf } = props
    const { nowEnterprise, lang } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const params = useParams({ maxResult: 1000 }, false)
    const { fetchParams, setList, list, setReady, ready } = params

    const editValueRef = useRef()
    const listRef = useRef([])
    const colRef = useRef([])
    const editColRef = useRef()

    const [editCol, setEditCol] = useState()
    const [editValues, setEditValues] = useState()
    const [columns, setColumns] = useState([])

    const defaultCols = [
        {
            title: ln_t('campaign.product'),
            dataIndex: 'product',
            fixed: 'left',
        },
    ]    

    const apiFunc = async () => fetchCampaignByType(enterprise, subTab, fetchParams)

    const setValues = (key, value) => {
        const n = editValueRef.current || {}
        n[key] = value
        editValueRef.current = n
        setEditValues({ ...n })
    }

    const disabledDate = current => current.format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')

    const editableCol = res => {
        const { name, startDate, endDate, campaignId } = res
        const title = (
            <div className="d-flex fd-col align-items-center">
                <Input 
                    defaultValue={campaignId === 'newEvent' ? undefined : name} 
                    size="small" 
                    style={{ width: '200px', marginBottom: '6px' }}
                    onChange={e => setValues('name', e?.target?.value)}
                    placeholder={name}
                />
                <DatePicker.RangePicker 
                    defaultValue={[startDate, endDate]} 
                    size="small" 
                    onChange={v => setValues('dateRange', v)}
                    disabledDate={disabledDate}
                />
            </div>
        )
        return {
            width: 250,
            title,
            dataIndex: campaignId,
            render: (e, record) => (
                <>
                    <InputNumber 
                        defaultValue={e} 
                        min={Number(record?.default?.replace('%', '')) || 1}  
                        style={{ width: '70px' }} 
                        className="mr-2" 
                        size="small"
                        onChange={v => setValues(record.product, v)}
                        inputMode="decimal"
                    />
                    %
                </>
            )
        }
    }

    const handleEdit = res => {
        const { campaignId, name, startDate, endDate } = res
        if (editColRef.current) return message.info(ln_t('message.info.finishCurrentEditFirst'))
        setEditCol(campaignId)
        const newEvent = campaignId === 'newEvent'

        const newCol = editableCol(res)
        const newList = listRef.current.map(item => ({
            ...item,
            [campaignId]: Number(item[newEvent ? 'default' : campaignId].replace('%', ''))
        }))
        
        const initValues = {
            name: newEvent ? undefined : name,
            dateRange: [startDate, endDate],
            ...(Object.assign({}, ...newList.map(item => ({ [item.product]: item[campaignId] }))))
        }

        editValueRef.current = initValues
        setEditValues(initValues)
        setList(newList)

        if (newEvent) setColumns([...colRef.current, newCol])
        else {
            const tmp = colRef.current
            const index = tmp.findIndex(item => item.dataIndex === campaignId)
            tmp[index] = newCol
            setColumns([...tmp])
        }
    }

    const getCustomList = async (loading = true) => {
        loading && setIsLoading(true)
        const result = await apiFunc()
        if (result?.status === 200) {
            const { results } = result.data || {}
            const { products } = results[0]

            const n = products.map(product => {
                const { id, product_id } = product
                const arr = results.map(camp => {
                    const { name, campaignId, products: pros } = camp
                    const campMatchProduct = pros.find(pro => pro.product_id === product_id) || {}

                    return { [name === 'default' ? 'default' : campaignId]: `${campMatchProduct.referralRate}%` }
                })
                const obj = Object.assign({}, ...arr)
                return {
                    productId: id,
                    product: product_id,
                    ...obj
                }
            })

            const c = results.map(res => ({
                width: 200,
                title: colTitle(res, { 
                    deleteCallback: getCustomList, 
                    handleEdit: () => handleEdit({ ...res, startDate: moment(res.startDate), endDate: moment(res.endDate) })
                }),
                dataIndex: res.name === 'default' ? 'default' : res.campaignId,
            }))
            setColumns([...defaultCols, ...c])
            setList(n)
        }
        loading && setIsLoading(false)
    }

    const handleAdd = () => {
        const tmpRes = { 
            name: ln_t('campaign.newEventName'), 
            startDate: moment().add(1, 'day'), 
            endDate: moment().add(31, 'days'),
            campaignId: 'newEvent',
        }

        handleEdit(tmpRes)
    }

    const handleCancel = (loading = true) => {
        setEditCol()
        editValueRef.current = null
        setEditValues()
        getCustomList(loading)
    }

    const onFinish = async () => {
        if (Object.keys(editValues)?.find(key => !editValues[key])) return message.info(ln_t('message.info.pleaseInsertEveryInput'))

        const add = editCol === 'newEvent'
        const { name, dateRange, ...others } = editValues
        const [startDate, endDate] = dateRange
        const productsReferralRate = Object.keys(others).map(key => ({
            product: key,
            [add ? 'rate' : 'referralRate']: others[key]
        }))
        const n = { 
            name, 
            productsReferralRate, 
            startDate, 
            endDate 
        }

        Modal.confirm({
            ...modalConf,
            title: `${add ? ln_t('util.confirmAdd') : ln_t('util.confirmEdit')} ${name}？`,
            onOk: async () => {
                const result = add ? await addCampaign(enterprise, n) : await modCampaign(enterprise, editCol, n)
                const arr = [200, 201]
                if (arr.includes(result?.status)) handleCancel()        
            }
        })
    }

    useEffect(() => {
        setReady(true)
    }, [])

    useEffect(() => {
        listRef.current = list
    }, [list])

    useEffect(() => {
        colRef.current = columns
    }, [columns])

    useEffect(() => {
        editColRef.current = editCol
    }, [editCol])

    useEffect(() => {
        if (!ready) return
        if (editCol) handleCancel(false)
        else getCustomList(false)
    }, [lang])
    
    return (
        <>
            <MainTable 
                {...props}
                className="campaign-history"
                tableName="campHistory"
                columns={columns}
                rowKey="product"
                apiFunc={apiFunc}
                params={params}
                getCustomList={getCustomList}
                noFooter
                noPage
            />
            <div className="d-flex justify-content-end mt-4">
                {!editCol ? (
                    <Button 
                        className="d-flex align-items-center" 
                        onClick={() => handleAdd()}
                        disabled={editCol}
                    >
                        <PlusOutlined />
                        {ln_t('campaign.addNewEvent')}
                    </Button>
                ) : (
                    <>
                        <Button 
                            className="mr-2" 
                            onClick={() => handleCancel()}
                        >
                            {ln_t('util.cancel')}
                        </Button>
                        <Button onClick={() => onFinish()}>
                            {ln_t('util.confirm')}
                        </Button>
                    </>
                )}
            </div>
        </>
    )
}

export default UpcomingCamp
