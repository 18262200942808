import React, { useEffect, useRef, useState } from 'react'
import ResizeableLayout from './ResizeableLayout'

function ImageBlock(props) {
    const { block, contentState } = props
    const imageRef = useRef()
    const [data, setData] = useState(contentState.getEntity(block.getEntityAt(0)).getData())
    const [size, setSize] = useState({ width: 350, height: 200 })
    const [title, setTitle] = useState('')

    // useDebounce(() => {
    //   frameRef.current.src = data.html
    // }, 700, [size])

    useEffect(() => {
        const img = imageRef.current
        const onLoad = () => {
            const heightRatio = img.naturalHeight / img.naturalWidth
            setSize({ width: size?.width, height: size.width * heightRatio })
        }

        img.addEventListener('load', onLoad)

        return () => { img.removeEventListener('load', onLoad) }
    }, [])

    return (
        <ResizeableLayout 
            {...props} 
            size={size} 
            data={data} 
            setSize={setSize} 
            setData={setData} 
            title={title} 
            setTitle={setTitle}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
        >
            <img
                // width={size.width - 10}
                // height={size.height - 10}
                style={{ minWidth: '100%', flexShrink: 0 }}
                ref={imageRef}
                src={data.src}
                role="presentation"
                alt={title || 'presentation'}
            />
        </ResizeableLayout>
    )
}

export default ImageBlock
