import React, { useEffect, useState } from 'react'
import { Form, Button, Spin, message, } from 'antd'
import { useParams, withRouter } from 'react-router-dom'
import { AFFILIATE, ROUTELOGIN } from '../../../constants/Routing'
import useCheckEnterprise from '../../../hook/useCheckEnterprise'
import '../../Login/Login.less'
import Selector from '../../../components/Util/Selector'
import { modAffiliateTax } from '../../../apis/affiliateWeb'
import { USERPROFILE } from '../../../constants/ActionTypes'

function AccountType(props) {
    const { location, history, ln_t, langOptions, changeLang, dispatch, userInfo } = props
    const { isBusiness } = userInfo
    const { pathname, state } = location || {}
    const { email, affiliate } = state
    const role = pathname?.split('/')?.[1]
    const { enterpriseName } = useParams()
    const enterpriseId = useCheckEnterprise(enterpriseName, history, role === 'affiliate')
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    
    const replacePath = string => pathname.replace(/accountType/, string)

    const onFinish = async fieldsValue => {
        setLoading(true)
        const result = await modAffiliateTax(affiliate.id, enterpriseId, fieldsValue)
        if (result?.status === 200) {
            dispatch({ type: USERPROFILE.PATCH, payload: { ...fieldsValue } })
            history.push({ pathname: replacePath('taxInformation'), state })
        } else message.info(result?.data.message || ln_t('message.error.serverError'))
        setLoading(false)
    }

    useEffect(() => {
        if (!email || !affiliate) history.push(ROUTELOGIN[AFFILIATE])
        form.setFieldsValue({ isBusiness })
    }, [])

    return (
        <main className="login-container">
            <section className="login-inner">
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <h2>{ln_t('user.accountType')}</h2>
                <h6>{ln_t('message.info.accountType')}</h6>
                <Spin spinning={loading}>  
                    <Form
                        form={form}
                        size="large"
                        onFinish={onFinish}
                        hideRequiredMark
                    >
                        <Form.Item
                            name="isBusiness"
                            rules={[{ required: true, message: ln_t('user.accountType') }]}
                            style={{ marginTop: '28px' }}
                        >
                            <Selector 
                                placeholder={ln_t('user.accountType')} 
                                options={[
                                    { value: false, label: ln_t('user.personalAccount') },
                                    { value: true, label: ln_t('user.businessAccount') }
                                ]}
                                showSearch={false}
                            />
                        </Form.Item>

                        <div className="login-btn-container">
                            <Button type="button" htmlType="submit">{ln_t('util.confirm')}</Button>
                        </div>
                    </Form>
                </Spin>
            </section>
        </main>
    )
}

export default withRouter(AccountType)
