import React, { useEffect } from 'react'
import { Button } from 'antd'
import { addAffiliateBank, modAffiliate, modAffiliateTax } from '../../../apis/affiliateWeb'
import '../Profile.less'

function ConfirmValues(props) {
    const { userInfo, setIsLoading, ln_t, location, history } = props
    const { roleId, nowEnterprise } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const { state } = location || {}
    const { profileValues = {}, bankValues = {}, isBusiness } = state || {}

    const pushStep = step => history.push({ pathname: `/affiliate/bank/add/${step}`, state })

    const onFinish = async () => {
        setIsLoading(true)
        const n = {
            ...bankValues,
            affiliate: roleId,
            enterprise
        }
        const result1 = await addAffiliateBank(roleId, enterprise, n)
        if (result1?.status === 201) {
            const result2 = await modAffiliateTax(roleId, enterprise, profileValues)
            if (result2?.status === 200) {
                history.push('/affiliate/bank/add/step4')
            }    
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (!state) return history.push('/affiliate/account/request')
    }, [])

    return (
        <div className="affiliate-profile h-100">
            <div className="profile-form d-flex fd-col justify-content-between h-100">
                <div>
                    <h4 className="mb-5">{ln_t('bank.yourBankAndPersonalInfo')}</h4>
                    {Object.keys(bankValues).filter(key => key !== 'bankCode').map(key => (
                        <div key={key} className="d-flex align-items-center mb-2">
                            <h6 className="mr-1" style={{ fontWeight: '400', color: 'rgba(0,0,0,.7)' }}>{ln_t(`bank.${key}`)}：</h6>
                            <h6>{bankValues[key]}</h6>
                        </div>
                    ))}
                    {Object.keys(profileValues).map(key => (
                        <div key={key} className="d-flex align-items-center mb-2">
                            <h6 className="mr-1" style={{ fontWeight: '400', color: 'rgba(0,0,0,.7)' }}>
                                {isBusiness ? ln_t(`tax.${key}`) : ln_t(`affiliate.info.${key}`)}：
                            </h6>
                            <h6>{profileValues[key]}</h6>
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <Button type="button" className="mr-2" onClick={() => pushStep('step2')}>{ln_t('util.previous')}</Button>
                    <Button type="primary" onClick={() => onFinish()}>{ln_t('util.confirm')}</Button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmValues
