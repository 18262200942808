import { useEffect, useState } from 'react'
import { AFFILIATE, ROUTELOGIN, validEnterprises } from '../constants/Routing'

function useCheckEnterprise(name, history, extraCondition = true, role = AFFILIATE) {
    const [enterpriseId, setEnterpriseId] = useState(0)

    const checkEnterprise = name => validEnterprises.find(item => item.name.toLowerCase() === name?.toLowerCase())

    useEffect(() => {
        const chk = checkEnterprise(name)
        setEnterpriseId(chk?.id)
    }, [])

    useEffect(() => {
        if (!enterpriseId && enterpriseId !== 0 && extraCondition) history.push(ROUTELOGIN[role])
    }, [enterpriseId])

    return enterpriseId
}

export default useCheckEnterprise
