import { CHECKAUTH } from '../constants/ActionTypes'

const initialState = {
    isLoading: true,
    isAuthed: 0,
    isError: false,
    errMessage: '',
}

export default function checkAuth(state = initialState, action = {}) {
    switch (action.type) {
    case CHECKAUTH.REQUEST:
        return { ...state, ...initialState }
    case CHECKAUTH.SUCCESS:
        return { ...state, isLoading: false, isAuthed: 1 }
    case CHECKAUTH.ERROR:
        return { ...state, isLoading: false, isAuthed: 2, isError: true, ...action.payload }
    default:
        return state
    }
}
