import React from 'react'
import './LogoFloat.less'

function LogoFloat(props) {
    return (
        <div className="float-container">
            <img src="/lndata_logo.png" alt="lndata logo" className="ghost" />
            <p className="shadowFrame">
                <svg
                    version="1.1"
                    className="ghostShadow"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="61px"
                    y="20px"
                    width="122.436px"
                    height="39.744px"
                    viewBox="0 0 122.436 39.744"
                    enableBackground="new 0 0 122.436 39.744"
                >
                    <ellipse fill="#cccccc" cx="61.128" cy="19.872" rx="49.25" ry="8.916" />
                </svg>
            </p>
        </div>
    )
}

export default LogoFloat
