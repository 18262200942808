import React, { useEffect, useState } from 'react'
import { fetchAffiliateBankListByEnterprise } from '../apis/affiliateWeb'

const useCheckBank = (affiliateId, enterpriseId) => {
    const [bankVerified, setBankVerified] = useState(false)
    const [checkingStatus, setCheckingStatus] = useState({ loading: true, error: false })

    const checkBank = async () => {
        let n = bankVerified
        setCheckingStatus({ loading: true, error: false })
        const result = await fetchAffiliateBankListByEnterprise(affiliateId, enterpriseId)
        if (result?.status === 200) {
            const { results } = result.data
            n = results?.[0]?.isVerified
            setBankVerified(n)
            setCheckingStatus({ loading: false, error: false })
        } else setCheckingStatus({ loading: false, error: true }) 
        
        return n
    }

    useEffect(() => {
        checkBank()
    }, [])

    return {
        bankVerified, 
        checkBank,
        checkingStatus
    }
}

export default useCheckBank
