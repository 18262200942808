import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Spin, message, Modal } from 'antd'
import { UserOutlined, UnlockOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Link, useParams, withRouter } from 'react-router-dom'
import { login, forgetPassword, getOtp } from '../../apis/auth'
import { CHECKAUTH, HANDLEERROR, USERPROFILE } from '../../constants/ActionTypes'
import { AFFILIATE, ROUTEBRAND, ROUTEHOME } from '../../constants/Routing'
import LogoFloat from '../../components/Util/LogoFloat'
import useCheckEnterprise from '../../hook/useCheckEnterprise'
import Logo from '../../components/Affiliate/Logo'
import './Login.less'
import { fetchAffiliateTax } from '../../apis/affiliateWeb'

function Login(props) {
    const { auth, dispatch, location, history, ln_t, 
        langOptions, changeLang, modalConf } = props

    const [form] = Form.useForm()
    const { pathname } = location || {}
    const role = pathname?.split('/')?.[1]
    const err = useSelector(state => state.handleError)
    const { enterpriseName } = useParams()
    const enterpriseId = useCheckEnterprise(enterpriseName, history, role === 'affiliate')

    const [loginLoading, setLoginLoading] = useState(false)
    const [finishedCheck, setFinishedCheck] = useState(false)

    const replacePath = string => pathname.replace(/login/, string)

    const sendLogin = async params => {
        setLoginLoading(true)

        const result = await login(role, params)

        if (result?.status === 200) {
            const { userInfo } = result?.data || {}
            const { enterprises } = userInfo || {}
            const find = enterprises?.find(item => String(item.enterprise) === String(enterpriseId))
            const n = { ...userInfo, nowEnterprise: enterprises?.[0] }
            const affiliate = role === 'affiliate'
            
            if (affiliate && !find) message.info(ln_t('message.info.noEnterpriseUser'))
            else {
                dispatch({ type: CHECKAUTH.SUCCESS })
                const { isEmailVerified, nowEnterprise, email, roleId } = n
                const { isAffiliateTC, isEnterpriseUserTC } = nowEnterprise || {}

                dispatch({ type: USERPROFILE.PATCH, payload: { ...n } })
                
                let path = ROUTEHOME[role]
                if (affiliate) {
                    const affent = await fetchAffiliateTax(roleId, enterpriseId)
                    const { isBusiness } = affent?.data?.[0] || {}
                    n.nowEnterprise = find
                    dispatch({ type: USERPROFILE.PATCH, payload: { ...n, isBusiness } })
                    if (isEmailVerified !== 'verified') {
                        const result = await getOtp({ email })
                        if (result?.status === 200) message.info(ln_t('message.success.getOtp'))
                        else message.info(result?.data.message || ln_t('message.error.serverError'))
                        path = replacePath('otpVerify')
                    } else if (isBusiness === undefined || isBusiness === null) {
                        path = replacePath('accountType')
                    } else if (isAffiliateTC !== 'accepted') path = replacePath('terms')
                } else if (isEnterpriseUserTC !== 'accepted') path = replacePath('terms')

                history.push({ pathname: path, state: { email, affiliate: { id: roleId } } })    
            }
        } else message.error(result?.data?.non_field_errors || ln_t('message.error.serverError'))
        setLoginLoading(false)
    }

    const sendForgetPass = async () => {
        setLoginLoading(true)
        const email = form.getFieldValue('username')
        if (!email) message.info(ln_t('message.info.insertAccountFirst'))
        else if (!email.match(/^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/)) {
            message.info(ln_t('message.info.insertCorrectEmailPattern'))
        } else {
            const params = {
                email,
                userType: role
            }
            const result = await forgetPassword(params)
            if (result?.status === 200) {
                Modal.info({
                    ...modalConf,
                    content: (<span>{ln_t('message.info.emailAlreadySent')}</span>),
                })
            } else message.info(result?.data?.message || 'server error')
        }
        setLoginLoading(false)
    }

    const onFinish = fieldsValue => {
        const f = fieldsValue
        const postData = {
            username: f.username,
            password: window.btoa(f.password),
        }
        sendLogin(postData)
    }

    useEffect(() => {
        if (role === AFFILIATE) {
            const brand = pathname.split('/')?.at(-1)
            const name = Object.keys(ROUTEBRAND).find(key => ROUTEBRAND[key] === `/${brand}`)
            dispatch({ type: USERPROFILE.PATCH, payload: { nowEnterprise: { name } } }) 
        }
        dispatch({ type: CHECKAUTH.REQUEST }) 
    }, [])

    useEffect(() => {
        if (err.catchError) dispatch({ type: HANDLEERROR.CLEAR })
    }, [err])

    useEffect(() => {
        let s
        if (!auth?.isLoading) {
            s = setTimeout(() => setFinishedCheck(true), 1000)
        }

        return () => clearTimeout(s)
    }, [auth?.isLoading])

    return (
        <main className="login-container">
            {finishedCheck 
                ? (
                    <section className={`login-inner ${pathname.includes('affiliate') ? 'reduce-padding' : ''}`}>
                        <div 
                            onClick={() => changeLang(langOptions[0].value)}
                            className="lang-btn"
                        >
                            {langOptions[0].label}
                        </div>
                        <div className="logo-box">
                            <Logo {...props} />
                        </div>
                        <div className="title-text">{ln_t(`login.${role}`)}</div>
                        <Spin spinning={loginLoading}>  
                            <Form
                                initialValues={{ remember: true }}
                                form={form}
                                size="large"
                                onFinish={onFinish}
                                hideRequiredMark
                            >
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: ln_t('login.enterUsername') }]}
                                >
                                    <Input autoComplete="off" prefix={<UserOutlined className="mr-2" />} placeholder={ln_t('login.username')} inputMode="email" />
                                </Form.Item>
                                <Form.Item
                                    style={{ marginTop: '28px' }}
                                    name="password" 
                                    rules={[{ required: true, message: ln_t('login.enterPassword') }]}
                                >
                                    <Input.Password autoComplete="off" prefix={<UnlockOutlined className="mr-2" />} placeholder={ln_t('login.password')} />
                                </Form.Item>

                                <div className="link mt-4 primary" onClick={() => sendForgetPass()}>
                                    {ln_t('login.forgetPassword')}
                                </div>
                                {role === 'affiliate' 
                                    ? (
                                        <div className="link mt-2">
                                            {ln_t('login.noAccount')}
                                            <Link to={replacePath('checkEmail')}>
                                                {ln_t('login.createAccount')}
                                            </Link>
                                        </div>
                                    ) : ''}

                                <div className="login-btn-container">
                                    <Button type="button" htmlType="submit">{ln_t('login.login')}</Button>
                                </div>
                            </Form>
                        </Spin>
                        {pathname.includes('affiliate') ? (
                            <div className="copyright"><i>Copyright © 2022 LnData.INC, all rights reserved.</i></div>
                        ) : ''}
                    </section>
                )
                : <LogoFloat />}
        </main>
    )
}
export default withRouter(Login)
