import React from 'react'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import './Switcher.less'

function Switcher(props) {
    const { className, handleLeft = () => {}, handleRight = () => {}, disabled = [false, false] } = props

    return (
        <section className={`d-flex align-items-center justify-content-end ${className}`}>
            <div 
                className="handler-box mr-2" 
                onClick={() => handleLeft()}
                style={{ cursor: !disabled[0] ? 'pointer' : 'not-allowed' }}
            >
                <LeftOutlined />
            </div>
            <div 
                className="handler-box" 
                onClick={() => handleRight()}
                style={{ cursor: !disabled[1] ? 'pointer' : 'not-allowed' }}
            >
                <RightOutlined />
            </div>
        </section>
    )
}

export default Switcher
