import React from 'react'
import { Button } from 'antd'
import '../Profile.less'

function VerifyBank(props) {
    const { ln_t, history, addBank } = props

    const onFinish = () => {
        history.push('/affiliate/account/request')
    }

    return (
        <div className="affiliate-profile h-100">
            <div className="d-flex fd-col justify-content-between h-100 px-5">
                <div>
                    <h4 className="mb-4">{ln_t('bank.verifyYourBankAccount')}</h4>
                    {addBank ? <h6 className="mb-4">{ln_t('bank.addAccountSuccessPleaseFollowTheseSteps')}</h6> : ''}

                    <ol className="p-0 ml-3">
                        <li>
                            <h6>
                                {ln_t('bank.verify.step1a')}
                                <span style={{ color: 'red' }}>{ln_t('bank.verify.step1b')}</span>
                            </h6>
                        </li> 
                        <li><h6>{ln_t('bank.verify.step2')}</h6></li>
                        <li><h6>{ln_t('bank.verify.step3')}</h6></li> 
                    </ol>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <Button type="button" onClick={() => onFinish()}>{addBank ? ln_t('util.confirm') : ln_t('util.goBack')}</Button>
                </div>
            </div>
        </div>
    )
}

export default VerifyBank
