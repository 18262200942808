import React, { useEffect, useState, useCallback } from 'react'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { fetchOrderTrending } from '../../../apis/stats'
import Switcher from '../../../components/Util/Switcher'

function OrderTranding(props) {
    const { userInfo, ln_t, className, width } = props
    const { nowEnterprise, lang } = userInfo || {}
    const [data, setData] = useState({})
    const [graphHeight, setGraphHeight] = useState(0)
    const [page, setPage] = useState()
    const [lastPage, setLastPage] = useState()
    const [fullData, setFullData] = useState()

    const labelOpts = [
        { label: ln_t('product.summary.completed'), color: 'rgba(7, 179, 161, 1)' }, 
        { label: ln_t('product.summary.tentative'), color: 'rgba(7, 179, 161, .6)' }, 
        { label: ln_t('product.summary.canceled'), color: 'rgba(7, 179, 161, .3)' }
    ]

    const graphOpts = {
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                title(tooltipItem, data) {
                    const datas = data?.datasets
                    const { index, datasetIndex } = tooltipItem?.[0] || {}
                    let total = 0
                    datas.forEach(data => {
                        total += data.data[index]
                    })
                    const val = datas?.[datasetIndex]?.data?.[index]
                    const label = datas?.[datasetIndex]?.label
                    return `${label} (${String(((val / total) * 100).toFixed(1)).replace('.0', '')}%)`
                },
                label(tooltipItem, data) {
                    const datas = data?.datasets
                    const { index, datasetIndex } = tooltipItem || {}
                    const val = datas?.[datasetIndex]?.data?.[index]
                    return `${val?.toLocaleString()} ${ln_t('dashboard.orders')}`
                },
            },
            titleFontSize: 16,
            bodyFontSize: 16,
            padding: 12,
            displayColors: false,
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: ln_t('dashboard.month'),
                    fontSize: 18,
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    userCallback(value) {
                        if (Math.floor(value) === value) {
                            return value.toLocaleString()
                        }
                    }    
                },
                scaleLabel: {
                    display: true,
                    labelString: ln_t('dashboard.orderCount'),
                    fontSize: 18,
                }
            }]
        }
    }    

    const graphRef = useCallback(node => {
        if (!node) return
        const h = node.offsetWidth * 0.8
        setGraphHeight(h)
    }, [width, data])

    const handlePage = newPage => {
        const { labels: fullLabels, datasets } = fullData
        const startIndex = (fullLabels.length - ((lastPage - newPage + 1) * 7)) < 0 ? 0 : (fullLabels.length - ((lastPage - newPage + 1) * 7))
        const endIndex = fullLabels.length - ((lastPage - newPage) * 7)

        if (newPage < 1 || endIndex > fullLabels.length) return

        const d = {
            labels: fullLabels.slice(startIndex, endIndex), 
            datasets: datasets.map(item => ({
                ...item,
                data: item.data.slice(startIndex, endIndex)
            }))
        }
        setData(d)
        setPage(newPage)
    }

    const getDatas = async () => {
        const result = await fetchOrderTrending(nowEnterprise?.enterprise)
        if (result?.status === 200) {
            const { results } = result.data
            const labels = [ln_t('product.summary.completed'), ln_t('product.summary.tentative'), ln_t('product.summary.canceled')]
            const enLabels = ['completed', 'tentative', 'canceled']
            const months = []
            if (results?.length >= 2) {
                for (let i = moment(String(results[0].yearMonth)); i <= moment(String(results[results.length - 1].yearMonth)); i.add(1, 'month')) {
                    months.push(Number(i.format('YYYYMM')))
                }
            }

            const n = {
                labels: months.map(item => moment(String(item)).format('YYYY-MM')),
                datasets: labels.map((label, index) => ({
                    label,
                    data: months.map(m => {
                        const match = results.find(res => m === res.yearMonth)
                        return match ? match[`${enLabels[index].toLowerCase()}OrderCount`] : 0
                    }),
                    backgroundColor: labelOpts[index].color,
                    stack: 'Stack 0',
                    barThickness: 40,
                }))
            }
            const last = Math.ceil(months.length / 7)
            setLastPage(last)
            setPage(last)
            setFullData(n)
        }
    }

    useEffect(() => {
        if (!fullData || !page) return
        handlePage(page)
    }, [fullData])

    useEffect(() => {
        getDatas()
    }, [lang])

    return (
        <section className={`${className}`}>
            <div className="multi-bar" ref={graphRef}>
                <h4 className="mb-4">{ln_t('dashboard.mothlyOrderTrending')}</h4>
                <Switcher 
                    className="mb-3 mr-1" 
                    handleLeft={() => handlePage(page - 1)} 
                    handleRight={() => handlePage(page + 1)}
                    disabled={[page <= 1, page >= lastPage]}
                />
                <Bar key={`order-trend_${graphHeight}`} data={data} options={graphOpts} height={graphHeight / 1.5} width={graphHeight} />
            </div>
            <div className="d-flex align-items-center justify-content-center px-4 mt-4">
                {labelOpts.map((opt, index) => (
                    <div className="d-flex align-items-center mr-4" key={`affiliate-product-label-${index}`}>
                        <div className="label-color" style={{ background: opt.color }} />
                        <h6 className="m-0">{opt.label}</h6>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default OrderTranding
