import React from 'react'
import { pick, cloneDeep } from 'lodash'
import { message } from 'antd'
import moment from 'moment'
import MainTable from '../../../components/Util/MainTable'
import { fetchTrafficListByEnterprise, fetchTrafficSummaryByEnterprise } from '../../../apis/traffic'
import useParams from '../../../hook/useParams'
import SearchForm from './SearchForm'

function Traffic(props) {
    const { ln_t, userInfo, setIsLoading, download } = props
    const { nowEnterprise } = userInfo || {}
    const params = useParams({ sort: '-createdAt' })
    const { fetchParams } = params
    
    const columns = [
        {
            title: ln_t('traffic.country'),
            dataIndex: 'country',
            sorter: true,
        },
        {
            title: ln_t('traffic.city'),
            dataIndex: 'city',
            sorter: true,
        },
        {
            title: ln_t('affiliate.id'),
            dataIndex: 'ouid',
            sorter: true,
        },
        {
            title: ln_t('affiliate.name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: ln_t('traffic.device'),
            dataIndex: 'deviceType',
            sorter: true,
        },
        {
            title: ln_t('traffic.os'),
            dataIndex: 'osType',
            sorter: true,
        },
        {
            title: ln_t('traffic.clickedAt'),
            dataIndex: 'createdAt',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        }
    ]

    const apiFunc = async () => fetchTrafficListByEnterprise(nowEnterprise?.enterprise, fetchParams)

    const handleDownload = async () => {
        const { affiliate, country, createdAt_after, createdAt_before } = cloneDeep(fetchParams)
        const oneMonthAgo = moment(createdAt_before)?.subtract(1, 'months').subtract(1, 'days')
        if (!createdAt_after || !createdAt_before || oneMonthAgo > moment(createdAt_after)) return message.error(ln_t('message.error.cantDownloadMoreThanOneMonthData'))
        setIsLoading(true)
        const dateFilter = {
            createdAt_after: moment.utc(createdAt_after).format('YYYY-MM-DDTHH:mm:ssZ'),
            createdAt_before: moment.utc(createdAt_before).add(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ')
        }
        const result = await fetchTrafficSummaryByEnterprise(nowEnterprise?.enterprise, { sort: '-date', affiliate, country, ...dateFilter })
        if (result?.status === 200) {
            const formatDate = date => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '')
            const formattedResult = result.data.map(({ createdAt, ...other }) => ({ ...other, createdAt: formatDate(createdAt) }))
            const neededCols = ['affiliate_ouid', 'affiliate_name', 'date', 'country', 'city', 'deviceType', 'osType', 'count']
            const colNames = Object.assign({}, ...neededCols.map(col => ({ [col]: ln_t(`traffic.${col}`) })))
            const n = formattedResult.map(item => pick(item, neededCols))
            download([[colNames, ...n]], ln_t('breadcrumb.traffic.list'), true)
        }
        setIsLoading(false)
    }
  
    return (
        <MainTable 
            {...props}
            className="traffic-list"
            tableName="traffic"
            columns={columns}
            rowKey="trafficId"
            apiFunc={apiFunc}
            params={params}
            useHeader
            headerRender={<SearchForm {...props} {...params} />}
            btnTitle={ln_t('util.download')}
            onBtnClick={handleDownload}
        />
    )
}

export default Traffic
