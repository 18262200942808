import React, { useEffect } from 'react'
import { Form, Input, Button, message, Modal, Divider } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { AFFILIATE } from '../../constants/Routing'
import { fetchAffiliate, modAffiliate } from '../../apis/affiliateWeb'
import { USERPROFILE } from '../../constants/ActionTypes'
import './Profile.less'

function Account(props) {
    const [form] = Form.useForm()
    const { userInfo, dispatch, setIsLoading, ln_t, history } = props

    const { roleId } = userInfo || {}

    const getUserData = async userId => {
        setIsLoading(true)
        const result = await fetchAffiliate(userId)
        if (result?.status === 200) {
            const { name, email, fb, ig, yt, tiktok, personalBlog, others } = result?.data || {}
            const data = {
                name, 
                password: '********',
                email, 
                fb, 
                ig, 
                yt, 
                tiktok, 
                personalBlog, 
                others
            }
            form.setFieldsValue({ ...data, password: '********' })
        }
        setIsLoading(false)
    }

    const onFinish = fieldsValue => {
        const handleEdit = async () => {
            setIsLoading(true)
            const { name, fb, ig, yt, tiktok, personalBlog, others } = fieldsValue
            const postData = { name, fb, ig, yt, tiktok, personalBlog, others } 
      
            const result = await modAffiliate(userInfo?.roleId, postData)
            if (result?.status === 200) {
                dispatch({ type: USERPROFILE.PATCH, payload: { username: name } })
                message.success(ln_t('message.success.edit'))
            }  
            setIsLoading(false)  
        }

        Modal.confirm({
            icon: false,
            centered: true,
            okText: ln_t('util.confirm'),
            cancelText: ln_t('util.cancel'),
            content: ln_t('message.info.confirmEdit'),
            onOk: async () => handleEdit()
        })
    }

    useEffect(() => {
        if (!roleId) return
        getUserData(roleId)
    }, [roleId])

    return (
        <div className="affiliate-profile">
            <Form
                form={form}
                className="profile-form"
                labelAlign="left"
                requiredMark={false}
                validateTrigger="onChange"
                onFinish={onFinish}
            >
                <h4 className="mb-5 text-center">{ln_t('sidebarMenu.label.profile')}</h4>
                <Form.Item label={ln_t('affiliate.info.username')} name="name">
                    <Input allowClear autoComplete="off" placeholder={ln_t('affiliate.placeholder.name')} />
                </Form.Item>
                <Form.Item label={ln_t('affiliate.info.password')} className="mr-2 d-flex">
                    <Form.Item noStyle name="password">
                        <Input disabled style={{ width: 'calc(100% - 88px)' }} className="mr-2" />
                    </Form.Item>
                    <Button type="button" onClick={() => history.push(`/${AFFILIATE}/mod-password`)}>{ln_t('util.update')}</Button>
                </Form.Item>
                <Form.Item label={ln_t('affiliate.info.email')} className="d-flex">
                    <Form.Item noStyle name="email">
                        <Input className="mr-2" style={{ width: 'calc(100% - 88px)' }} disabled />
                    </Form.Item>
                    <span className="text-green" style={{ fontWeight: '600' }}>
                        <CheckCircleFilled className="icon-pos mr-1" />{ln_t('affiliate.info.verified')}
                    </span>
                </Form.Item>
                <Divider className="my-4">{ln_t('affiliate.info.yourSocialMediaAccounts')}</Divider>
                {['fb', 'ig', 'yt', 'tiktok', 'personalBlog', 'others'].map(key => (
                    <Form.Item key={key} label={ln_t(`affiliate.info.${key}`)} name={key}>
                        <Input allowClear autoComplete="off" placeholder={ln_t(`affiliate.placeholder.${key}`)} inputMode="url" />
                    </Form.Item>
                ))}
                <div className="d-flex justify-content-center mt-4">
                    <Button htmlType="submit" type="primary">{ln_t('util.confirmUpdate')}</Button>
                </div>
            </Form>
        </div>
    )
}

export default Account
