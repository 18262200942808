import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const fetchOrdersByCamp = () => {}

export const fetchOrderListByEnterprise = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/order/`, MethodType.GET, params)

export const fetchCampAffiliatePayment = async params => api.request(1, '/api/v2/order/by_affiliate/', MethodType.GET, params)

export const changeOrderStatus = async params => api.request(1, '/api/v2/order/status/', MethodType.PATCH, params)

export const fetchOrderDetails = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/orderDetails/`, MethodType.GET, params)
