import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const fetchTrafficListByEnterprise = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/traffic/`, MethodType.GET, params)

export const fetchTrafficSummaryByEnterprise = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/traffic/summary`, MethodType.GET, params)

export const fetchTransactionDetails = async (enterpriseId, params) => (
    api.request(1, `/api/v3/enterprise/${enterpriseId}//enterprise/{parent_lookup_enterprise}/transactionDetails/`, MethodType.GET, params)
)
