import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Modal, Button } from 'antd'
import { pick } from 'lodash'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import SearchForm from './SearchForm'
import TranscationDetailTable from './TranscationDetailTable'
import OrderDetailTable from './OrderDetailTable' 
import { getPaymentHistory, getPaymentHistoryTotalAmount, getTransactionDetails } from '../../../apis/payment'

const formatAccount = account => {
    const l = account.length
    return account.split('').map((item, index) => {
        if (((index + 1) % 4) || index + 1 === l) return item
        return `${item} - `
    }).join('')
}

function PaidList(props) {
    const { ln_t, userInfo, setIsLoading, download } = props
    const { nowEnterprise } = userInfo || {}

    const params = useParams({ sort: '-confirmedDate' })
    const params2 = useParams()

    const { fetchParams } = params
    const { setReady, fetchParams: fetchParams2, handleFetchParams } = params2

    const [isModalVisible, setModalVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState()
    const [confirmedDate, setConfirmedDate] = useState()
    const [dataType, setDataType] = useState()
    const [dataParam, setDataParam] = useState()
    const [paymentHistoryTotalAmount, setPaymentHistoryTotalAmount] = useState(0)

    const showModal = confirmedDate => {
        setConfirmedDate(confirmedDate)
        setDataType('transcationDetail')
        setDataParam({ confirmedDate })
        setModalTitle(ln_t('payment.transcationDetail'))
        setModalVisible(true)
    }

    const handleOk = () => {
        setModalVisible(false)
    }

    const changeModal = (title, dataType, params) => {
        setDataType(dataType)
        setDataParam(params)
        setModalTitle(title)
    }

    const handleDownload = async params => {
        setIsLoading(true)
        const result = await getTransactionDetails(nowEnterprise?.enterprise, { sort: '-updatedAt', ...params })
        if (result?.status === 200) {
            const formatDate = date => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '')
            const formattedResult = result.data.results.map(({ withdrawalDate, ...other }) => ({
                ...other, withdrawalDate: formatDate(withdrawalDate),
            }))
            const neededCols = ['ouid', 'name', 'bankAccountName', 'bank', 'branchCode', 'affiliateBankAccount',
                'referralAmount', 'withdrawalDate', 'orderCount']
            const customNaming = {
                ouid: ln_t('affiliate.id'),
                name: ln_t('affiliate.name'),
                bankAccountName: ln_t('affiliate.accountName'),
                affiliateBankAccount: ln_t('payment.bankAccount'),
                orderCount: ln_t('dashboard.orderCount') }
            const colNames = Object.assign({}, ...neededCols.map(col => ({ [col]: customNaming[col] || ln_t(`payment.${col}`) })))
            const n = formattedResult.map(item => pick(item, neededCols))
            download([[colNames, ...n]], ln_t('payment.tabMenu.paymentHistory'), true)
        }
        setIsLoading(false)
    }

    const columns = [
        {
            title: ln_t('payment.paymentDate'),
            dataIndex: 'confirmedDate',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        },
        {
            title: ln_t('payment.paidAmount'),
            dataIndex: 'referralAmount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('payment.transactionDetail'),
            dataIndex: 'transactionCount',
            render: (e, record) => (e === 0 ? '' : <div className="primary-link" onClick={() => showModal(record.confirmedDate)}>{e} {ln_t('payment.detailUnit')}</div>),
        },                  
        {
            title: ln_t('util.download'),
            dataIndex: 'orderCount',
            render: (e, record) => (
                e === 0 ? '-' 
                    : (
                        <div className="primary-link" onClick={() => handleDownload({ confirmedDate: record.confirmedDate })}>
                            {ln_t('util.download')}
                        </div>
                    )),
        },  
    ]

    const apiFunc = async () => getPaymentHistory(nowEnterprise?.enterprise, fetchParams)

    const getPaymentHistoryTotalAmountFunc = async () => {
        const result = await getPaymentHistoryTotalAmount(nowEnterprise?.enterprise)
        if (result?.status === 200) {
            // console.log(result?.data[0].totalAmount)
            setPaymentHistoryTotalAmount(result?.data[0]?.totalAmount || 0)
        }
    }
    
    useEffect(() => {
        getPaymentHistoryTotalAmountFunc()
    }, [])

    const footer = () => (
        <div className="text-right">{`${ln_t('payment.paymentHistoryTotalAmount')}：NT $${Number(paymentHistoryTotalAmount).toLocaleString()}`}</div>
    )

    return (
        <>
            <SearchForm {...props} {...params} />
            <MainTable 
                {...props}
                className="paid-payment"
                tableName="paidPayment"
                columns={columns}
                rowKey="confirmedDate_referralAmount"
                apiFunc={apiFunc}
                params={params}
                footer={footer}
            />
            <Modal 
                title={modalTitle} 
                visible={isModalVisible} 
                onCancel={handleOk} 
                width={1300} 
                footer={false}
            >
                {
                    isModalVisible 
                        ? (
                            dataType === 'transcationDetail'
                                ? <TranscationDetailTable {...props} dataParam={dataParam} changeModal={changeModal} />  
                                : <OrderDetailTable {...props} dataParam={dataParam} changeModal={changeModal} confirmedDate={confirmedDate} showBack />  
                        ) : '' 
                }
            </Modal>
        </>
    )
}

export default PaidList
