import React from 'react'
import { Input, Form } from 'antd'
import { changePassword } from '../../../apis/user'
import { ENTERPRISE, ROUTELOGIN } from '../../../constants/Routing'
import FormFooter from '../../../components/Util/FormFooter'
import { USERPROFILE } from '../../../constants/ActionTypes'
import { logout } from '../../../apis/auth'
import './UserProfile.less'

function ModPassword(props) {
    const { userInfo, dispatch, setIsLoading, ln_t, history, passwordFormat } = props
    const [form] = Form.useForm()

    const { nowEnterprise, roleId } = userInfo
    const { enterprise } = nowEnterprise || {}

    const sendLogout = async () => {
        setIsLoading(true)
        const result = await logout()
        if (result?.status === 200) {
            dispatch({ type: USERPROFILE.LOGOUT })

            setIsLoading(false)
            history.push(ROUTELOGIN.enterprise)
        } else setIsLoading(false)
    }

    const onModPass = async fieldsValue => {
        setIsLoading(true)
        const { newPassword, oldPassword } = fieldsValue
        const body = {
            newPassword: window.btoa(newPassword),
            oldPassword: window.btoa(oldPassword)
        }
        const result = await changePassword(enterprise, roleId, body)
        if (result?.status === 200) sendLogout()

        setIsLoading(false)
    }

    const validation = {
        password: [
            { required: true, message: ln_t('user.validation.newPassword') }, 
            { pattern: passwordFormat, message: ln_t('user.validation.matchPattern') }
        ],
        confirm: [{ required: true, message: ln_t('user.validation.confirmAgain') }],
        oldPass: [{ required: true, message: ln_t('user.validation.currentPassword') }],
    }

    return (
        <div className="profile-container" style={{ paddingBottom: '30px' }}>
            <div className="profile-inner mod-pass">
                <Form
                    form={form}
                    onFinish={onModPass}
                    hideRequiredMark
                    validateTrigger="onChange"
                    labelAlign="left"
                >
                    <Form.Item label={ln_t('user.form.currentPassword')} name="oldPassword" rules={validation.oldPass}>
                        <Input.Password
                            placeholder={validation.oldPass[0].message} 
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        label={ln_t('user.form.newPassword')}
                        name="newPassword"
                        rules={[...validation.password, ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('oldPassword') !== value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(ln_t('message.error.newAndCurrentCannotBeSame'))
                            },
                        })]}
                    >
                        <Input.Password
                            placeholder={validation.password[0].message} 
                            autoComplete="off"
                        />
                    </Form.Item>

                    <Form.Item
                        label={ln_t('user.form.confirmAgain')}
                        name="confirm"
                        rules={[...validation.confirm, ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(ln_t('message.error.pleaseMatchNewPass'))
                            },
                        })]}
                    >
                        <Input.Password
                            placeholder={validation.confirm[0].message} 
                            autoComplete="off"
                        />
                    </Form.Item>
                    <div className="rule-box">
                        <span>{ln_t('user.password.length')}<br />
                            {ln_t('user.password.format')}
                        </span>
                    </div>
                    <FormFooter
                        onCancel={() => history.push(`/${ENTERPRISE}/user-profile`)}
                        placement="center"
                        className="mt-5 ml-4"
                        submitBtnTitle={ln_t('util.confirm')}
                    />
                </Form>
            </div>
        </div>
    )
}

export default ModPassword
