import React from 'react'
import { Input, Form, message } from 'antd'
import { modAffiliatePassword } from '../../apis/affiliateWeb'
import FormFooter from '../../components/Util/FormFooter'
import { USERPROFILE } from '../../constants/ActionTypes'
import { logout } from '../../apis/auth'
import { AFFILIATE, ENTERPRISE, ROUTEBASE, ROUTEBRAND, ROUTELOGIN } from '../../constants/Routing'
import '../Enterprise/Setting/UserProfile.less'

function ModPassword(props) {
    const { userInfo, dispatch, setIsLoading, history, ln_t, passwordFormat } = props
    const [form] = Form.useForm()
    const path = history.location.pathname    
    const isBrand = path.startsWith(ROUTEBASE[ENTERPRISE])
    const { nowEnterprise } = userInfo || {}

    const sendLogout = async () => {
        setIsLoading(true)
        const result = await logout()
        if (result?.status === 200) {
            dispatch({ type: USERPROFILE.LOGOUT, payload: { nowEnterprise } })

            setIsLoading(false)
            history.push(`${ROUTELOGIN[AFFILIATE]}${ROUTEBRAND[nowEnterprise.name] || ''}`)
        } else setIsLoading(false)
    }

    const onModPass = async fieldsValue => {
        setIsLoading(true)

        const { newPassword, oldPassword } = fieldsValue

        const body = {
            newPassword: window.btoa(newPassword),
            oldPassword: window.btoa(oldPassword)
        }
        const { roleId } = userInfo || {}
        const result = await modAffiliatePassword(roleId, body)
        if (result?.status === 200) {
            sendLogout()
        } else message.error('Fail to change password')

        setIsLoading(false)
    }

    const validation = {
        password: [{ required: true, message: ln_t('user.validation.newPassword') }, { pattern: passwordFormat, message: ln_t('user.validation.matchPattern') }],
        confirm: [{ required: true, message: ln_t('user.validation.confirmAgain') }],
        oldPass: [{ required: true, message: ln_t('user.validation.currentPassword') }],
    }

    return (
        <div className="affiliate-mod-pass">
            <div className="profile-inner">
                <Form
                    form={form}
                    onFinish={onModPass}
                    hideRequiredMark
                    validateTrigger="onChange"
                    labelAlign="left"
                >
                    <Form.Item label={ln_t('user.form.currentPassword')} name="oldPassword" rules={validation.oldPass}>
                        <Input.Password
                            placeholder={validation.oldPass[0].message} 
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        label={ln_t('user.form.newPassword')}
                        name="newPassword"
                        rules={[...validation.password, ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('oldPassword') !== value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(ln_t('message.error.newAndCurrentCannotBeSame'))
                            },
                        })]}
                    >
                        <Input.Password
                            placeholder={validation.password[0].message} 
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        label={ln_t('user.form.confirmAgain')}
                        name="confirm"
                        rules={[...validation.confirm, ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(ln_t('message.error.pleaseMatchNewPass'))
                            },
                        })]}
                    >
                        <Input.Password
                            placeholder={validation.confirm[0].message} 
                            autoComplete="off"
                        />
                    </Form.Item>
                    <div className="rule-box">
                        <span>{ln_t('user.password.length')}<br />
                            {ln_t('user.password.format')}
                        </span>
                    </div>
                    <FormFooter
                        onCancel={() => history.push(`/${AFFILIATE}/profile`)}
                        placement="center"
                        className="mt-5 ml-4"
                        submitBtnTitle={ln_t('util.confirm')}
                    />
                </Form>
            </div>
        </div>
    )
}

export default ModPassword
