import React from 'react'
import './Footer.less'

function Footer(props) {
    return (
        <section className="project-footer">
            <span>Power by Lndata, Copyright &copy; {new Date().getFullYear()}</span>
        </section>
    )
} 

export default Footer
