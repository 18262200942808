import React, { useEffect, useState } from 'react'
import { Form, Input, Button, DatePicker, message, Modal, Divider } from 'antd'
import moment from 'moment'
import { fetchAffiliateTax, modAffiliateTax } from '../../apis/affiliateWeb'
import './Profile.less'
import useCheckBank from '../../hook/useCheckBank'

function Profile(props) {
    const [form] = Form.useForm()
    const { userInfo, setIsLoading, ln_t, modalConf, addBank, location, history, 
        login, loginButtons, goToTerm } = props
    const { roleId, nowEnterprise } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const { state } = location || {}
    const { profileValues } = state || {}
    const { bankVerified, checkBank } = useCheckBank(roleId, enterprise)
    const [isBusiness, setIsBusiness] = useState()

    const getUserData = async userId => {
        setIsLoading(true)
        const result = await fetchAffiliateTax(userId, enterprise)
        if (result?.status === 200) {
            const { isBusiness: biz, fullName, residentID, birthDate, phone, address,
                companyName, taxID, companyPhoneNumber, companyAddress, companyWebsite, email } = result?.data?.[0] || {}
            setIsBusiness(biz)
            const business = { companyName, taxID, companyPhoneNumber, companyAddress, fullName, phone, email, companyWebsite }
            const personal = { fullName, residentID, phone, address, birthDate: birthDate ? moment(birthDate) : birthDate, }
            let n = biz ? business : personal
            if (profileValues) n = { ...n, ...profileValues }
            form.setFieldsValue(n)
        }
        setIsLoading(false)
    }

    const pushStep = step => {
        const { birthDate, ...other } = profileValues
        const profile = isBusiness ? { ...other } : { ...profileValues }
        history.push({ pathname: `/affiliate/bank/add/${step}`, state: { ...state, profileValues: profile, isBusiness } })
    }

    const onFinish = async fieldsValue => {
        if (addBank) return pushStep('step3')

        const handleEdit = async (msg = true) => {
            setIsLoading(true)
            const verified = await checkBank()
            if (verified) {
                Modal.info({
                    ...modalConf,
                    content: ln_t('message.info.bankVerifiedCannotEditProfile'),
                })        
            } else {
                const { fullName, residentID, birthDate, phone, address,
                    companyName, taxID, companyPhoneNumber, companyAddress, companyWebsite, email } = fieldsValue 
                const business = { companyName, taxID, companyPhoneNumber, companyAddress, fullName, phone, email, companyWebsite }
                const personal = { fullName, residentID, phone, address, birthDate: birthDate?.format('YYYY-MM-DD'), }
                const result = await modAffiliateTax(roleId, enterprise, isBusiness ? business : personal)
                if (result?.status === 200 && msg) message.success(ln_t('message.success.edit'))
            }
            setIsLoading(false)  
        }

        if (login) {
            await handleEdit(false)
            return goToTerm()
        }
        Modal.confirm({
            ...modalConf,
            content: ln_t('message.info.confirmEdit'),
            onOk: async () => handleEdit()
        })
    }

    const msg = {
        fullName: ln_t('affiliate.placeholder.fullName'),
        id: ln_t('affiliate.placeholder.ID'),
        phone: ln_t('affiliate.placeholder.phone'),
        address: ln_t('affiliate.placeholder.address'),
        birth: ln_t('affiliate.placeholder.birth'),
        pattern: ln_t('affiliate.validation.matchFormat'),
        companyName: ln_t('tax.placeholder.companyName'),
        taxID: ln_t('tax.placeholder.taxID'),
        companyPhoneNumber: ln_t('tax.placeholder.companyPhoneNumber'),
        companyAddress: ln_t('tax.placeholder.companyAddress'),
        companyWebsite: ln_t('tax.placeholder.companyWebsite'),
        contactName: ln_t('tax.placeholder.fullName'),
        contactPhoneNumber: ln_t('tax.placeholder.phone'),
        contactEmail: ln_t('tax.placeholder.email'),
    }

    useEffect(() => {
        if (addBank && !state) return history.push('/affiliate/account/request')
        if (roleId) getUserData(roleId)
    }, [roleId])

    return (
        <div className="affiliate-profile h-100">
            <Form
                form={form}
                className="profile-form d-flex fd-col h-100"
                labelAlign="left"
                requiredMark={false}
                validateTrigger="onSubmit"
                onFinish={onFinish}
                onFieldsChange={() => {
                    const values = form.getFieldsValue()
                    const { birthDate } = values || {}
                    addBank && history.replace({ state: { ...state, profileValues: { ...values, birthDate: birthDate?.format('YYYY-MM-DD') } } })
                }}
            >
                <div>
                    <h4 className={`mb-5 ${addBank ? '' : 'text-center'}`}>{ln_t('sidebarMenu.label.taxInformation')}</h4>
                    {isBusiness ? (
                        <>
                            <Form.Item label={ln_t('tax.companyName')} name="companyName" rules={[{ required: addBank, message: msg.companyName }]}>
                                <Input allowClear autoComplete="off" placeholder={msg.companyName} disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item 
                                label={ln_t('tax.taxID')} 
                                name="taxID" 
                                rules={[
                                    { pattern: /^[0-9]{8,8}$/, message: msg.pattern },
                                    { required: addBank, message: msg.taxID }
                                ]}
                            >
                                <Input allowClear autoComplete="off" placeholder={msg.taxID} disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item 
                                label={ln_t('tax.companyPhoneNumber')} 
                                name="companyPhoneNumber" 
                                rules={[
                                    { pattern: /^0[0-9]{7,9}$/, message: msg.pattern },
                                    { required: addBank, message: msg.companyPhoneNumber }
                                ]}
                            >
                                <Input allowClear autoComplete="off" placeholder={msg.companyPhoneNumber} inputMode="tel" disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item label={ln_t('tax.companyAddress')} name="companyAddress" rules={[{ required: addBank, message: msg.companyAddress }]}>
                                <Input allowClear autoComplete="off" placeholder={msg.companyAddress} disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item label={ln_t('tax.companyWebsite')} name="companyWebsite" rules={[{ required: false }]}>
                                <Input allowClear autoComplete="off" placeholder={msg.companyWebsite} disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item label={ln_t('tax.fullName')} name="fullName" rules={[{ required: addBank, message: msg.contactName }]}>
                                <Input allowClear autoComplete="off" placeholder={msg.contactName} disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item 
                                label={ln_t('tax.phone')} 
                                name="phone" 
                                rules={[
                                    { pattern: /^0[0-9]{7,9}$/, message: msg.pattern },
                                    { required: addBank, message: msg.contactPhoneNumber }
                                ]}
                            >
                                <Input allowClear autoComplete="off" placeholder={msg.phone} inputMode="tel" disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item 
                                label={ln_t('tax.email')} 
                                name="email" 
                                disabled
                            >
                                <Input allowClear autoComplete="off" placeholder={msg.phone} inputMode="tel" disabled />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item label={ln_t('affiliate.info.realName')} name="fullName" rules={[{ required: addBank, message: msg.fullName }]}>
                                <Input allowClear autoComplete="off" placeholder={msg.fullName} disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item 
                                label={ln_t('affiliate.info.ID')} 
                                name="residentID" 
                                rules={[
                                    { pattern: /^[A-Z]{1,1}[0-9]{9,9}$/, message: msg.pattern },
                                    { required: addBank, message: msg.id }
                                ]}
                            >
                                <Input allowClear autoComplete="off" placeholder={msg.id} disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item 
                                label={ln_t('affiliate.info.phone')} 
                                name="phone" 
                                rules={[
                                    { pattern: /^0[0-9]{7,9}$/, message: msg.pattern },
                                    { required: addBank, message: msg.phone }
                                ]}
                            >
                                <Input allowClear autoComplete="off" placeholder={msg.phone} inputMode="tel" disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item label={ln_t('affiliate.info.address')} name="address" rules={[{ required: addBank, message: msg.address }]}>
                                <Input allowClear autoComplete="off" placeholder={msg.address} disabled={bankVerified} />
                            </Form.Item>
                            <Form.Item label={ln_t('affiliate.info.birthDate')} name="birthDate" rules={[{ required: addBank, message: msg.birth }]}>
                                <DatePicker allowClear placeholder={msg.birth} style={{ width: '100%' }} disabled={bankVerified} />
                            </Form.Item>
                        </>
                    )}
                </div>
                {login ? loginButtons : (
                    <div className="d-flex justify-content-center mt-5">
                        {addBank ? <Button type="button" className="mr-2" onClick={() => pushStep('step1')}>{ln_t('util.previous')}</Button> : ''}
                        <Button htmlType="submit" type="primary" disabled={bankVerified}>{ln_t(`util.${addBank ? 'next' : 'confirmUpdate'}`)}</Button>
                    </div>
                )}
            </Form>
        </div>
    )
}

export default Profile
