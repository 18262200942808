import React, { useState } from 'react'
import { Form, Input, DatePicker } from 'antd'
import moment from 'moment'
import Selector from '../../../components/Util/Selector'

const { RangePicker } = DatePicker

function SearchForm(props) {
    const [form] = Form.useForm()

    const { ln_t, handleFetchParams, className } = props

    const typeOptions = [
        { value: 'email', label: ln_t('affiliate.email') },
        { value: 'affiliateId', label: ln_t('affiliate.id') },
    ]

    const [searchType, setSearchType] = useState(typeOptions[0].value)

    const handleTypeChange = value => {
        setSearchType(value)
        const n = Object.assign({}, ...typeOptions.map(opt => ({ [opt.value]: undefined }))) 
        form.setFieldsValue(n)
    }

    const onFinish = fieldValues => {
        const { dateRange } = fieldValues
        let n = {}
        const format = time => time.format('YYYY-MM-DD')
        if (dateRange?.length === 2) {
            const [dateForm, dateTo] = dateRange
            n = { ...n, createdAt_after: format(dateForm), createdAt_before: format(moment(dateTo).add(1, 'days')) }
        }
        if (fieldValues[searchType]) {
            n = { ...n, [searchType]: fieldValues[searchType] }
        }
        handleFetchParams('init', n)
    }

    return (
        <Form 
            form={form}
            className={`d-flex flex-wrap round-selector align-items-start ${className}`}
            onFinish={onFinish}
        >
            <Selector 
                options={typeOptions} 
                value={searchType} 
                onChange={handleTypeChange} 
                className="mb-1 mr-1"
                showSearch={false}
                style={{ width: '110px' }}
            />
            <Form.Item noStyle name={searchType}>
                <Input placeholder={`${ln_t('util.search')}...`} allowClear className="mb-1 mr-2" style={{ width: '240px' }} />
            </Form.Item>
            <Form.Item label={ln_t('affiliate.joinedDate')} name="dateRange" className="mb-1 mr-2">
                <RangePicker ranges={{
                    '1 week': [moment().subtract(1, 'week'), moment()],
                    '1 month': [moment().subtract(1, 'month'), moment()],
                    '3 months': [moment().subtract(3, 'months'), moment()],
                    '6 months': [moment().subtract(6, 'months'), moment()],
                    '1 year': [moment().subtract(364, 'days'), moment()],
                }}
                />
            </Form.Item>
            <button className="main-button mb-2" type="submit">{ln_t('util.search')}</button>
        </Form>
    )
}

export default SearchForm
