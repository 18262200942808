import React, { useEffect, useRef, useState } from 'react'
import { 
    EditorState, 
    convertToRaw, 
    Modifier, 
    CompositeDecorator, 
} from 'draft-js'
import { convertFromHTML } from 'draft-convert'
import Editor from '@draft-js-plugins/editor'
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
} from '@draft-js-plugins/buttons'
import { uniq } from 'lodash'
import { EditorWrapper, blockStyles } from './editor.styled.js'
import { 
    UploadImageButton, 
    CodeBlockButton, 
    linkPlugin, 
    toolbarPlugin, 
    plugins, 
    emptyContentState, 
    decorators,
    textAlignmentPlugin,
    imagePlugin,
    alignmentPlugin,
    // iframelyPlugin
} from './plugin'
import ColorFontBtn from './ColorFontBtn.js'
import { convertPlugins } from './objToHtml.js'

const { Toolbar } = toolbarPlugin
const { AlignmentTool } = alignmentPlugin
// const { EmbedButton } = iframelyPlugin

function InlineEditor(props) {
    const { setObjBeforeConvert, setContentStorage, editMode, fieldsData, postId, setFiles, files } = props
    const [show, setShow] = useState(false)
    const [editorState, setEditorState] = useState()
    const [fontColorList, setFontColorList] = useState([])
    const [showPicker, setShowPicker] = useState(false)
    const [color, setColor] = useState({ r: 34, g: 34, b: 34, a: 1 })

    const editorRef = useRef(null)

    const initColor = editorState => {
        const currentStyle = editorState.getCurrentInlineStyle()
        const c = { r: 34, g: 34, b: 34, a: 1 }
        for (const item of currentStyle.values()) {
            if (item?.includes('rgb')) {
                const arr = item.replace(/[rgba()]/gi, '').split(',')
                // eslint-disable-next-line no-return-assign
                Object.keys(c).forEach((key, index) => c[key] = arr[index])
            }
        }
        return c
    }

    const onChange = value => {
        const contentState = value.getCurrentContent()
        setEditorState(value)
        setObjBeforeConvert(contentState)
        if (!contentState) return
        const blocks = convertToRaw(contentState)
        setContentStorage(blocks)
        setShowPicker(false)
        setColor(initColor(value))
    }

    const onTab = e => {
        e.preventDefault()
        const newContentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            '    '
        )
        setEditorState(EditorState.push(editorState, newContentState, 'insert-characters'))
    }

    const initEditor = () => {
        let state = emptyContentState
        if (editMode) {
            if (postId) state = convertFromHTML(convertPlugins.fromHtml)(fieldsData.content)
            // else state = convertFromRaw(editStorage.contentStorage)
        }
        setEditorState(EditorState.createWithContent(state, new CompositeDecorator(decorators)))
        setShow(true)
    }

    useEffect(() => {
        initEditor()
    }, [])

    const uploadBtnProps = {
        onEditorStateChange: newState => setEditorState(newState),
        editorState,
        setFiles,
        files
    }

    return (
        show 
            ? (
                <EditorWrapper>
                    <Editor
                        // readOnly={true}
                        editorState={editorState}
                        placeholder="News Content..."
                        onChange={onChange}
                        plugins={plugins}
                        decorators={decorators}
                        blockStyleFn={blockStyles}
                        customStyleFn={(style, block) => {
                            for (const item of style.values()) {
                                if (item?.includes('rgb')) {
                                    // console.log('item', item)
                                    setFontColorList(prev => uniq([...prev, item]))
                                    return { color: item } 
                                }
                            }
                        }}
                        onTab={e => onTab(e)}
                        // decorators={decorators}
                        ref={element => {
                            editorRef.current = element
                        }}
                    />
                    <AlignmentTool />
                    <Toolbar>
                        {externalProps => (
                            <>
                                <UploadImageButton 
                                    {...externalProps}
                                    {...uploadBtnProps}
                                    buttonProps={{ title: 'Add Image' }}
                                    modifier={imagePlugin.addImage}
                                />
                                {/* <EmbedButton {...externalProps} editorState={editorState} setEditorState={setEditorState} /> */}
                                <linkPlugin.LinkButton {...externalProps} buttonProps={{ title: 'Attach Link' }} />
                                <BoldButton {...externalProps} buttonProps={{ title: 'Bold' }} />
                                <ItalicButton {...externalProps} buttonProps={{ title: 'Italic' }} />
                                <UnderlineButton {...externalProps} buttonProps={{ title: 'Underline' }} />
                                <ColorFontBtn 
                                    editorState={editorState} 
                                    setEditorState={setEditorState} 
                                    fontColorList={fontColorList}
                                    addFontColor={item => setFontColorList(uniq([...fontColorList, item]))}
                                    showPicker={showPicker}
                                    setShowPicker={setShowPicker}
                                    color={color}
                                    setColor={setColor}
                                />
                                <textAlignmentPlugin.TextAlignment {...externalProps} />
                                <HeadlineOneButton {...externalProps} buttonProps={{ title: 'H1' }} />
                                <HeadlineTwoButton {...externalProps} buttonProps={{ title: 'H2' }} />
                                <HeadlineThreeButton {...externalProps} buttonProps={{ title: 'H3' }} />
                                <UnorderedListButton {...externalProps} buttonProps={{ title: 'List' }} />
                                <OrderedListButton {...externalProps} buttonProps={{ title: 'Ordered List' }} />
                                {/* <CodeBlockButton {...externalProps} buttonProps={{ title: 'Code' }} /> */}
                                <BlockquoteButton {...externalProps} buttonProps={{ title: 'Blockquote' }} />
                            </>
                        )}
                    </Toolbar>
                </EditorWrapper>
            ) : ''
    )
}

export default InlineEditor
