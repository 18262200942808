/* eslint-disable no-bitwise */
const xlsx = require('xlsx')

export const downloadExcel = (jsonData, name) => {
    const jsonWorkSheet = xlsx.utils.json_to_sheet(jsonData)
    // Set sheet name
    const workBook = {
        SheetNames: ['Sheet'],
        Sheets: {
            Sheet: { ...jsonWorkSheet,
                A1: {
                    v: Object.keys(jsonData[0])[0],
                    s: {
                        fill: { fgColor: { rgb: 'FFC0504D' } },
                        font: { sz: 20 },
                    }
                }
        
            } }
    }
    
    // File path
    const filePath = `${name}_download_${ 
        (function (){
            const tmpT = new Date()
            const tmpTime = tmpT.toLocaleTimeString().split(':').join('')
            const tmpDate = tmpT.toLocaleDateString()
            return `${tmpDate}_${tmpTime}`
        }())
    }.xlsx`

    xlsx.writeFile(workBook, filePath)
  
    return filePath
}

export function sheet2blob(workbook) {
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
        return buf
    }
  
    const wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    }
    const wbout = xlsx.write(workbook, wopts)
    const blob = new Blob([s2ab(wbout)], {
        type: 'application/octet-stream'
    }) // 字符串转ArrayBuffer
    return blob
}
  
export function openDownloadDialog(url, saveName) {
    if (typeof url === 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url) // 创建blob地址
    }

    const aLink = document.createElement('a')
    aLink.href = url
    aLink.download = saveName || '' // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    let event
    if (window.MouseEvent) event = new MouseEvent('click')
    else {
        event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    }
    aLink.dispatchEvent(event)
}

export function handleDownload(SheetNames, jsonData, fileName, customColNames) {
    if (jsonData && Array.isArray(jsonData)) {
        const workbook = {
            SheetNames,
            Sheets: {}
        }

        const sheet1 = jsonData.map(item => xlsx.utils.json_to_sheet(item, customColNames ? { 
            skipHeader: true,
        } : {}))

        SheetNames.forEach((item, index) => {
            workbook.Sheets[item] = sheet1[index] // 生成excel的配置项
        })
        
        const filePath = `${fileName}.xlsx`
    
        openDownloadDialog(sheet2blob(workbook), filePath)
        return { result: 'success', message: '下載成功' }
    } return { result: 'error', message: '資料格式錯誤' }
}
