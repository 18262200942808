import React, { useEffect, useState } from 'react'
import { Button, message, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { EditFilled, PlusOutlined, QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons'
import { fetchAffiliateBankListByEnterprise, fetchSummary, requestWithdraw } from '../../apis/affiliateWeb' 
import './BankAndWithdraw.less'

function BankAndWithdraw(props) {
    const { userInfo, ln_t, history, setIsLoading, modalConf } = props

    const { roleId, nowEnterprise } = userInfo || {}
    const { isBankMultiple, enterprise, minWithdraw, withdrawalDateStart, withdrawalDateEnd } = nowEnterprise || {}
    const [banks, setBanks] = useState([])

    const getBanks = async () => {
        setIsLoading(true)
        const result = await fetchAffiliateBankListByEnterprise(roleId, enterprise)
        if (result?.status === 200) {
            const { results } = result.data
            setBanks(results)
        }
        setIsLoading(false)
    }

    const request = async bankAccount => {
        const result = await requestWithdraw(roleId, enterprise, { bankAccount })
        if (result?.status === 200) {
            const { status, message: msg } = result.data
            if (status === 'failed') message.info(msg)
            else message.info(ln_t('message.success.withdraw'))
        }
    }

    const handleWithdraw = async () => {
        const bankAccount = banks?.[0]?.bankAccount
        if (!bankAccount) return message.info(ln_t('message.info.createBankAccountFirst'))
        const hiddenAccount = [...Array(bankAccount.length - 4)].map(() => '*').join('') + bankAccount.slice(bankAccount.length - 4)

        setIsLoading(true)
        const result = await fetchSummary(roleId, enterprise)
        if (result?.status === 200) {
            const { available_earning } = result.data?.[0] || {}
            if (!available_earning || available_earning < minWithdraw) message.info(ln_t('message.info.doesNotAchieveMinWithdraw'))
            else {
                Modal.confirm({
                    ...modalConf,
                    title: ln_t('bank.withdrawalConfirmation'),
                    content: (
                        <>
                            <div>{ln_t('bank.yourWithdrawalAmount')}：${available_earning.toLocaleString()}</div>
                            <div>{ln_t('bank.yourBankAccount')}：{hiddenAccount}</div>
                        </>
                    ),
                    onOk: async () => request(bankAccount)
                })
            }
        }

        setIsLoading(false)
    }

    useEffect(() => {
        if (!roleId || !enterprise) return
        getBanks()
    }, [])

    const invalidToWithdraw = () => {
        if (!banks?.[0]?.isVerified) return true
        const today = new Date().getDate()
        if (today < withdrawalDateStart) return true
        if (today > withdrawalDateEnd) return true
        return false
    }

    return (
        <div className="d-flex fd-col justify-content-between h-100">
            <div>
                <h4 className="text-center">{ln_t('bank.requestWithdraw')}</h4>
                <section className="d-flex align-items-center my-5">
                    {banks?.map(bank => {
                        const { bankAccountName, bankName, bankAccount, bankBranchCode, id, isVerified } = bank
                        const hiddenAccount = [...Array(bankAccount.length - 4)].map(() => '*').join('') + bankAccount.slice(bankAccount.length - 4)
                        const nameLength1_3 = Math.ceil(bankAccountName.length / 3)
                        const hiddenName = `${bankAccountName.slice(0, nameLength1_3)}${[...Array(nameLength1_3)].map(() => '*').join('')}${bankAccountName.slice(nameLength1_3 * 2)}`
                        return (
                            <div 
                                className="bank-card d-flex fd-col justify-content-between"
                            >
                                <div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6>{bankName}</h6>
                                        {!isVerified ? (
                                            <EditFilled 
                                                className="cursor-pointer" 
                                                onClick={() => history.push({ pathname: '/affiliate/bank/modify', state: { id } })}
                                            />
                                        ) : <CheckOutlined />}
                                    </div>
                                    <h6>{hiddenName}</h6>
                                    <h6>{hiddenAccount}</h6>
                                    <h6>{bankBranchCode}</h6>
                                </div>
                                <h6 className="text-right mt-3">{isVerified ? ln_t('bank.verified') : ln_t('bank.underReview')}</h6>
                            </div>
                        )
                    })}
                    {!banks?.length || isBankMultiple 
                        ? (
                            <div 
                                className="bank-card d-flex align-items-center justify-content-center cursor-pointer"
                                onClick={() => history.push('/affiliate/bank/add/step1')}
                            >
                                <div className="d-flex fd-col align-items-center">
                                    <h4 className="mb-3"><PlusOutlined /></h4>
                                    <h5>{ln_t('bank.addAccount')}</h5>
                                </div>
                            </div>
                        ) : ''}
                </section>
                <section className="pl-2">
                    <ol className="p-0 ml-3">
                        <li>
                            <h6 style={{ color: 'red' }}>
                                <span style={{ color: 'black' }}>{ln_t('bank.beforeWithdrawStep1a')}</span>
                                {ln_t('bank.beforeWithdrawStep1b')}
                            </h6>
                        </li> 
                        <li><h6>{ln_t('bank.beforeWithdrawStep2')}</h6></li>
                        <li><h6>{ln_t('bank.beforeWithdrawStep3')}</h6></li> 
                        {nowEnterprise?.name?.toLowerCase() === 'eztravel'
                            ? (
                                <li>
                                    <h6 className="red-rule">
                                        {ln_t('bank.beforeWithdrawStep4')}
                                        <ol className="ol-star" style={{ padding: '5px 0px 0px 15px' }}>{ln_t('bank.beforeWithdrawStep4a')}</ol>
                                        <ol className="ol-star" style={{ padding: '5px 0px 0px 15px' }}>{ln_t('bank.beforeWithdrawStep4b')}</ol>
                                    </h6>
                                </li>
                            )
                            : ''}
                    </ol>

                    {nowEnterprise?.name?.toLowerCase() === 'eztravel'
                        ? (
                            <div 
                                className="cursor-pointer mt-4 mb-1" 
                                onClick={() => history.push('/affiliate/account/request/howToVerify')}
                                style={{ textDecoration: 'underline' }}
                            >
                                <span className="d-flex align-items-center">
                                    <QuestionCircleOutlined className="mr-2" />{ln_t('bank.howToVerify')}
                                </span>
                            </div>
                        )
                        : ''}
                    <div className="d-flex align-items-center">
                        <QuestionCircleOutlined className="mr-2" />
                        <span>
                            {ln_t('bank.learnMoreAboutOur')}
                            <Link to="/affiliate/account/request/rules&terms" style={{ textDecoration: 'underline', color: 'black' }}>
                                {ln_t('enterpriseTC.userRules')}
                            </Link>
                            {ln_t('util.and')}
                            <Link to="/affiliate/account/request/rules&terms" style={{ textDecoration: 'underline', color: 'black' }}>
                                {ln_t('enterpriseTC.termsAndCondition')}
                            </Link>
                        </span>
                    </div>
                </section>
            </div>
            <div className="d-flex justify-content-center mt-5">
                <Button 
                    size="large" 
                    onClick={() => handleWithdraw()}
                    disabled={invalidToWithdraw()}
                >
                    {ln_t('bank.withdraw')}
                </Button>
            </div>
        </div>
    )
}

export default BankAndWithdraw
