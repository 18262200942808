import React from 'react'
import { Form, DatePicker } from 'antd'
import moment from 'moment'
import Selector from '../../../components/Util/Selector'

const { RangePicker } = DatePicker

function SearchForm(props) {
    const [form] = Form.useForm()

    const { ln_t, handleFetchParams, className, dateConf, statusOptions } = props
    const { name: dateName, label: dateLabel } = dateConf || {}

    const onFinish = fieldValues => {
        const { status } = fieldValues
        let n = {}
        const format = time => time.format('YYYY-MM-DD')
        if (status !== 'ALL') n.status = status
        if (fieldValues[dateName]?.length === 2) {
            const [after, before] = fieldValues[dateName]
            n = { ...n, [`${dateName}_after`]: format(after), [`${dateName}_before`]: format(before) }
        }
        handleFetchParams('init', n)
    }

    return (
        <Form 
            form={form}
            className={`d-flex flex-wrap round-selector ${className}`}
            onFinish={onFinish}
            initialValues={{ status: 'ALL' }}
        >
            <Form.Item label={dateLabel} name={dateName} className="mb-1 mr-3">
                <RangePicker ranges={{
                    '1 week': [moment().subtract(1, 'week'), moment()],
                    '1 month': [moment().subtract(1, 'month'), moment()],
                    '3 months': [moment().subtract(3, 'months'), moment()],
                    '6 months': [moment().subtract(6, 'months'), moment()],
                    '1 year': [moment().subtract(364, 'days'), moment()],
                }}
                />
            </Form.Item>
            <Form.Item name="status" className="mb-1 mr-2">
                <Selector 
                    options={statusOptions} 
                    style={{ width: '150px' }}
                />
            </Form.Item>
            <button className="main-button mb-2" type="submit">{ln_t('util.search')}</button>
        </Form>
    )
}

export default SearchForm
