import React, { useState, useEffect } from 'react'
import { LinkOutlined, UserOutlined, DollarCircleOutlined, WalletOutlined, ProfileOutlined, CaretDownOutlined, CaretUpOutlined, SolutionOutlined, NotificationOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { ROUTEBASE, AFFILIATE } from '../../constants/Routing'
import './Menu.less'

const formatNumber = number => number.toLocaleString()

function SidebarMenu(props) {
    const { userInfo, ln_t, location, width } = props
    const { pathname } = location || {}

    const menu = [
        { 
            key: 'account',
            icon: <UserOutlined />,
            children: [
                { key: 'profile', link: '/account/profile', icon: <SolutionOutlined className="mr-2" /> },
                { key: 'taxInformation', link: '/account/taxInformation', icon: <ProfileOutlined className="mr-2" /> },
                { key: 'request', link: '/account/request', icon: <DollarCircleOutlined className="mr-2" /> },
            ] },
        { key: 'referralLink', link: '/links', icon: <LinkOutlined /> },
        { key: 'earning', link: '/earning', icon: <WalletOutlined /> },
        { key: 'news', link: '/news', icon: <NotificationOutlined /> },
    ]

    return (
        <div className="affiliate-menu">
            {menu.map(item => {
                const { key, icon, link, children } = item
                return children ? (
                    <div key={key}>
                        <div className="menu-item mb-2 menu-header">
                            {icon}
                            <span className="item-title">{ln_t(`sidebarMenu.label.${key}`)}</span>
                        </div>
                        <div className="ml-3">
                            {children.map(child => (
                                <Link
                                    key={child.key}
                                    className={`menu-item mb-2 ${pathname.includes(child.link) ? 'active' : ''}`} 
                                    to={`${ROUTEBASE[AFFILIATE]}${child.link}`}
                                >
                                    {child.icon}
                                    <span className="item-title">{ln_t(`sidebarMenu.label.${child.key}`)}</span>
                                </Link>
                            ))}
                        </div>
                    </div>

                ) : (
                    <Link
                        key={key}
                        className={`menu-item mb-2 ${pathname.includes(link) ? 'active' : ''}`} 
                        to={`${ROUTEBASE[AFFILIATE]}${link}`}
                    >
                        {icon}
                        <span className="item-title">{ln_t(`sidebarMenu.label.${key}`)}</span>
                    </Link>
                )
            })}
        </div>
    )
}

export default SidebarMenu
