import React, { useEffect, useState } from 'react'
import { Button, Spin, message } from 'antd'
import { useParams, withRouter } from 'react-router-dom'
import { AFFILIATE, ROUTEHOME, ROUTELOGIN } from '../../../constants/Routing'
import { modStatusTc } from '../../../apis/affiliate'
import RuleAndTerms from '../../../components/Terms/Main'
import { USERPROFILE } from '../../../constants/ActionTypes'
import useCheckEnterprise from '../../../hook/useCheckEnterprise'
import '../../Login/Login.less'

function Terms(props) {
    const { location, history, ln_t, langOptions, changeLang, dispatch, userInfo } = props

    const { nowEnterprise } = userInfo || {}

    const { enterpriseName } = useParams()
    const enterpriseId = useCheckEnterprise(enterpriseName, history)

    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('brandRules')

    const { state } = location || {}
    const { email, affiliate } = state || {}

    const onFinish = async () => {
        setLoading(true)

        const result = await modStatusTc(affiliate.id, enterpriseId, { statusTC: 'accepted' })
        if (result?.status === 200) {
            dispatch({ type: USERPROFILE.PATCH, payload: { nowEnterprise: { ...nowEnterprise, isAffiliateTC: 'accepted' } } })
            history.push(ROUTEHOME.affiliate)
        } else message.info(result?.data.message || ln_t('message.error.serverError'))

        setLoading(false)
    }

    const onTabChange = key => setActiveTab(key)

    useEffect(() => {
        if (!email || !affiliate) history.push(ROUTELOGIN[AFFILIATE])
    }, [])

    return (
        <main className="login-container">
            <section className="login-inner large">
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <Spin spinning={loading}>
                    <RuleAndTerms activeKey={activeTab} onTabChange={onTabChange} />
                    <div className="text-center mt-5">
                        <Button type="button" onClick={() => onFinish()}>{ln_t('user.acceptTerms')}</Button>
                    </div>
                </Spin>
            </section>
        </main>
    )
}

export default withRouter(Terms)
