import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Modal, Form, Input, Spin, Divider, DatePicker, message } from 'antd'
import FormFooter from '../../../components/Util/FormFooter'
import Selector from '../../../components/Util/Selector'
import { fetchBankList } from '../../../apis/affiliateWeb'
import { modVerification } from '../../../apis/bankVerify'

function PersonalVerifyForm(props) {
    const [form] = Form.useForm()
    const { modalVisible, setModalVisible, userInfo, ln_t, handleFetchParams, editDatas, setEditDatas } = props

    const { nowEnterprise } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const [fromLoading, setFormLoading] = useState(false)
    const [bankList, setBankList] = useState([])

    const onCancel = () => {
        form.resetFields()
        setEditDatas()
        setModalVisible(false)
    }

    const onFinish = async fieldValues => {
        setFormLoading(true)
        const { bank, bankAccount, bankAccountName, bankBranchCode, idNumber, address, birthDate, fullName } = fieldValues
        const n = { 
            affiliate: editDatas.affiliate,
            id: editDatas.id,
            bank, 
            bankAccount, 
            bankAccountName, 
            bankBranchCode, 
            fullName,
            idNumber, 
            address, 
            birthDate: birthDate.format('YYYY-MM-DD') 
        }
        const result = await modVerification(enterprise, n)
        if (result?.status === 200) {
            message.info(ln_t('message.success.edit'))
            handleFetchParams('patch')
            onCancel()
        }

        setFormLoading(false)
    }

    const msg = {
        fullName: ln_t('affiliate.placeholder.fullName'),
        id: ln_t('affiliate.placeholder.ID'),
        phone: ln_t('affiliate.placeholder.phone'),
        address: ln_t('affiliate.placeholder.address'),
        birth: ln_t('affiliate.placeholder.birth'),
        pattern: ln_t('affiliate.validation.matchFormat'),
        bankName: ln_t('bank.placeholder.bankName'),
        bankAccountName: ln_t('bank.placeholder.bankAccountName'),
        bankAccountNumber: ln_t('bank.placeholder.bankAccountNumber'),
        branchCode: ln_t('bank.placeholder.branchCode')
    }

    const getBankList = async () => {
        setFormLoading(true)
        const { birthDate } = editDatas || {}
        form.setFieldsValue({ ...editDatas, birthDate: moment(birthDate) })
        const result = await fetchBankList()
        if (result?.status === 200) setBankList(result.data.results.map(res => ({ label: res.name, value: res.code })))
        setFormLoading(false)
    }

    useEffect(() => {
        if (!modalVisible || !editDatas) return
        getBankList()
    }, [modalVisible])

    return (
        <Modal 
            visible={modalVisible} 
            footer={false} 
            onCancel={onCancel}
            centered
        >
            <Spin spinning={fromLoading}>
                <Divider>{ln_t('verify.modifyInformation')}</Divider>
                <Form
                    form={form}
                    onFinish={onFinish}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Form.Item label={ln_t('bank.bankName')} name="bank" rules={[{ required: true, message: msg.bankName }]}>
                        <Selector options={bankList} placeholder={msg.bankName} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('bank.longBankAccountName')} 
                        name="bankAccountName" 
                        rules={[
                            { required: true, message: msg.bankAccountName },
                            { min: 2, message: ln_t('bank.placeholder.bankAccountNameLength') },
                        ]}
                    >
                        <Input allowClear autoComplete="off" placeholder={msg.bankAccountName} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('bank.bankAccountNumber')} 
                        name="bankAccount" 
                        rules={[
                            { required: true, message: msg.bankAccountNumber },
                            { pattern: /^[0-9]{12,16}$/, message: msg.pattern }
                        ]}
                    >
                        <Input allowClear autoComplete="off" placeholder={msg.bankAccountNumber} inputMode="numeric" />
                    </Form.Item>
                    <Form.Item label={ln_t('bank.bankBranchCode')} name="bankBranchCode" rules={[{ required: true, message: msg.branchCode }]}>
                        <Input allowClear autoComplete="off" placeholder={msg.branchCode} inputMode="numeric" />
                    </Form.Item>
                    <Form.Item label={ln_t('affiliate.info.fullName')} name="fullName" rules={[{ required: true, message: msg.fullName }]}>
                        <Input allowClear autoComplete="off" placeholder={msg.fullName} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('affiliate.info.ID')} 
                        name="idNumber" 
                        rules={[
                            { pattern: /^[A-Z]{1,1}[0-9]{9,9}$/, message: msg.pattern },
                            { required: true, message: msg.id }
                        ]}
                    >
                        <Input allowClear autoComplete="off" placeholder={msg.id} />
                    </Form.Item>
                    <Form.Item label={ln_t('affiliate.info.address')} name="address" rules={[{ required: true, message: msg.address }]}>
                        <Input allowClear autoComplete="off" placeholder={msg.address} />
                    </Form.Item>
                    <Form.Item label={ln_t('affiliate.info.birthDate')} name="birthDate" rules={[{ required: true, message: msg.birth }]}>
                        <DatePicker allowClear placeholder={msg.birth} style={{ width: '100%' }} />
                    </Form.Item>

                    <FormFooter
                        submitBtnTitle={ln_t('util.confirm')}
                        className="mt-2"
                        onCancel={onCancel}
                    />
                </Form>
            </Spin>
        </Modal>
    )
}

export default PersonalVerifyForm
