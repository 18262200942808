import React, { useEffect, useState } from 'react'
import { Button, Empty, message, Modal, Spin } from 'antd'
import { DeleteOutlined, EditOutlined, NotificationOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons'
import moment from 'moment'
import SearchForm from './SearchForm'
import './News.less'
import { fetchNews, fetchNewsCategory, fetchSingleNews, modNews } from '../../../apis/news'
import useParams from '../../../hook/useParams'

function News(props) {
    const { ln_t, history, userInfo, modalConf } = props
    const { nowEnterprise, lang } = userInfo || {}
    const params = useParams({ sort: '-isPinned,-updatedTime' })
    const { fetchParams } = params
    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [news, setNews] = useState()
    const [categories, setCategories] = useState()
    const apiFetchNews = async (enterpriseId, params) => fetchNews(enterpriseId, params)
    const apiModify = async (enterpriseId, id, params) => modNews(enterpriseId, id, params)
    const apiFetchSingle = async (enterpriseId, id) => fetchSingleNews(enterpriseId, id)

    const getNews = async () => {
        setLoading(true)
        const result = await apiFetchNews(nowEnterprise?.enterprise, fetchParams)
        if (result?.status === 200) setNews(result.data.results)
        setLoading(false)
    }

    const handleChange = async (id, params, successMsg) => {
        const result = await apiModify(nowEnterprise?.enterprise, id, params)
        if (result?.status === 200) {
            message.info(successMsg)
            getNews()
        }
    }

    const handleDelete = (item, type) => {
        Modal.confirm({
            ...modalConf,
            title: `${ln_t('util.confirmDelete')} ${item.title}？`,
            onOk: () => handleChange(item.id, { isDeleted: true }, ln_t('message.success.delete'))
        })
    }

    const handleEdit = async id => {
        const result = await apiFetchSingle(nowEnterprise?.enterprise, id)
        if (result?.status === 200) {
            history.push({
                pathname: '/enterprise/setting/news/edit',
                state: { data: result.data }
            })
        }
    }

    const getCategoryOpt = async () => {
        const res = await fetchNewsCategory(nowEnterprise?.enterprise, { maxResult: 999 })
        if (res?.status === 200) setCategories(res?.data?.results)
    }
    useEffect(() => {
        getNews()
        getCategoryOpt()
        setReady(true)
    }, [])

    useEffect(() => {
        if (ready) getNews()
    }, [fetchParams])

    const formatDate = date => moment(date).format('YYYY-MM-DD')

    const passProps = {
        ...props,
        params,
        categories
    }

    return (
        <>
            <SearchForm {...passProps} className="news-search" />
            <Spin className="news" spinning={loading} style={{ minHeight: '40px' }}>
                {news?.map(item => (
                    <div className="news-wrapper" key={item.id}>
                        <div className="news-header">
                            {item.isPinned ? <PushpinFilled style={{ fontSize: '16px', margin: 'auto', paddingRight: '6px', color: '#0ab3a1' }} /> : ''}
                            <div className="title">{item.title}</div>
                            <div className={`status ${item.isPublished ? 'published' : 'draft'}`}>
                                {item.isPublished ? ln_t('news.published') : ln_t('news.draft')}
                            </div>
                        </div>
                        <div className="desc">{item.descriptions}</div>
                        <div className="news-footer">
                            <div className="date">
                                {formatDate(item.campaignStart)} - {formatDate(item.campaignEnd)}
                            </div>
                            <div className="button">
                                {item.isPinned 
                                    ? (
                                        <Button
                                            type="link"
                                            icon={<><PushpinOutlined style={{ fontSize: '15px' }} /><div className="icon-slash" /></>}
                                            onClick={() => handleChange(item.id, { isPinned: false }, ln_t('message.success.unpinned'))}
                                        >
                                            {ln_t('news.unpin')}
                                        </Button>
                                    )
                                    : (
                                        <Button
                                            type="link"
                                            icon={<PushpinOutlined style={{ fontSize: '15px' }} />}
                                            onClick={() => handleChange(item.id, { isPinned: true }, ln_t('message.success.pinned'))}
                                        >
                                            {ln_t('news.pin')}
                                        </Button>
                                    )}
                                {item.isPublished ? '' : (
                                    <Button type="link" icon={<NotificationOutlined />} onClick={() => handleChange(item.id, { isPublished: true }, ln_t('message.success.published'))}>
                                        {ln_t('news.publish')}
                                    </Button>
                                )}
                                <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(item.id)}>
                                    {ln_t('news.edit')}
                                </Button>
                                <Button type="link" icon={<DeleteOutlined />} onClick={() => handleDelete(item, 'delete')}>
                                    {ln_t('news.delete')}
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
                {news?.length === 0 ? <Empty style={{ padding: '50px 0px' }} /> : ''}
            </Spin>
        </>
    )
}

export default News
