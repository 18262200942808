import React from 'react'
import { pick } from 'lodash'
import { message } from 'antd'
import moment from 'moment'
import MainTable from '../../../components/Util/MainTable'
import { fetchOrderListByEnterprise } from '../../../apis/order'
import useParams from '../../../hook/useParams'
import SearchForm from './SearchForm'

function OrderList(props) {
    const { ln_t, userInfo, setIsLoading, download } = props
    const { nowEnterprise } = userInfo || {}
    const params = useParams({ sort: '-updatedAt' })
    const { fetchParams } = params
        
    const columns = [
        {
            title: ln_t('affiliate.id'),
            dataIndex: 'ouid',
            sorter: true,
        },
        {
            title: ln_t('affiliate.name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: ln_t('order.id'),
            dataIndex: 'transactionId',
            sorter: true,
        },
        {
            title: ln_t('order.date'),
            dataIndex: 'orderDate',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        },
        {
            title: ln_t('order.orderStatus'),
            dataIndex: 'orderStatus',
            sorter: true,
            render: e => ln_t(`util.orderStatus.${e}`)
        },
        {
            title: ln_t('order.category'),
            dataIndex: 'product',
        },
        {
            title: ln_t('order.description'),
            dataIndex: 'description',
        },
        {
            title: ln_t('order.amount'),
            dataIndex: 'amount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('order.amountPaid'),
            dataIndex: 'amountPaid',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('order.referralAmount'),
            dataIndex: 'referralAmount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('order.expectedToPay'),
            dataIndex: 'expectedToConfirm',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        },
        {
            title: ln_t('order.updatedAt'),
            dataIndex: 'updatedAt',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        },
    ]

    const apiFunc = async () => fetchOrderListByEnterprise(nowEnterprise?.enterprise, fetchParams)

    const handleDownload = async () => {
        const { orderStatus, orderDate_after, orderDate_before, expectedToConfirm_before, expectedToConfirm_after } = fetchParams
        const after = orderDate_after || expectedToConfirm_after
        const before = orderDate_before || expectedToConfirm_before
        const oneMonthAgo = moment(before)?.subtract(1, 'months').subtract(1, 'days')
        if (!after || !before || oneMonthAgo > moment(after)) return message.error(ln_t('message.error.cantDownloadMoreThanOneMonthData'))
        setIsLoading(true)
        const result = await fetchOrderListByEnterprise(nowEnterprise?.enterprise, { 
            sort: '-updatedAt', orderDate_after, orderDate_before, expectedToConfirm_after, expectedToConfirm_before, orderStatus
        })
        if (result?.status === 200) {
            const formatDate = date => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '')
            const formattedResult = result.data.map(({ orderDate, expectedToConfirm, createdAt, updatedAt, withdrawalDate, confirmedDate, affiliate, ...other }) => ({
                ...other,
                ...affiliate,
                orderDate: formatDate(orderDate),
                expectedToConfirm: formatDate(expectedToConfirm),
                createdAt: formatDate(createdAt),
                updatedAt: formatDate(updatedAt),
                withdrawalDate: formatDate(withdrawalDate),
                confirmedDate: formatDate(confirmedDate),
            }))
            const neededCols = ['ouid', 'name', 'transactionId', 'orderDate', 'orderStatus', 'product', 
                'description', 'amount', 'amountPaid', 'referralRate', 'referralAmount', 'expectedToConfirm', 'createdAt', 'updatedAt', 
                'withdrawalDate', 'status', 'confirmedDate', 'affiliateBankAccount', 'confirmedByName']
            const customNaming = { ouid: ln_t('affiliate.id'), name: ln_t('affiliate.name'), }
            const colNames = Object.assign({}, ...neededCols.map(col => ({ [col]: customNaming[col] || ln_t(`order.${col}`) })))
            const n = formattedResult.map(item => pick(item, neededCols))
            download([[colNames, ...n]], ln_t('breadcrumb.order.list'), true)
        }
        setIsLoading(false)
    }
    
    return (
        <MainTable 
            {...props}
            className="order-list"
            tableName="order"
            columns={columns}
            rowKey="transactionId"
            apiFunc={apiFunc}
            params={params}
            useHeader
            headerRender={<SearchForm {...props} {...params} />}
            btnTitle={ln_t('util.download')}
            onBtnClick={handleDownload}
        />
    )
}

export default OrderList
