import React, { useRef, useEffect, useState } from 'react'
import { convertFromRaw, Entity } from 'draft-js'
import { createBlockStyleButton } from '@draft-js-plugins/buttons'
import { PictureOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { composeDecorators } from '@draft-js-plugins/editor'
import createLinkPlugin from '@draft-js-plugins/anchor'
import createTextAlignmentPlugin from '@draft-js-plugins/text-alignment'
import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
import createImagePlugin from '@draft-js-plugins/image'
import createAlignmentPlugin from '@draft-js-plugins/alignment'
import createFocusPlugin from '@draft-js-plugins/focus'
import createResizeablePlugin from '@draft-js-plugins/resizeable'
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop'
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload'
// import createIframelyPlugin from '@jimmycode/draft-js-iframely-plugin'
import IframeEmbed from './IframeEmbed'
import ImageBlock from './ImageBlock'
  
function UploadBtnWrapper(props) {
    const { children = '', fieldName = 'file', action = '/api/uploadPostImage', 
        accept = 'image/*', editorState, modifier, onEditorStateChange, setFiles, files } = props
    const fileInputRef = useRef()

    const onClick = e => {
        fileInputRef.current.click()
    }

    const addFile = (fileUrl, file) => {
        if (!editorState || !onEditorStateChange || !modifier) return
        const extraData = { 
            title: file.name, 
            size: {
                height: 200,
                width: 350
            },
            alignment: 'center', 
        }
        onEditorStateChange(modifier(editorState, fileUrl, extraData))
        // onEditorStateChange(modifier(editorState, `/postImages/${fileUrl}`, extraData))
        const prev = files || []
        setFiles([...prev, { [fileUrl]: file }])
    }

    const onSubmit = async file => {
        const { name } = file
        const fileUrl = URL.createObjectURL(file)
        addFile(fileUrl, file)
        // const body = new FormData()
        // body.append("file", file)
        // const result = await fetch(action, { method: "POST", body })
        // if (result?.status === 201) {
        //   const data = await result.json()
        //   const { newFilename, originalFilename } = data
        //   addFile(newFilename, originalFilename)
        // }
    }

    const fileChange = e => {
        e.preventDefault()
        const { files } = e.target
        if (files?.length !== 1) return 
        onSubmit(files[0])
    }

    return (
        <div className="bi09khh"> 
            <input 
                id={fieldName} 
                name={fieldName}
                type="file" 
                hidden 
                ref={fileInputRef} 
                accept={accept}
                onChange={e => fileChange(e)}
            />
            <button type="button" className="bc4rxid" onClick={e => onClick(e)}>
                {children}
            </button>
        </div>
    )
}

export function UploadImageButton(props) {
    return (
        <UploadBtnWrapper {...props}>
            <PictureOutlined />
        </UploadBtnWrapper>
    )
}

export function UploadVideoButton(props) {
    return (
        <UploadBtnWrapper {...props} accept="video/*">
            <PlayCircleOutlined />
        </UploadBtnWrapper>
    )
}
  
export const CodeBlockButton = createBlockStyleButton({ blockType: 'code-block', children: 'code' })

function EditorLink(props) {
    const { entityKey, children } = props
    const { url } = Entity.get(entityKey).getData()
    // console.log(url)

    return (
        <a 
            href={url} 
            title={`press cmd + click to open ${url}`} 
            onClick={e => {
                e.metaKey && window.open(url)
            }}
            className="primary-link"
        >
            {children}
        </a>
    )
}

export const linkPlugin = createLinkPlugin({
    // Link: EditorLink,
    placeholder: 'https://...',
})

const focusPlugin = createFocusPlugin()
const resizeablePlugin = createResizeablePlugin({
    vertical: 'relative',
    horizontal: 'relative'
})
const blockDndPlugin = createBlockDndPlugin()
export const textAlignmentPlugin = createTextAlignmentPlugin()
export const toolbarPlugin = createToolbarPlugin()
export const alignmentPlugin = createAlignmentPlugin()

const imageDecorators = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
)

export const imagePlugin = createImagePlugin({ 
    imageComponent: ImageBlock
})
// export const iframelyPlugin = createIframelyPlugin({
//     embedComponent: IframeEmbed,
//     options: {
//         placeholder: 'Paste a link and press Enter to embed iframe ~~',
//         apiKey: 'b4c4123adf581cf51504c4',
//         handleOnReturn: true,
//         handleOnPaste: true,
//         onRequest: iframelyUrl => {
//             let url = iframelyUrl.replace('//iframe.ly/api/iframely?api_key=b4c4123adf581cf51504c4&url=', '')
//             url = url.replace('&iframe=1&rel=summary&omit_script=true&align=center&html5=1', '')
//             return {
//                 html: url,
//                 size: {
//                     height: 200,
//                     width: 350
//                 },
//                 alignment: 'center',
//                 title: ''
//             }
//         }
//     }
// })

const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
    handleUpload: () => {},
    addImage: imagePlugin.addImage,
})
export const plugins = [
    toolbarPlugin, 
    textAlignmentPlugin, 
    focusPlugin,
    resizeablePlugin,
    blockDndPlugin,
    alignmentPlugin,
    dragNDropFileUploadPlugin,
    linkPlugin, 
    imagePlugin,
    // iframelyPlugin,
]

export const emptyContentState = convertFromRaw({
    entityMap: {},
    blocks: [...Array(1)].map((v, i) => ({
        text: '',
        key: `foo${i}`,
        type: 'unstyled',
        entityRanges: [],
    })),
})

function findEntitiesByType(contentBlock, callback, type) {
    contentBlock.findEntityRanges(
        character => {
            const entityKey = character.getEntity()
            return (
                entityKey !== null
        && Entity.get(entityKey).getType() === type
            )
        },
        callback
    )
}

export const decorators = [
    { strategy: (c, cb) => findEntitiesByType(c, cb, 'LINK'), component: EditorLink },
]
