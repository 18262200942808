import { HANDLEERROR } from '../constants/ActionTypes'

const initialState = {
    catchError: false,
    errStatus: 0,
    errMessage: '',
}

export default function checkAuth(state = initialState, action = {}) {
    switch (action.type) {
    case HANDLEERROR.CLEAR:
        return { ...state, ...initialState }
    case HANDLEERROR.CATCH:
        return { ...state, catchError: true, ...action.payload }
    default:
        return state
    }
}
