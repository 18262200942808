import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Spin, Divider, Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import FormFooter from '../../../components/Util/FormFooter'
import { addEnterpriseUser } from '../../../apis/user'

export const initFormData = {
    name: undefined, 
    isAdmin: false, 
    email: undefined
}

function UserForm(props) {
    const [form] = Form.useForm()
    const { modalVisible, setModalVisible, handleFetchParams, enterpriseId } = props
    const { t: ln_t } = useTranslation()

    const [formData, setFormData] = useState(initFormData)
    const [fromLoading, setFormLoading] = useState(false)

    const msg = {
        name: ln_t('user.validation.name'),
        email: ln_t('user.validation.email'),
        role: ln_t('user.validation.role')
    }

    const onCancel = () => {
        setFormData(initFormData)
        form.setFieldsValue(initFormData)
        setModalVisible(false)
    }

    const onFinish = async f => {
        setFormLoading(true)
        const postData = { ...f }

        const result = await addEnterpriseUser(enterpriseId, postData)
        if (result?.status === 201) {
            setFormLoading(false)
            onCancel()
            handleFetchParams('init')
        }

        setFormLoading(false)
    }

    useEffect(() => { if (modalVisible) form.setFieldsValue(formData) }, [formData])

    return (
        <Modal 
            visible={modalVisible} 
            footer={false} 
            onCancel={onCancel}
            centered
        >
            <Spin spinning={fromLoading}>
                <Divider>{ln_t('settings.createUser')}</Divider>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={formData}
                    requiredMark={false}
                    layout="vertical"
                    validateTrigger="onSubmit"
                >
                    <Form.Item
                        label={ln_t('user.form.name')}
                        name="name" 
                        rules={[{ required: true, message: msg.name }]}
                    >
                        <Input placeholder={msg.name} />
                    </Form.Item>
                    <Form.Item
                        label={ln_t('user.form.email')}
                        name="email"
                        rules={[{ required: true, type: 'email', message: msg.email }]}
                    >
                        <Input placeholder={msg.email} inputMode="email" />
                    </Form.Item>
                    <Form.Item
                        label={ln_t('user.form.role')}
                        name="isAdmin" 
                        rules={[{ required: true, message: msg.role }]}
                    >
                        <Radio.Group buttonStyle="outline">
                            <Radio.Button value={false}>{ln_t('util.role.false')}</Radio.Button>
                            <Radio.Button value>{ln_t('util.role.true')}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <FormFooter
                        submitBtnTitle={ln_t('util.confirm')}
                        className="mt-2"
                        onCancel={onCancel}
                    />
                </Form>
            </Spin>
        </Modal>
    )
}

export default UserForm
