import { USERPROFILE } from '../constants/ActionTypes'

const initialState = {
    role: 'affiliate',
    lang: 'zn-TW'
}

export default function userProfile(state = initialState, action = {}) {
    const { lang } = state || {}
    switch (action.type) {
    case USERPROFILE.LOGOUT:
        return { ...initialState, lang, ...action.payload || {} }
    case USERPROFILE.PATCH:
        return { ...state, ...action.payload }
    default:
        return state
    }
}
