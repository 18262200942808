import React, { useEffect, useState } from 'react'
import { Form, DatePicker, Input } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import Selector from '../../../components/Util/Selector'

function SearchForm(props) {
    const [form] = Form.useForm()
    const { handleFetchParams, ln_t, className } = props
    const [searchType, setSearchType] = useState()

    const typeOptions = [
        { value: 'affiliateId', label: ln_t('affiliate.id') },
        { value: 'country', label: ln_t('traffic.country') },
    ]

    const onFinish = fieldValues => {
        const { createdAt } = fieldValues
        let n = {}
        if (createdAt) {
            const [dateForm, dateTo] = _.cloneDeep(createdAt)
            const format = time => moment.utc(time).format('YYYY-MM-DDT00:00:00Z')
            n = { 
                ...n, 
                createdAt_after: format(dateForm), 
                createdAt_before: format(dateTo)
            }
        }
        if (fieldValues[searchType]) {
            n = { ...n, [searchType]: fieldValues[searchType] }
        }
        handleFetchParams('init', n)
    }

    const handleTypeChange = value => {
        setSearchType(value)
        const n = Object.assign({}, ...typeOptions.map(opt => ({ [opt.value]: undefined }))) 
        form.setFieldsValue(n)
    }

    useEffect(() => { setSearchType(typeOptions[0].value) }, [])

    return (
        <Form 
            form={form}
            className={`d-flex flex-wrap round-selector align-items-start ${className}`}
            onFinish={onFinish}
        >
            <Selector 
                options={typeOptions} 
                value={searchType} 
                onChange={handleTypeChange} 
                className="mb-1 mr-1"
                showSearch={false}
                style={{ width: '110px' }}
            />
            <Form.Item noStyle name={searchType}>
                <Input placeholder={`${ln_t('util.search')}...`} allowClear className="mb-1 mr-2" style={{ width: '240px' }} />
            </Form.Item>
            <Form.Item label={ln_t('traffic.clickedAt')} name="createdAt" className="mb-1 mr-2">
                <DatePicker.RangePicker 
                    ranges={{
                        [ln_t('date.oneWeek')]: [moment().subtract(1, 'week'), moment()],
                        [ln_t('date.oneMonth')]: [moment().subtract(1, 'month'), moment()],
                        // '3 months': [moment().subtract(3, 'months'), moment()],
                        // '6 months': [moment().subtract(6, 'months'), moment()],
                        // '1 year': [moment().subtract(364, 'days'), moment()],
                    }} 
                />
            </Form.Item>
            <button className="main-button mb-2" type="submit">{ln_t('util.search')}</button>
        </Form>
    )
}

export default SearchForm
