import React, { useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { InfoCircleTwoTone, QuestionCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { pick } from 'lodash'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import { fetchEarningList } from '../../../apis/affiliateWeb'
import SearchForm from './SearchForm'

function EarningDetail(props) {
    const { ln_t, userInfo, download } = props
    const { nowEnterprise, roleId } = userInfo || {}
    const [isLoading, setIsLoading] = useState(false)

    const params = useParams({ sort: '-orderDate', maxResult: 8 })
    const { fetchParams } = params
    
    const statusInfo = ln_t('earning.earningDetail.statusInfo').split(';')
    const tooltipTitle = (
        <span>
            {statusInfo.map((item, index) => (index === statusInfo.length ? (
                <span key={index}>{item}</span>
            ) : (
                <span key={index}>{item} <br /> </span>
            )))}
        </span>
    )

    const statusTitle = (
        <Tooltip title={tooltipTitle} placement="bottom">
            <div style={{ display: 'flex' }}>
                <InfoCircleTwoTone twoToneColor="#07b3a1" />
                <div>{ln_t('earning.earningDetail.status')}</div>
            </div>
        </Tooltip>
    )

    const eachStatusInfo = {
        requested: ln_t('util.statusInfo.REQUESTED'),
        pending: ln_t('util.statusInfo.PENDING'),
        confirmed: ln_t('util.statusInfo.CONFIRMED'),
        paid: ln_t('util.statusInfo.PAID'),
        canceled: ln_t('util.statusInfo.CANCELED'),
    }

    const columns = [
        {
            title: ln_t('earning.earningDetail.orderDate'),
            dataIndex: 'orderDate',
            sorter: true,
        },
        {
            title: ln_t('earning.earningDetail.orderCategory'),
            dataIndex: 'product_id',
            sorter: true,
        },
        {
            title: ln_t('earning.earningDetail.orderDetail'),
            dataIndex: 'description',
            sorter: true,
        },
        {
            title: ln_t('earning.earningDetail.orderAmount'),
            dataIndex: 'amount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('earning.earningDetail.referralAmount'),
            dataIndex: 'referralAmount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('earning.earningDetail.expectToReceiveDate'),
            dataIndex: 'expectedToConfirm',
            sorter: true,
        },
        {
            title: () => statusTitle,
            dataIndex: 'status',
            sorter: true,
            render: e => (
                <Tooltip title={eachStatusInfo[e.toLowerCase()]} placement="right">
                    <span>{ln_t(`util.orderStatus.${e}`)}</span>
                </Tooltip>
            )
        },
    ]

    const apiFunc = async () => fetchEarningList(roleId, nowEnterprise?.enterprise, fetchParams)
    
    const handleDownload = async () => {
        setIsLoading(true)
        const result = await fetchEarningList(roleId, nowEnterprise?.enterprise, { ...fetchParams, maxResult: 9999 })
        if (result?.status === 200) {
            const x = result?.data?.results?.map(({ orderDate, ...item }) => ({
                ...item,
                orderDate: moment(orderDate).format('YYYY-MM-DD HH:mm:ss'),
            }))
            const neededCols = ['orderDate', 'product_id', 'description', 'amount', 'referralAmount', 'expectedToConfirm', 'status']
            const label = { product_id: 'orderCategory', description: 'orderDetail', amount: 'orderAmount', expectedToConfirm: 'expectToReceiveDate' }
            const colNames = Object.assign({}, ...neededCols.map(col => ({ [col]: ln_t(`earning.earningDetail.${label[col] || col}`) })))
            const n = x.map(item => pick(item, neededCols))
            download([[colNames, ...n]], ln_t('sidebarMenu.label.earning'), true)
        }
        setIsLoading(false)
    }

    return (
        <>
            <MainTable 
                {...props}
                className="earning-list"
                tableName="earning"
                columns={columns}
                rowKey="transactionId"
                apiFunc={apiFunc}
                params={params}
                useHeader
                headerRender={(
                    <>
                        <SearchForm 
                            {...props} 
                            {...params} 
                            dateConf={{
                                label: ln_t('order.orderDate'),
                                name: 'orderDate'
                            }}
                            statusOptions={[
                                { value: 'ALL', label: ln_t('util.status.all') },
                                { value: 'REQUESTED', label: ln_t('util.orderStatus.REQUESTED') },
                                { value: 'PENDING', label: ln_t('util.orderStatus.PENDING') },
                                { value: 'CONFIRMED', label: ln_t('util.orderStatus.CONFIRMED') },
                                { value: 'PAID', label: ln_t('util.orderStatus.PAID') },
                                { value: 'CANCELED', label: ln_t('util.orderStatus.CANCELED') },
                            ]}
                        />
                        <Button type="primary" style={{ marginBottom: 'auto' }} onClick={handleDownload} loading={isLoading}>
                            {ln_t('util.download')}
                        </Button>
                    </>
                )}
            />
            <div
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '16px' }}
                onClick={() => Modal.info({ title: ln_t('earning.commisionAmountInfo'), content: ln_t('earning.commisionAmountInfoDesc'), width: 550 })}
            >
                <QuestionCircleOutlined className="mr-2" />
                <span style={{ textDecoration: 'underline' }}>{ln_t('earning.commisionAmountInfo')}</span>
            </div>
        </>
    )
}

export default EarningDetail
