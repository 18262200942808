import React, { useEffect } from 'react'
import { EditorState, Modifier, RichUtils } from 'draft-js'
import { FontColorsOutlined } from '@ant-design/icons'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'

const Icon = styled.div`
    height: 25px;
    width: 22px;
    svg {
        fill: ${({ color }) => color}
    }
`

const PickerWrapper = styled.div`
    position: absolute;
    top: 40px;
    left: -85px;
    display: ${({ showPicker }) => (showPicker ? 'block' : 'none')};

    input {
        width: 100% !important;
    }
`

function ColorFontBtn(props) {
    const { editorState, setEditorState, fontColorList, addFontColor, 
        showPicker = false, setShowPicker = () => {}, color, setColor } = props

    const onClick = e => {
        setShowPicker(!showPicker)
    }
    const formatRgba = obj => `rgba(${Object.values(obj).join(',')})`

    const colorChange = colorConf => {
        const toggledColor = colorConf.rgb
        setColor(toggledColor)
        const selection = editorState.getSelection()
        const currentStyle = editorState.getCurrentInlineStyle()

        const nextContentState = fontColorList.reduce((contentState, color) => Modifier.removeInlineStyle(contentState, selection, color), editorState.getCurrentContent())

        let nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'change-inline-style'
        )

        const newColor = formatRgba(toggledColor)
        if (!currentStyle.has(newColor)) {
            addFontColor(newColor)

            nextEditorState = RichUtils.toggleInlineStyle(
                nextEditorState,
                newColor
            )
        }

        setEditorState(nextEditorState)
    }

    return (
        <div className="bi09khh"> 
            <button 
                type="button" 
                className="bc4rxid" 
                style={{ position: 'relative' }} 
                // onMouseDown={e => e.preventDefault()}
            >
                <Icon color={formatRgba(color)}>
                    <FontColorsOutlined onClick={e => onClick(e)} />
                </Icon>
                <PickerWrapper showPicker={showPicker}>
                    <SketchPicker color={color} onChange={c => colorChange(c)} />
                </PickerWrapper>
            </button>
        </div>
    )
}

export default ColorFontBtn
