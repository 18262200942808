import React, { useEffect } from 'react'
import { Input, Form, message } from 'antd'
import { modUser } from '../../../apis/user'
import { ENTERPRISE } from '../../../constants/Routing'
import FormFooter from '../../../components/Util/FormFooter'
import { USERPROFILE } from '../../../constants/ActionTypes'
import './UserProfile.less'

function ModName(props) {
    const { userInfo, location, dispatch, ln_t, history, setIsLoading } = props
    const [form] = Form.useForm()

    const { state } = location || {}
    const { name } = state || {}

    const msg = {
        name: ln_t('affiliate.placeholder.name'),
    }

    const onCancel = () => history.push(`/${ENTERPRISE}/user-profile`)

    const onFinish = async fieldsValue => {
        setIsLoading(true)

        const { name } = fieldsValue
        const { nowEnterprise, roleId } = userInfo || {}
        const { enterprise } = nowEnterprise || {}

        const result = await modUser(enterprise, roleId, { name })
        if (result?.status === 200) {
            dispatch({ type: USERPROFILE.PATCH, payload: { username: name } })
            message.info(ln_t('message.success.edit'))
            onCancel()
        }

        setIsLoading(false)
    }

    useEffect(() => {
        if (name) form.setFieldsValue({ name })
        else onCancel()
    }, [])

    return (
        <div className="profile-container">
            <div className="profile-inner">
                <Form
                    form={form}
                    onFinish={onFinish}
                    hideRequiredMark
                    validateTrigger="onSubmit"
                    labelAlign="left"
                >
                    <Form.Item label={ln_t('affiliate.info.username')} name="name" rules={[{ required: true, message: msg.name }]}>
                        <Input
                            placeholder={msg.name}
                            allowClear
                            autoComplete="off"
                            style={{ width: '300px' }}
                        />
                    </Form.Item>
                    <FormFooter
                        onCancel={onCancel}
                        placement="center"
                        className="mt-5 ml-4"
                        submitBtnTitle={ln_t('util.confirm')}
                    />
                </Form>
            </div>
        </div>
    )
}

export default ModName
