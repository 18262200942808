import React, { useEffect, useState } from 'react'
import { Button, Spin, message, Checkbox } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { ENTERPRISE, ROUTEHOME, ROUTELOGIN } from '../../constants/Routing'
import { fetchEnterpriseById, modUser } from '../../apis/user'
import { USERPROFILE } from '../../constants/ActionTypes'
import '../Login/Login.less'
import { fetchEnterpriseProducts } from '../../apis/product'

function ConfirmRules(props) {
    const { history, ln_t, langOptions, changeLang, dispatch, userInfo, location } = props

    const { nowEnterprise, roleId } = userInfo || {}
    const { enterprise } = nowEnterprise

    const { state } = location || {}
    const { checked } = state || {}

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [products, setProducts] = useState([])
    const [checkedRules, setCheckedRules] = useState({})
    const { isBankMultiple, minWithdraw, defaultUrl, enterpriseId, name } = data || {}

    const onFinish = async () => {
        setLoading(true)
        const result = await modUser(enterprise, roleId, { statusTC: 'accepted' })
        if (result?.status === 200) {
            dispatch({ type: USERPROFILE.PATCH, payload: { nowEnterprise: { ...nowEnterprise, isEnterpriseUserTC: 'accepted' } } })
            history.push(ROUTEHOME[ENTERPRISE])
        } else message.info(result?.data.message || ln_t('message.error.serverError'))

        setLoading(false)
    }

    const getEnterpriseData = async () => {
        setLoading(true)
        const result = await fetchEnterpriseById(enterprise)
        if (result?.status === 200) setData(result.data)

        const result2 = await fetchEnterpriseProducts(enterprise)
        if (result2?.status === 200) {
            const { results } = result2.data
            setProducts(results)
        }
        setLoading(false)
    }

    const handleCheck = (key, e) => {
        const n = { ...checkedRules }
        n[key] = e?.target?.checked
        setCheckedRules(n)
        history.replace({ state: { checked: n } })
    }

    useEffect(() => {
        if (!enterprise || !roleId) return history.push(ROUTELOGIN[ENTERPRISE])
        if (checked) setCheckedRules(checked)
        getEnterpriseData()
    }, [])

    return (
        <main className="login-container">
            <section className="login-inner rules">
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <div className="logo-box">
                    <img className="adx-logo" src="/lndata_logo.png" alt="Lndata Logo" />
                </div>
                <div className="title-text">{ln_t('login.enterprise')}</div>
                <Spin spinning={loading}>
                    <div className="d-flex fd-col">
                        <Checkbox onChange={e => handleCheck('rule1', e)} checked={checkedRules.rule1}>
                            {ln_t('enterpriseTC.everyAffiliateCanHave')}
                            <span style={{ fontWeight: '600' }}>{ln_t(`enterpriseTC.isBankMultiple.${isBankMultiple}`)}</span>
                            {ln_t('enterpriseTC.bankAccount')}
                        </Checkbox>
                        <Checkbox onChange={e => handleCheck('rule2', e)} checked={checkedRules.rule2}>
                            {ln_t('enterpriseTC.minWithdrawAmountIs')} ${minWithdraw?.toLocaleString()} NTD
                        </Checkbox>
                        <Checkbox onChange={e => handleCheck('rule3', e)} checked={checkedRules.rule3}>
                            {ln_t('enterpriseTC.defaultUrlIs')}：{defaultUrl}
                        </Checkbox>
                        <Checkbox onChange={e => handleCheck('rule4', e)} checked={checkedRules.rule4}>
                            {ln_t('enterpriseTC.productDefaultReferralRate')}：
                        </Checkbox>
                        <section className="products">
                            {products.map(item => (
                                <div className="product-item" key={item.product}>
                                    <label>{item.product}</label>
                                    <span>{item.referralRate}%</span>
                                </div>
                            ))}
                        </section>
                        <Checkbox onChange={e => handleCheck('rule5', e)} checked={checkedRules.rule5}>
                            {ln_t('enterpriseTC.pleaseConfirm')}
                            <Link to={`/enterprise/detailRules/${name}`}>
                                {ln_t('enterpriseTC.userRules')}
                            </Link>
                            {ln_t('util.and')}
                            <Link to={`/enterprise/detailRules/${name}`}>
                                {ln_t('enterpriseTC.termsAndCondition')}
                            </Link>
                        </Checkbox>
                    </div>
                    <div className="text-center mt-5">
                        <Button 
                            type="button" 
                            onClick={() => onFinish()}
                            disabled={Object.values(checkedRules)?.filter(check => check)?.length !== 5}
                        >
                            {ln_t('util.confirm')}
                        </Button>
                    </div>
                </Spin>
            </section>
        </main>
    )
}

export default withRouter(ConfirmRules)
