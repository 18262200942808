import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const modAffiliatePassword = async (affiliateId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/change_password/`, MethodType.POST, params)

export const fetchSummary = async (affiliateId, enterpriseId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/earning/summary/`, MethodType.GET, params)

export const fetchAffiliateUrlList = async (affiliateId, enterpriseId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/url/`, MethodType.GET, params)

export const createUrl = async (affiliateId, enterpriseId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/url/`, MethodType.POST, params)

export const fetchAffiliate = async (affiliateId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/`, MethodType.GET, params)

export const fetchAffiliateTax = async (affiliateId, enterpriseId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/affent/`, MethodType.GET, params)

export const modAffiliate = async (affiliateId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/`, MethodType.PATCH, params)

export const modAffiliateTax = async (affiliateId, enterpriseId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/affent/`, MethodType.PATCH, params)

export const fetchEarningList = async (affiliateId, enterpriseId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/earning/`, MethodType.GET, params)

export const fetchWithdrawList = async (affiliateId, enterpriseId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/withdraw/`, MethodType.GET, params)

export const fetchBankList = async params => api.request(1, '/api/v3/bank/?maxResult=1000', MethodType.GET, params)

export const fetchAffiliateBankListByEnterprise = async (affiliateId, enterpriseId, params) => (
    api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/bank/`, MethodType.GET, params)
)

export const addAffiliateBank = async (affiliateId, enterpriseId, params) => (
    api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/bank/`, MethodType.POST, params)
)

export const fetchBankById = async (affiliateId, enterpriseId, bankId, params) => (
    api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/bank/${bankId}/`, MethodType.GET, params)
)

export const modBankById = async (affiliateId, enterpriseId, bankId, params) => (
    api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/bank/${bankId}/`, MethodType.PATCH, params)
)

export const requestWithdraw = async (affiliateId, enterpriseId, params) => (
    api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/bank/request_withdraw/`, MethodType.POST, params)
)

export const fetchCampaignPromo = async (affiliateId, enterpriseId, params) => (
    api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/campaignPromo/?type=active`, MethodType.GET, params)
)

export const fetchActiveEvent = async (affiliateId, enterpriseId, params) => ({
    keys: ['defaultRate', 'nowRate'],
    response: (
        await api.request(
            1, 
            [`/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/campaignPromo/?type=active`, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/rateNow/`], 
            MethodType.GET, 
            params
        )
    )
})
