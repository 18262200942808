import React, { useEffect, useState, useCallback } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { fetchAffiliateProduct, fetchProductSummary } from '../../../apis/stats'
import { fetchEnterpriseProducts } from '../../../apis/product'

function AffiliateProduct(props) {
    const { userInfo, ln_t, className, width } = props
    const { nowEnterprise, lang } = userInfo || {}
    const { enterprise } = nowEnterprise || {}
    const [data, setData] = useState({})
    const [graphHeight, setGraphHeight] = useState(0)
    const [fullData, setFullData] = useState({})
    const [labelOpts, setLabelOpts] = useState([])

    const { total = 0, difference: diff = 0 } = fullData || {}

    const emptyOpts = {
        cutoutPercentage: 75,
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        }
    }

    const graphOpts = {
        cutoutPercentage: 75,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                title(tooltipItem, data) {
                    const { data: datas, total } = data?.datasets?.[0] || {}
                    const index = tooltipItem?.[0]?.index
                    const val = datas?.[index]
                    // console.log(val, total, datas)
                    return `${data.labels?.[index]} (${String(((val / total) * 100).toFixed(1)).replace('.0', '')}%)`
                },
                label(tooltipItem, data) {
                    const datas = data?.datasets?.[0]?.data
                    const index = tooltipItem?.index
                    return `${datas?.[index]?.toLocaleString()} ${ln_t('dashboard.affiliateCount')}`
                },
            },
            titleFontSize: 16,
            bodyFontSize: 16,
            padding: 12,
            displayColors: false,
        }
    }
    
    const graphRef = useCallback(node => {
        if (!node) return
        const h = node.offsetWidth
        setGraphHeight(h)
    }, [width, data])

    const getDatas = async () => {
        const result = await fetchAffiliateProduct(enterprise)
        if (result?.status === 200) {
            const results = result.data?.[0] || {}
            const { details = [], total: totalAff } = results
            const lOpts = details.map((item, index, arr) => {
                const { product } = item
                return {
                    label: product ? `${ln_t('dashboard.product.include')}${product}` : ln_t('dashboard.product.noAction'),
                    color: product ? `rgba(7, 179, 161, ${(1 / (arr.length)) * (index + 1)})` : 'rgba(0, 0, 0, .1)'
                }
            })
            setLabelOpts(lOpts)
            const labels = lOpts.map(item => item.label)

            const n = {
                labels,
                datasets: [
                    {
                        total: totalAff,
                        data: details.map(item => item.count),
                        backgroundColor: lOpts.map(item => item.color)
                    }
                ]
            }
            setData(n)
            setFullData(results)
        }
    }

    useEffect(() => {
        getDatas()
    }, [lang])

    return (
        <section className={className}>
            <h4>{ln_t('dashboard.affiliateVsProductCat')}</h4>
            <div className="doughnut doughnut-100 my-4" ref={graphRef}>
                <Doughnut key={`affiliateProduct_${graphHeight}`} data={data} options={graphOpts} responsive height={graphHeight} width={graphHeight} />
                {total 
                    ? '' 
                    : (
                        <div style={{ position: 'absolute', top: 0, left: 0 }} className="w-100 h-100">
                            <Doughnut 
                                data={{
                                    datasets: [
                                        {
                                            data: [100],
                                            backgroundColor: ['rgba(0, 0, 0, .1)']
                                        }
                                    ]
                                }} 
                                options={emptyOpts} 
                                responsive 
                                height={graphHeight} 
                                width={graphHeight}
                            />
                        </div>
                    )}
                <div 
                    className="doughnut-center" 
                    style={{ width: `${graphHeight * 0.75}px`, height: `${graphHeight * 0.75}px` }}
                >
                    <h3 className="mb-1">
                        {(total / 1000) >= 1 ? `${(total / 1000).toFixed(1).toLocaleString().replace('.0', '')}K` : total}
                        <span style={{ fontSize: '16px' }} className="ml-1">{ln_t('dashboard.affiliateCount')}</span>
                    </h3>
                    <h6 className="mb-1 d-flex align-items-center">
                        {diff ? (Math.abs(diff) / 1000) >= 1 ? `${(Math.abs(diff) / 1000).toFixed(1).toLocaleString().replace('.0', '')}K` : Math.abs(diff) : ''}
                        {diff > 0 
                            ? <ArrowUpOutlined className="icon up" /> 
                            : diff 
                                ? <ArrowDownOutlined className="icon down" /> 
                                : <span className="icon">-</span>}
                    </h6>
                    <p>{ln_t('dashboard.fromLastMonth')}</p>
                </div>
            </div>
            <div 
                className="d-flex align-items-center flex-wrap mt-5" 
                style={{ gap: '1rem 0' }}
            >
                {labelOpts?.map((opt, index) => (
                    <div className="d-flex align-items-center mr-4" key={`affiliate-product-label-${index}`}>
                        <div className="label-color" style={{ background: opt.color }} />
                        <h6 className="m-0">{opt.label}</h6>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default AffiliateProduct
