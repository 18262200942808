import React, { useEffect, useState } from 'react'
import { Empty, message, Select } from 'antd'
import moment from 'moment'
import './News.less'
import { fetchNotif, modifyNotif } from '../../../apis/news'

function News(props) {
    const { ln_t, history, userInfo, modalConf } = props
    const { nowEnterprise, roleId } = userInfo || {}
    const [news, setNews] = useState()
    const [mode, setMode] = useState('active')
    const apiFetch = async (affiliateId, enterpriseId, params, id) => fetchNotif(affiliateId, enterpriseId, params, id)
    const apiModify = async (affiliateId, enterpriseId, params, id) => modifyNotif(affiliateId, enterpriseId, params, id)
    
    const getParams = mode => {
        const today = moment().format('YYYY-MM-DD')
        const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD')
        if (mode === 'active') return { campaignStart_before: today, campaignEnd_after: today }
        return { campaignEnd_before: yesterday }
    }

    const getNews = async (params, id) => {
        const newParams = { ...params, sort: '-news__isPinned,-news__updatedTime' }
        const result = await apiFetch(roleId, nowEnterprise?.enterprise, newParams, id)
        if (result?.status === 200) {
            setNews(result.data.results)
        }
    }

    const handleFilter = e => {
        getNews(getParams(e))
        setMode(e)
    }

    const onViewContent = async item => {
        const result = await apiFetch(roleId, nowEnterprise?.enterprise, {}, item?.id)
        if (result?.status === 200) {
            history.push({
                pathname: '/affiliate/news/content',
                state: { data: result?.data }
            })
        }
    }

    const handleViewContent = async item => {
        if (item.isRead) {
            onViewContent(item)
            return
        }

        const reqBody = { isRead: true }
        const update = await apiModify(roleId, nowEnterprise?.enterprise, reqBody, item.id)
        if (update?.status === 200) {
            onViewContent(item)
        }
    }

    useEffect(() => {
        getNews(getParams(mode))
    }, [])

    const formatDate = date => moment(date).format('YYYY-MM-DD')

    return (
        <div style={{ height: '85vh' }}>
            <div className="affiliate-news-header">
                <div className="title">{ln_t(`news.${mode}`)} {ln_t('sidebarMenu.label.news')}</div>
                <Select value={mode} style={{ width: 120 }} onChange={e => handleFilter(e)}>
                    <Select.Option key="active" value="active">{ln_t('news.active')}</Select.Option>
                    <Select.Option key="expired" value="expired">{ln_t('news.expired')}</Select.Option>
                </Select>
            </div>
            <div className="affiliate-news">
                {news?.map(item => (
                    <div className={`aff-news-wrapper ${item.isRead ? '' : 'aff-news-unread'}`} key={item.id} onClick={() => handleViewContent(item)}>
                        <div className="news-header">
                            <div className="title">{item.news.title}</div>
                        </div>
                        <div className="desc">{item.news.descriptions}</div>
                        <div className="news-footer">
                            <div className="date">
                                {formatDate(item.news.campaignStart)} - {formatDate(item.news.campaignEnd)}
                            </div>
                        </div>
                    </div>
                ))}
                {news?.length === 0 ? <Empty style={{ padding: '50px 0px' }} /> : ''}
            </div>
        </div>
    )
}

export default News
