import React from 'react'
import InfoCard from './InfoCard'

function Container(props) {
    const { children } = props
    
    return (
        <div className="affiliate-wrapper">
            <InfoCard {...props} />
            <section className="affiliate-main-inner">
                {children}
            </section>
        </div>
    )
}

export default Container
