import React, { useEffect, useState } from 'react'
import { Button, } from 'antd'
import { withRouter } from 'react-router-dom'
import '../../Login/Login.less'
import Profile from '../Profile'

function TaxInformation(props) {
    const { location, history, ln_t, langOptions, changeLang } = props
    const { pathname, state } = location || {}

    const replacePath = string => pathname.replace(/taxInformation/, string)
    const previous = () => history.push({ pathname: replacePath('accountType'), state })
    const goToTerm = () => history.push({ pathname: replacePath('terms'), state })

    const loginButtons = (
        <>
            <div className="d-flex justify-content-end">
                <span className="link primary" onClick={goToTerm}>{ln_t('util.skip')}</span>
            </div>
            <div className="login-btn-container">
                <Button type="button" onClick={previous} style={{ marginRight: '8px' }}>{ln_t('util.previous')}</Button>
                <Button type="button" htmlType="submit">{ln_t('util.confirm')}</Button>
            </div>
        </>
    )

    const passProps = {
        ...props,
        loginButtons,
        goToTerm
    }

    return (
        <main className="login-container">
            <section className="login-inner medium">
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <Profile {...passProps} login />
            </section>
        </main>
    )
}

export default withRouter(TaxInformation)
