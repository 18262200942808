import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import userProfile from './userProfile'
import checkAuth from './checkAuth'
import handleError from './handleError'

const rootReducer = history => combineReducers({
    router: connectRouter(history),
    userProfile,
    checkAuth,
    handleError,
})

export default rootReducer
