import React, { useEffect, useState, useCallback } from 'react'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { fetchAffiliateTrending } from '../../../apis/stats'
import Switcher from '../../../components/Util/Switcher'

function AffiliateTrend(props) {
    const { userInfo, ln_t, className, width } = props
    const { nowEnterprise, lang } = userInfo || {}
    const [data, setData] = useState({})
    const [graphHeight, setGraphHeight] = useState(0)
    const [page, setPage] = useState()
    const [lastPage, setLastPage] = useState()
    const [fullData, setFullData] = useState()

    const labelOpts = [
        {
            className: 'line-chart', 
            label: ln_t('dashboard.monthlyNewAffiliates'),
            color: 'rgba(7, 179, 161, 1)',
        },
        {
            className: 'bar-chart', 
            label: ln_t('dashboard.totalAffiliates'),
            color: 'rgba(7, 179, 161, .7)',
        }
    ]

    const graphOpts = {
        legend: {
            display: false
        },
        tooltips: {
            titleFontSize: 16,
            bodyFontSize: 16,
            padding: 12,
            displayColors: false,
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: ln_t('dashboard.month'),
                    fontSize: 18,
                }
            }],
            yAxes: [
                {
                    id: 'y1',
                    type: 'linear',
                    position: 'left',
                    ticks: {
                        beginAtZero: true,
                        userCallback(value) {
                            if (Math.floor(value) === value) {
                                return value.toLocaleString()
                            }
                        }
                    },
                    scaleLabel: {
                        display: true,
                        labelString: ln_t('dashboard.monthlyNewAffiliates'),
                        fontSize: 18,
                    }
                },
                {
                    id: 'y2',
                    type: 'linear',
                    position: 'right',
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        beginAtZero: true,
                        userCallback(value) {
                            if (Math.floor(value) === value) {
                                return value.toLocaleString()
                            }
                        }
                    },
                    scaleLabel: {
                        display: true,
                        labelString: ln_t('dashboard.totalAffiliates'),
                        fontSize: 18,
                    }
                },
            ]
        }
    }    

    const graphRef = useCallback(node => {
        if (!node) return
        const h = node.offsetWidth * 0.8
        setGraphHeight(h)
    }, [width, data])

    const handlePage = newPage => {
        const { labels: fullLabels, datasets } = fullData
        const startIndex = (fullLabels.length - ((lastPage - newPage + 1) * 7)) < 0 ? 0 : (fullLabels.length - ((lastPage - newPage + 1) * 7))
        const endIndex = fullLabels.length - ((lastPage - newPage) * 7)

        if (newPage < 1 || endIndex > fullLabels.length) return

        const d = {
            labels: fullLabels.slice(startIndex, endIndex), 
            datasets: datasets.map(item => ({
                ...item,
                data: item.data.slice(startIndex, endIndex)
            }))
        }
        setData(d)
        setPage(newPage)
    }

    const getDatas = async () => {
        const result = await fetchAffiliateTrending(nowEnterprise?.enterprise)
        if (result?.status === 200) {
            const x = result.data.sort((a, b) => a.yearMonth - b.yearMonth)

            const months = []
            if (x?.length >= 2) {
                for (let i = moment(String(x[0].yearMonth)); i <= moment(String(x[x.length - 1].yearMonth)); i.add(1, 'month')) {
                    months.push(Number(i.format('YYYYMM')))
                }
            } else if (x?.length === 1) months.push(x[0].yearMonth)

            const mapKey = key => months.map(m => {
                const match = x.find(res => m === res.yearMonth)
                return match ? match[key] : 0
            })

            const n = {
                labels: months.map(m => moment(String(m)).format('YYYY-MM')),
                datasets: [
                    {
                        type: 'line',
                        label: ln_t('dashboard.newAffiliate'),
                        borderColor: 'rgba(7, 179, 161, 1)',
                        borderWidth: 2,
                        pointBackgroundColor: 'rgba(255, 255, 255, .5)',
                        fill: false,
                        data: mapKey('newAffiliate'),
                        yAxisID: 'y1',
                    },
                    {
                        type: 'bar',
                        label: ln_t('dashboard.totalAffiliate'),
                        backgroundColor: 'rgba(7, 179, 161, .7)',
                        data: mapKey('totalAffiliate'),
                        borderColor: 'white',
                        barThickness: 40,
                        yAxisID: 'y2',
                    },
                ]
            }

            const last = Math.ceil(months.length / 7)
            setLastPage(last)
            setPage(last)
            setFullData(n)
        }
    }

    useEffect(() => {
        if (!fullData || !page) return
        handlePage(page)
    }, [fullData])

    useEffect(() => {
        getDatas()
    }, [lang])

    return (
        <section className={className}>
            <div className="single-bar" ref={graphRef}>
                <h4 className="mb-4">{ln_t('dashboard.mothlyAffiliateTrend')}</h4>
                <Switcher 
                    className="mb-3 mr-1" 
                    handleLeft={() => handlePage(page - 1)} 
                    handleRight={() => handlePage(page + 1)}
                    disabled={[page <= 1, page >= lastPage]}
                />
                <Bar 
                    key={`affiliate-trend-${graphHeight}`} 
                    data={data} 
                    options={graphOpts} 
                    height={graphHeight / (width > 1400 ? 2.3 : 1.9)} 
                    width={graphHeight}
                />
                <div 
                    className="d-flex align-items-center justify-content-center flex-wrap mt-2" 
                >
                    {labelOpts.map((opt, index) => (
                        <div className="d-flex align-items-center mr-4" key={`affiliate-product-label-${index}`}>
                            <div className={opt.className} style={{ backgroundColor: opt.color }} />
                            <h6 className="m-0">{opt.label}</h6>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default AffiliateTrend
