import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Modal, Form, Input, Spin, Divider, DatePicker, message } from 'antd'
import FormFooter from '../../../components/Util/FormFooter'
import Selector from '../../../components/Util/Selector'
import { fetchBankList } from '../../../apis/affiliateWeb'
import { modVerification } from '../../../apis/bankVerify'
import { modEnterpriseAffiliate } from '../../../apis/affiliate'

function BusinessVerifyForm(props) {
    const [form] = Form.useForm()
    const { modalVisible, setModalVisible, userInfo, ln_t, handleFetchParams, editDatas, setEditDatas } = props

    const { nowEnterprise } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const [fromLoading, setFormLoading] = useState(false)
    const [bankList, setBankList] = useState([])

    const onCancel = () => {
        form.resetFields()
        setEditDatas()
        setModalVisible(false)
    }

    const onFinish = async fieldValues => {
        setFormLoading(true)
        const n = { 
            affiliate: editDatas.affiliate,
            id: editDatas.id,
            ...fieldValues
        }
        const result = await modVerification(enterprise, n)
        const result2 = await modEnterpriseAffiliate(enterprise, editDatas?.affiliate, n)
        if (result?.status === 200 && result2?.status === 200) {
            message.info(ln_t('message.success.edit'))
            handleFetchParams('patch')
            onCancel()
        }

        setFormLoading(false)
    }

    const msg = {
        fullName: ln_t('affiliate.placeholder.fullName'),
        phone: ln_t('affiliate.placeholder.phone'),
        companyName: ln_t('tax.placeholder.companyName'),
        taxID: ln_t('tax.placeholder.taxID'),
        companyPhoneNumber: ln_t('tax.placeholder.companyPhoneNumber'),
        companyAddress: ln_t('tax.placeholder.companyAddress'),
        companyWebsite: ln_t('tax.placeholder.companyWebsite'),
        contactName: ln_t('tax.placeholder.fullName'),
        contactPhoneNumber: ln_t('tax.placeholder.phone'),
        contactEmail: ln_t('tax.placeholder.email'),
        pattern: ln_t('affiliate.validation.matchFormat'),
        bankName: ln_t('bank.placeholder.bankName'),
        bankAccountName: ln_t('bank.placeholder.bankAccountName'),
        bankAccountNumber: ln_t('bank.placeholder.bankAccountNumber'),
        branchCode: ln_t('bank.placeholder.branchCode')
    }

    const getBankList = async () => {
        setFormLoading(true)
        const { personalInformation } = editDatas
        form.setFieldsValue({ ...editDatas, ...personalInformation })
        const result = await fetchBankList()
        if (result?.status === 200) setBankList(result.data.results.map(res => ({ label: res.name, value: res.code })))
        setFormLoading(false)
    }

    useEffect(() => {
        if (!modalVisible || !editDatas) return
        getBankList()
        console.log(editDatas)
    }, [modalVisible])

    return (
        <Modal 
            visible={modalVisible} 
            footer={false} 
            onCancel={onCancel}
            centered
        >
            <Spin spinning={fromLoading}>
                <Divider>{ln_t('verify.modifyInformation')}</Divider>
                <Form
                    form={form}
                    onFinish={onFinish}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Form.Item label={ln_t('bank.bankName')} name="bank" rules={[{ required: true, message: msg.bankName }]}>
                        <Selector options={bankList} placeholder={msg.bankName} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('bank.longBankAccountName')} 
                        name="bankAccountName" 
                        rules={[
                            { required: true, message: msg.bankAccountName },
                            { min: 2, message: ln_t('bank.placeholder.bankAccountNameLength') },
                        ]}
                    >
                        <Input allowClear autoComplete="off" placeholder={msg.bankAccountName} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('bank.bankAccountNumber')} 
                        name="bankAccount" 
                        rules={[
                            { required: true, message: msg.bankAccountNumber },
                            { pattern: /^[0-9]{12,16}$/, message: msg.pattern }
                        ]}
                    >
                        <Input allowClear autoComplete="off" placeholder={msg.bankAccountNumber} inputMode="numeric" />
                    </Form.Item>
                    <Form.Item label={ln_t('bank.bankBranchCode')} name="bankBranchCode" rules={[{ required: true, message: msg.branchCode }]}>
                        <Input allowClear autoComplete="off" placeholder={msg.branchCode} inputMode="numeric" />
                    </Form.Item>
                    <Form.Item label={ln_t('affiliate.companyName')} name="companyName" rules={[{ required: true, message: msg.companyName }]}>
                        <Input allowClear autoComplete="off" placeholder={msg.companyName} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('affiliate.taxID')} 
                        name="taxID" 
                        rules={[{ pattern: /^[0-9]{8,8}$/, message: msg.pattern }, { required: true, message: msg.taxID }]}
                    >
                        <Input allowClear autoComplete="off" placeholder={msg.taxID} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('affiliate.companyPhone')} 
                        name="companyPhoneNumber" 
                        rules={[{ pattern: /^09[0-9]{8,8}$/, message: msg.pattern }, { required: true, message: msg.companyPhoneNumber }]}
                    >
                        <Input allowClear autoComplete="off" placeholder={msg.phone} inputMode="tel" />
                    </Form.Item>
                    <Form.Item label={ln_t('affiliate.companyAddress')} name="companyAddress" rules={[{ required: true, message: msg.companyAddress }]}>
                        <Input allowClear autoComplete="off" placeholder={msg.companyAddress} />
                    </Form.Item>
                    <Form.Item label={ln_t('affiliate.companyWebsite')} name="companyWebsite" rules={[{ required: false }]}>
                        <Input allowClear autoComplete="off" placeholder={msg.companyWebsite} />
                    </Form.Item>
                    <Form.Item label={ln_t('affiliate.contactName')} name="fullName" rules={[{ required: true, message: msg.contactName }]}>
                        <Input allowClear autoComplete="off" placeholder={msg.contactName} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('affiliate.info.phone')} 
                        name="phone" 
                        rules={[{ pattern: /^09[0-9]{8,8}$/, message: msg.pattern }, { required: true, message: msg.contactPhoneNumber }]}
                    >
                        <Input allowClear autoComplete="off" placeholder={msg.phone} inputMode="tel" />
                    </Form.Item>

                    <FormFooter
                        submitBtnTitle={ln_t('util.confirm')}
                        className="mt-2"
                        onCancel={onCancel}
                    />
                </Form>
            </Spin>
        </Modal>
    )
}

export default BusinessVerifyForm
