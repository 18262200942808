import React, { useState, useEffect, useMemo } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { Spin, message } from 'antd'
import Header from './Header'
import Sidebar from './Sidebar'
import BreadCrumb from './BreadCrumb'
import AffiliateInner from '../Affiliate/Container'
import { CHECKAUTH, HANDLEERROR } from '../../constants/ActionTypes'
import { ENTERPRISE, ROUTEBASE, AFFILIATE } from '../../constants/Routing'
import LogoFloat from '../Util/LogoFloat'
import Footer from './Footer'
import './AppContainer.less'
import ActiveEvent from '../Affiliate/ActiveEvent'

const initDropDown = { user: false, lang: false }
function AppContainer(props) {
    const { dispatch, err, ln_t, userInfo, isLoading, location } = props
    const { role, isEmailVerified, nowEnterprise } = userInfo || {}
    const { isAffiliateTC } = nowEnterprise || {}
    const { pathname: path } = location || {}
    const isEnterprise = path.startsWith(ROUTEBASE[ENTERPRISE])
    const isAffiliate = path.startsWith(ROUTEBASE[AFFILIATE])

    const [dropDownVisible, setDropDownVisible] = useState(initDropDown)
    const [toggle, setToggle] = useState(true)

    const rolePermission = useMemo(() => (
        (isEnterprise && role === ENTERPRISE) 
        || (isAffiliate && role === AFFILIATE && isEmailVerified === 'verified' && isAffiliateTC === 'accepted')
    ), [path, role])

    const resetDropDown = () => {
        setDropDownVisible({ ...initDropDown })
    }

    const toggleDropDown = key => {
        setDropDownVisible({ ...initDropDown, [key]: !dropDownVisible[key] })
    }

    useEffect(() => {
        dispatch({ type: CHECKAUTH.REQUEST })
        if (!isEnterprise) setToggle(false)
    }, [path])

    useEffect(() => {
        if (err.catchError) {
            message.info(ln_t(err.errMessage))
            dispatch({ type: HANDLEERROR.CLEAR })
        }
    }, [err])
    
    return (
        <Spin spinning={isLoading} style={{ transform: 'translateY(40%)' }}>
            <main className={`app-wrapper ${isAffiliate ? 'affiliate-theme' : ''}`}>
                {rolePermission && Object.keys(userInfo)?.length > 3
                    ? (
                        <>
                            <Header 
                                {...props}
                                dropDownVisible={dropDownVisible}
                                toggle={toggle} 
                                setToggle={setToggle}
                                toggleDropDown={toggleDropDown}
                                resetDropDown={resetDropDown}
                            />
                            <section 
                                className={`app-container ${(!toggle || !isEnterprise) ? 'app-container-close' : ''} ${!isEnterprise ? '' : 'brand'}`} 
                                onClick={() => resetDropDown()}
                            >
                                <div className={`app-container-inner ${isEnterprise ? 'brand' : ''}`}>
                                    <div>
                                        {isEnterprise 
                                            ? (
                                                <>
                                                    <BreadCrumb {...props} /> 
                                                    <Route {...props} />
                                                </>
                                            )
                                            : (
                                                <AffiliateInner {...props}>
                                                    <Route {...props} />
                                                </AffiliateInner>
                                            )}
                                    </div>
                                    {isEnterprise ? <Footer /> : ''}
                                </div>
                            </section>
                            {isEnterprise 
                                ? (
                                    <section 
                                        className={`sidebar-container ${toggle ? 'active' : ''}`} 
                                        onClick={() => resetDropDown()}
                                    >
                                        <Sidebar {...props} toggle={toggle} setToggle={setToggle} ln_t={ln_t} />
                                    </section>
                                ) : ''}
                        </>
                    ) 
                    : (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate3d(-50%,-50%,0)' }}>
                            <LogoFloat />
                        </div>
                    )}
            </main>
        </Spin>
    )   
}

export default withRouter(AppContainer)
