import React from 'react'
import { Popconfirm, Switch } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

function StatusSwitchConfirm(props) {
    const { t: ln_t } = useTranslation()
    const { onConfirm = () => {}, checked, name = '', size = 'small', type = 'status', width = '48px' } = props

    const popupConf = {
        okText: ln_t('util.confirm'),
        cancelText: ln_t('util.cancel')
    }    

    return (
        <Popconfirm
            title={type === 'status' ? `${checked ? ln_t('util.disable') : ln_t('util.enable')} ${name} ?` : `${ln_t('util.change')} ${ln_t(`util.${type}.${type}`)}`}
            onConfirm={() => onConfirm()}
            icon={<EditOutlined style={{ color: 'black' }} />}
            {...popupConf}
        >
            <Switch
                checkedChildren={ln_t(`util.${type}.true`)}
                style={{ width }}
                unCheckedChildren={ln_t(`util.${type}.false`)}
                size={size}
                checked={checked}
                data-testid="statusBtn"
            />
        </Popconfirm>
    )
}

export default StatusSwitchConfirm
