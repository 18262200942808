import { DollarCircleOutlined, DownOutlined, MoneyCollectOutlined, SettingOutlined, StarOutlined, SwapOutlined, UsergroupAddOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { ENTERPRISE, ROUTEHOME } from '../../constants/Routing'
import './Sidebar.less'

const menu = [
    { key: 'dashboard', link: '/dashboard', icon: <StarOutlined className="mr-2" /> },
    { key: 'affiliate', link: '/affiliate', icon: <UsergroupAddOutlined className="mr-2" /> },
    { key: 'order', link: '/order', icon: <WalletOutlined className="mr-2" /> },
    { key: 'traffic', link: '/traffic', icon: <SwapOutlined className="mr-2" /> },
    { key: 'payment', link: '/payment', icon: <DollarCircleOutlined className="mr-2" /> },
    { key: 'idVerify', link: '/idVerification', icon: <UserOutlined className="mr-2" /> },
]

const adminMenu = [
    { key: 'setting',
        link: '/setting',
        icon: <SettingOutlined className="mr-2" />,
        children: [
            { key: 'user', link: '/setting/user', icon: <StarOutlined className="mr-2" /> },
            { key: 'product', link: '/setting/product', icon: <UsergroupAddOutlined className="mr-2" /> },
            { key: 'campaign', link: '/setting/campaign', icon: <StarOutlined className="mr-2" /> },
            { key: 'news', link: '/setting/news', icon: <StarOutlined className="mr-2" /> },
        ] },
]

function Sidebar(props) {
    const { userInfo, location, history, ln_t } = props
    const { pathname } = location || {}
    const { isAdmin } = userInfo || {}

    const [subActive, setSubActive] = useState(true)

    const style = (value, sub) => {
        if (sub) return pathname.includes(value) ? 'active' : ''
        return pathname.includes(value) ? 'sidebar-item-active' : 'sidebar-item'
    }

    return (
        <aside className="sidebar-inner">
            <section className="sidebar-logo" onClick={() => history.push(`${ROUTEHOME.enterprise}`)}>
                <img
                    src="/lndata_logo.png"
                    alt="Lndata Logo"
                    className="cursor-pointer" 
                />
            </section>
            <section className="sidebar-menu">
                {(isAdmin ? [...menu, ...adminMenu] : menu)?.map(item => (
                    !item.children?.length
                        ? (
                            <Link key={item.key} className={style(item.link)} to={`/${ENTERPRISE}${item.link}`}>
                                {item.icon}{ln_t(`sidebarMenu.label.${item.key}`)}
                            </Link>
                        )
                        : (
                            <div className="has-sub" key={item.key}>
                                <div className="sidebar-item" onClick={() => setSubActive(!subActive)}>
                                    <span>{item.icon}{ln_t(`sidebarMenu.label.${item.key}`)}</span>
                                    <DownOutlined className={`sub-toggle ${subActive ? 'active ml-3' : 'ml-3'}`} />
                                </div>
                                <ul className={`sub-container ${subActive ? 'active' : ''}`}>
                                    {item.children.map(sub => (
                                        <li key={sub.key} className="sub-item">
                                            <Link className={style(sub.link, 'sub')} to={`/${ENTERPRISE}${sub.link}`}>
                                                {ln_t(`sidebarMenu.label.${sub.key}`)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                ))}
            </section> 
        </aside>
    )
} 

export default withRouter(Sidebar)
