import React, { useMemo, useEffect, useRef } from 'react'
import { Button, Input, Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import TableFooter from './TableFooter'
import './Table.less'

function MainTable(props) {
    const { className, rowKey, setIsLoading, columns = [], noPage = false, 
        noFooter = false, initCustomParams = {}, apiFunc = () => {}, disabledRow, 
        useSelection, tableName = 'table', params, searchWithoutLike, useHeader, 
        headerRender, btnTitle, onBtnClick = () => {}, expandable = false, footer, 
        getCustomList, rowClassName, width, resetWhenClick } = props
    
    const { tableParams, fetchParams, list, ready, handleFetchParams, searchParams, setSearchParams,
        setList, handleTableParams, updatedRowId, selectedItem, setSelectedItem } = params

    const { t: ln_t } = useTranslation()
    const inputRef = useRef()

    const matchRowKey = record => rowKey?.split('_')?.map(key => record[key])?.join('_')

    const getList = async () => {
        setIsLoading(true)
        const result = await apiFunc()
        if (result?.status === 200) {
            const { results, count } = result.data || {}
            setList(results)
            const n = { ...tableParams }
            n.total = count
            n.current = fetchParams.page
            handleTableParams('patch', n)
        }
        setIsLoading(false)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        const s = searchParams || {}
        if (searchWithoutLike) s[dataIndex] = `${selectedKeys[0]}`
        else s[`${dataIndex}_like`] = `%${selectedKeys[0]}%`
        setSearchParams({ ...s })
    }
    
    const handleReset = (clearFilters, dataIndex) => {
        clearFilters()
        const s = searchParams || {}
        if (searchWithoutLike) s[dataIndex] = undefined
        else s[`${dataIndex}_like`] = undefined
        setSearchParams({ ...s })
    } 

    const filterIcon = filtered => <SearchOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />
    const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }, dataIndex) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={node => { inputRef.current = node }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
            </Space>
        </div>
    )

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: obj => filterDropdown(obj, dataIndex),
        filterIcon,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => inputRef.current?.select(), 100)
            }
        }
    })
  
    const sort = key => {
        const { sort } = fetchParams
        return !(sort && (sort === key || sort === `-${key}`)) 
            ? false : fetchParams.sort.includes('-') ? 'descend' : 'ascend'
    }

    const cols = useMemo(() => columns?.filter(item => item.dataIndex)?.map(item => {
        const { dataIndex, render, title, type, format, prefix } = item || {}
        let n = { width: 90, ...item }
        if (width <= 576) n = { ...item }
        if (!title && title !== '') n.title = ln_t(`${tableName}.${dataIndex}`)
        if (item.sorter) n.sortOrder = sort(dataIndex)
        if (item.search) n = { ...n, ...getColumnSearchProps(dataIndex) }
        n.render = (e, record) => {
            if (render) return render(e, record)
            if (!e) return '-'

            let tmp = e
            if (type === 'time' && format) tmp = moment(e).format(format)
            if (type === 'number') tmp = Number(tmp).toLocaleString()
            if (prefix) tmp = prefix + tmp
            return tmp
        }
        return n
    }) || [], [columns])
    
    const handleTableChange = (pagination, filters, sorter, { action: paginate }) => {
        const n = { ...fetchParams }
        resetWhenClick && n.id ? n.id = undefined : ''
        if (paginate !== 'filter') {
            if (paginate === 'paginate') n.page = pagination.current
            else if (paginate === 'sort') n.page = 1
    
            if (n.sort) n.sort = (sorter.order === 'ascend') ? sorter.field : `-${sorter.field}`
            handleFetchParams('patch', n)
        }
    }

    const defaultClassName = record => (matchRowKey(record) === updatedRowId ? '' : '')

    const rowSelection = {
        selectedRowKeys: selectedItem.map(item => matchRowKey(item)),
        onSelect: (record, selectedRows) => {
            const find = selectedItem.find(item => matchRowKey(item) === matchRowKey(record))
            if (find) setSelectedItem(selectedItem.filter(item => matchRowKey(item) !== matchRowKey(find)))
            else setSelectedItem([...selectedItem, record])
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) setSelectedItem([...selectedItem, ...changeRows])
            else if (!selected) {
                const newArr = []
                selectedItem.forEach(item => {
                    if (changeRows.find(row => matchRowKey(row) === matchRowKey(item))) return
                    if (!changeRows.find(row => matchRowKey(row) === matchRowKey(item))) newArr.push(item)
                })
                setSelectedItem(newArr)
            }
        },
        getCheckboxProps: record => ({
            disabled: disabledRow && disabledRow(record),
            name: matchRowKey(record),
        }),
    }

    useEffect(() => {
        if (!searchParams) return
        handleFetchParams('initPatch', { ...initCustomParams, ...searchParams })
    }, [searchParams])

    useEffect(() => {
        if (ready) getCustomList ? getCustomList() : getList()
    }, [fetchParams, ready])

    return (
        <>
            {useHeader 
                ? (
                    <div className="w-100 d-flex align-items-center flex-wrap justify-content-between">
                        {headerRender || <div />}
                        {btnTitle && (
                            <button 
                                className="main-button mb-2" 
                                type="button" 
                                onClick={() => onBtnClick()}
                            >
                                {btnTitle}
                            </button>
                        )}
                    </div>
                ) : ''}
            <div className={`main-table-container ${className}`}>
                <Table
                    columns={cols}
                    sortDirections={['descend', 'ascend', 'descend']}
                    dataSource={list}
                    pagination={!noPage ? tableParams : false}
                    rowKey={record => matchRowKey(record)}
                    onChange={handleTableChange}
                    scroll={{ x: 'true', scrollToFirstRowOnChange: true }}
                    showSorterTooltip={false}
                    rowSelection={useSelection ? rowSelection : false}
                    rowClassName={rowClassName || defaultClassName}
                    expandable={expandable}
                    footer={footer}
                />
                {noFooter ? '' : <TableFooter tableParams={tableParams} />}
            </div>
        </>
    )
}

export default MainTable
