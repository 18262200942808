import React from 'react'
import { Select } from 'antd'

function Selector(props) {
    const { options = [], disabled = false, showSearch = true, placeholder, allowClear = false,
        className, value, onChange = () => {}, style, mode, labelInValue = false } = props

    return (
        <Select
            options={options}
            placeholder={placeholder}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            showSearch={showSearch}
            disabled={disabled}
            value={value}
            optionFilterProp="label"
            className={className}
            onChange={onChange}
            style={style}
            mode={mode}
            labelInValue={labelInValue}
            allowClear={allowClear}
        />
    )
}

export default Selector
