import React, { useEffect, useState } from 'react'
import { Button, Input, message, Typography, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { CopyOutlined, InfoCircleOutlined, } from '@ant-design/icons'
import { createUrl, fetchAffiliateTax, fetchAffiliateUrlList } from '../../apis/affiliateWeb'
import MainTable from '../../components/Util/MainTable'
import useParams from '../../hook/useParams'

function Links(props) {
    const { userInfo, ln_t } = props

    const { roleId, nowEnterprise, lang } = userInfo || {}
    const { enterprise } = nowEnterprise || {}
    const params = useParams({ sort: '-createdAt', maxResult: 7 })
    const { fetchParams, handleFetchParams } = params

    const [link, setLink] = useState()
    const [enable, setEnable] = useState(false)

    const columns = [
        {
            title: ln_t('referralLink.referralUrl'),
            dataIndex: 'referralUrl',
            sorter: true,
            render: e => (
                <Typography.Text 
                    copyable={{ text: e, icon: <CopyOutlined className="icon-pos ml-1" /> }}
                >
                    {e}
                </Typography.Text>
            )
        },
        {
            title: ln_t('referralLink.clicks'),
            dataIndex: 'visitCount',
            sorter: true,
        },
        {
            title: ln_t('referralLink.title'),
            dataIndex: 'title',
            sorter: true,
        },
        {
            title: ln_t('referralLink.originalUrl'),
            dataIndex: 'originalUrl',
            sorter: true,
            render: e => (
                e.length > 60 ? (
                    <Tooltip title={e}>
                        {`${e.slice(0, 60)}...`}
                    </Tooltip>
                ) : e
            )
        },
        {
            title: ln_t('referralLink.createdAt'),
            dataIndex: 'createdAt',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        },
    ]

    const notAllowedInLink = ['utm_source', 'utm_medium', 'AllianceID', 'SID', 'OUID']
    const createLink = async () => {
        if (!link) return message.info(ln_t('message.info.insertLinkFirst'))
        if (notAllowedInLink?.some(i => link.includes(i))) return message.info(ln_t('message.info.linkIncludeRestrictedWord'))
        const result = await createUrl(roleId, nowEnterprise?.enterprise, { link })

        if (result?.status === 201) {
            handleFetchParams('init')
            message.info(ln_t('message.success.generate'))
        } else if (result?.data?.data?.id) {
            handleFetchParams('patch', { id: result?.data?.data?.id, page: 1 })
        }
    }
    
    const apiFunc = async () => fetchAffiliateUrlList(roleId, nowEnterprise?.enterprise, fetchParams)

    const checkTaxInfo = async () => {
        const result = await fetchAffiliateTax(roleId, enterprise)
        if (result?.status !== 200) return
        const { isBusiness, fullName, residentID, phone, address, birthDate,
            companyName, taxID, companyPhoneNumber, companyAddress, email } = result?.data?.[0] || {}
        const business = { companyName, taxID, companyPhoneNumber, companyAddress, fullName, phone, email }
        const personal = { fullName, residentID, phone, address, birthDate, }
        const filled = Object.values(isBusiness ? business : personal).every(i => i && i !== '')
        if (filled) setEnable(true)
    }
    useEffect(() => { checkTaxInfo() }, [])

    return (
        <>
            <h4 className="text-center mb-5">{ln_t('referralLink.pasteUrlToBeShared')}</h4>
            <div className="mb-5">
                <section className="d-flex align-items-center">
                    <Input 
                        placeholder={ln_t('referralLink.enterLinkHere')} 
                        className="mr-2"
                        style={{ height: '34px' }}
                        value={link}
                        onChange={e => setLink(e?.target?.value)}
                        onPressEnter={() => createLink()}
                        inputMode="url"
                    />
                    <Tooltip title={enable ? '' : ln_t('message.info.fillTaxInfoFirst')}>
                        <Button type="primary" onClick={() => createLink()} disabled={!enable}>{ln_t('util.generate')}</Button>
                    </Tooltip>
                </section>
                {enable ? '' : (
                    <div className="d-flex mt-2">
                        <InfoCircleOutlined className="mr-1" />
                        {ln_t('message.info.cannotGenerateLink')}
                        <Link to="/affiliate/account/taxInformation" className="ml-1">
                            {ln_t('message.info.fillTaxInfoFirst')?.toLowerCase()}
                        </Link>
                    </div>
                )}
            </div>
            <h5 
                className="mb-3 ml-2" 
                style={{ 
                    letterSpacing: lang === 'zh-TW' ? '1px' : 0,
                }}
            >
                {ln_t('referralLink.list')}
            </h5>
            <MainTable
                {...props}
                className="link-list"
                tableName="referralLink"
                columns={columns}
                rowKey="id"
                apiFunc={apiFunc}
                params={params}
                resetWhenClick
            />
        </>
    )
}

export default Links
