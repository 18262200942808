import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import Selector from '../../../components/Util/Selector'
import { fetchBankById, fetchBankList, modBankById } from '../../../apis/affiliateWeb'
import '../Profile.less'

function BankForm(props) {
    const [form] = Form.useForm()
    const { userInfo, dispatch, setIsLoading, ln_t, history, add, location, edit } = props

    const { roleId, nowEnterprise } = userInfo || {}
    const { enterprise } = nowEnterprise || {}
    const [bankList, setBankList] = useState([])

    const { state } = location || {}
    const { bankValues, id } = state || {}

    const getBankList = async () => {
        if (bankValues) form.setFieldsValue(bankValues)
        const result = await fetchBankList()
        if (result?.status === 200) setBankList(result.data.results.map(res => ({ label: res.name, value: res.code })))
    }

    const onFinish = async fieldValues => {
        if (add) return history.push({ pathname: '/affiliate/bank/add/step2', state })    
        
        const result = await modBankById(roleId, enterprise, id, fieldValues)
        if (result?.status === 200) {
            message.info(ln_t('message.success.modBank'))
            history.push('/affiliate/account/request')
        }
    }

    const getBankData = async () => {
        const result = await fetchBankById(roleId, enterprise, id)
        if (result?.status === 200) {
            const { bankCode, bankAccountName, bankBranchCode, bankAccount } = result.data
            form.setFieldsValue({ bankCode, bankAccountName, bankBranchCode, bankAccount })
        }
    }

    useEffect(() => {
        if (edit && !id) return history.push('/affiliate/account/request')
        getBankList()
        if (edit) getBankData()
    }, [])

    return (
        <div className="affiliate-profile h-100">
            <Form
                form={form}
                className="profile-form d-flex fd-col justify-content-between h-100"
                requiredMark={false}
                validateTrigger="onChange"
                onFinish={onFinish}
                layout="vertical"
                onFieldsChange={() => {
                    let values = form.getFieldsValue()
                    if (values.bankCode) {
                        const bankName = bankList?.find(bank => bank.value === values.bankCode)?.label
                        values = { bankName, ...values }
                    }
                    add && history.replace({ state: { ...state, bankValues: values } })
                }}
            >
                <div>
                    <h4 className="mb-5">{add ? ln_t('bank.addNewBankAccount') : ln_t('bank.modBank')}</h4>
                    <Form.Item label={ln_t('bank.bankName')} name="bankCode" rules={[{ required: true, message: ln_t('bank.placeholder.bankName') }]}>
                        <Selector options={bankList} placeholder={ln_t('bank.placeholder.bankName')} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('bank.longBankAccountName')} 
                        name="bankAccountName" 
                        rules={[
                            { required: true, message: ln_t('bank.placeholder.bankAccountName') },
                            { min: 2, message: ln_t('bank.placeholder.bankAccountNameLength') },
                        ]}
                    >
                        <Input allowClear autoComplete="off" placeholder={ln_t('bank.placeholder.bankAccountName')} />
                    </Form.Item>
                    <Form.Item 
                        label={ln_t('bank.bankAccountNumber')} 
                        name="bankAccount" 
                        rules={[
                            { required: true, message: ln_t('bank.placeholder.bankAccountNumber') },
                            { pattern: /^[0-9]{10,14}$/, message: ln_t('affiliate.validation.matchFormat') }
                        ]}
                    >
                        <Input allowClear autoComplete="off" placeholder={ln_t('bank.placeholder.bankAccountNumber')} inputMode="numeric" />
                    </Form.Item>
                    <Form.Item label={ln_t('bank.bankBranchCode')} name="bankBranchCode" rules={[{ required: true, message: ln_t('bank.placeholder.branchCode') }]}>
                        <Input allowClear autoComplete="off" placeholder={ln_t('bank.placeholder.branchCode')} inputMode="numeric" />
                    </Form.Item>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <Button type="button" className="mr-2" onClick={() => history.push('/affiliate/account/request')}>{ln_t('util.cancel')}</Button>
                    <Button htmlType="submit" type="primary">{add ? ln_t('util.next') : ln_t('util.confirm')}</Button>
                </div>
            </Form>
        </div>
    )
}

export default BankForm
