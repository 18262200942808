import React, { useState } from 'react'
import { Tabs } from 'antd'
import EarningDetail from './EarningDetail'
import WithdrawRecord from './WithdrawRecord'
import './Earning.less'

const tabOptions = ['detail', 'withdrawRecord']

function Earning(props) {  
    const { ln_t } = props
    const [tab, setTab] = useState(tabOptions[0])

    const onTabChange = key => setTab(key)

    const render = {
        detail: <EarningDetail {...props} />,
        withdrawRecord: <WithdrawRecord {...props} />
    }
  
    return (
        <div className="affiliate-transaction">
            <Tabs activeKey={tab} onChange={onTabChange}>
                {tabOptions.map(key => (
                    <Tabs.TabPane key={key} tab={ln_t(`earning.${key}`)}>
                        {tab === key ? render[key] : ''}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    )
}

export default Earning
