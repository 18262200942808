// checkAuth
const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

// handleError
const CLEAR = 'CLEAR'
const CATCH = 'CATCH'

// userProfile
const LOGOUT = 'LOGOUT'
const PATCH = 'PATCH'

function createRequestTypes(base) {
    const res = {};
    [REQUEST, SUCCESS, ERROR, CLEAR, CATCH, LOGOUT, PATCH].forEach(type => {
        res[type] = `${base}_${type}`
    })
    return res
}

export const CHECKAUTH = createRequestTypes('CHECKAUTH')
export const USERPROFILE = createRequestTypes('USERPROFILE')
export const HANDLEERROR = createRequestTypes('HANDLEERROR')
