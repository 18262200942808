import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { HANDLEERROR } from '../../constants/ActionTypes'
import { AFFILIATE, ROUTEBRAND, ROUTELOGIN } from '../../constants/Routing'
import affiliateStore from '../../store'

function LoginNotFound(props) {
    const { ln_t, dispatch, location, langOptions, changeLang, history } = props

    const { pathname } = location || {}
    const err = useSelector(state => state.handleError)
    const { userProfile } = affiliateStore.getState() || {}
    const { role, nowEnterprise } = userProfile || {}
    const { path } = useParams()

    useEffect(() => {
        if (role === AFFILIATE && nowEnterprise?.name) {
            const brand = ROUTEBRAND[nowEnterprise?.name]
            if (!brand) return
            history.push(`${ROUTELOGIN[AFFILIATE]}${brand}`)
        }
    }, [])

    useEffect(() => {
        if (err.catchError) dispatch({ type: HANDLEERROR.CLEAR })
    }, [err])

    return (
        <main className="login-container">
            <section className={`login-inner ${pathname.includes('affiliate') ? 'reduce-padding' : ''}`}>
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <div className="page-not-found">{ln_t('util.pageNotFound')}</div>
                <div className="not-found-desc">
                    {ln_t('message.info.pageNotFound')}
                </div>
                {pathname.includes('affiliate') ? (
                    <div className="copyright"><i>Copyright © 2022 LnData.INC, all rights reserved.</i></div>
                ) : ''}
            </section>
        </main>
    )
}
export default withRouter(LoginNotFound)
