import React, { useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import MainTable from '../../../components/Util/MainTable'
import { deleteEnterpriseUser, fetchEnterpriseUsers, modUser } from '../../../apis/user'
import UserForm from './UserForm'
import useParams from '../../../hook/useParams'
import { CHECKAUTH } from '../../../constants/ActionTypes'
import StatusSwitchConfirm from '../../../components/Util/StatusSwitchConfirm'
import './AccountManage.less'

function AccountManage(props) {
    const [modalVisible, setModalVisible] = useState(false)

    const { ln_t, userInfo, setIsLoading, dispatch } = props
    const { nowEnterprise } = userInfo || {}
    const { enterprise } = nowEnterprise || {}
    const params = useParams({ sort: '-updatedAt' })
    const { fetchParams, handleFetchParams } = params

    const handleEdit = async (userId, key, value) => {
        setIsLoading(true)
        const result = await modUser(enterprise, userId, { [key]: value })
        if (result?.status === 200) {
            dispatch({ type: CHECKAUTH.REQUEST })
            handleFetchParams('patch')
        }
        setIsLoading(false)
    }

    const columns = [
        {
            title: ln_t('user.email'),
            dataIndex: 'email',
        },
        {
            title: ln_t('user.name'),
            dataIndex: 'name',
        },
        {
            title: ln_t('user.role'),
            dataIndex: 'isAdmin',
            render: (e, record) => (
                <StatusSwitchConfirm 
                    onConfirm={() => handleEdit(record.enterpriseUserId, 'isAdmin', !e)}
                    checked={e}
                    name={record.name}
                    type="role"
                    width="76px"
                />
            )
        },
        {
            title: ln_t('user.createdAt'),
            dataIndex: 'createdAt',
            type: 'time',
            format: 'MM/DD/YYYY'
        },  
        {
            title: ln_t('user.updatedAt'),
            dataIndex: 'updatedAt',
            type: 'time',
            format: 'MM/DD/YYYY'
        },  
        {
            title: ln_t('user.status'),
            dataIndex: 'status',
            render: (e, record) => (
                <StatusSwitchConfirm 
                    onConfirm={() => handleEdit(record.enterpriseUserId, 'status', !e)}
                    checked={e}
                    name={record.name}
                />
            )
        },
    ]  

    const handleAdd = () => {
        setModalVisible(true)
    }

    const apiFunc = async () => fetchEnterpriseUsers(enterprise, fetchParams)

    return (
        <>
            <MainTable 
                {...props}
                className="user-list"
                tableName="enterpriseUser"
                columns={columns}
                rowKey="enterpriseUserId"
                apiFunc={apiFunc}
                params={params}
                useHeader
                btnTitle={`+ ${ln_t('settings.createUser')}`}
                onBtnClick={handleAdd}
            />
            <UserForm 
                {...props}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                userInfo={userInfo}
                enterpriseId={enterprise} 
                {...params}
            />
        </>
    )
}

export default AccountManage
