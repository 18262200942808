import styled from 'styled-components'

export const blockStyles = contentBlock => {
    const type = contentBlock.getType()
    if (type === 'code-block') {
        return 'codeBlock'
    }
}

export const Wrapper = styled.div`
    .ant-input-affix-wrapper, 
    .ant-input, 
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-dropdown {
      border-radius: 3px;
    }

    .ant-select {
      display: flex;
      align-items: center;
    }

    .ant-select-multiple {
      .ant-select-selector {
        width: 100%;
      }
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-textarea-with-clear-btn), 
    .ant-select, .ant-upload {
      margin: 0 0 1rem;
    } 
    
    .ant-input-textarea {
        margin: 0 0 1.6rem;
    }

    .ant-upload-list {
      display: inline-block;
      margin-left: 6px;

      .ant-upload-list-item {
        margin-top: 0;
      }
    }

    .ant-upload{
      .ant-btn {
        border-radius: 4px;
        text-align: left;
        span:first-child {
          margin-left: -3px;
        }
      }
    }
`

export const ControlBtns = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 2rem;
  z-index: 2;
  ${'' /* margin: 1rem 0 .8rem; */}
  padding: 1rem 0 .8rem;
  background-color: white;

  .anticon {
    font-size: 16px;
    padding-right: 3px;
    margin-right: 8px;
  }
`

export const EditorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;

    .awlhfjh {
        left: 50%;
        transform: translate(-50%) scale(0);
        position: absolute;
        border: 1ps solid #ddd;
        norder-radius: 2px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px #dcdcdc;
        z-index: 2;
        box-sizing: border-box;
    }

    .t16lpgj {
        margin: 0 0 1rem;
        order: 1;
        border-radius: 3px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: sticky;
        top: 3.3rem;
        min-height: 42px;
        background-color: white;
        box-shadow: 0px 1px 3px 0px #dcdcdc;
        z-index: 999;

        input {
            outline: none;
            height: 100%;
            width: 100%;
            border: none;
            padding: 0 .5rem;
        }

        .bi09khh {
            padding: 0;
            height: 100%;

            .bc4rxid {
                padding: 7px 12px;
                background: transparent;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                width: fit-content;
                cursor: pointer;
                transition: .3s;
                border: none;

                &.akzb7t5, &:hover {
                    background: #efefef;
                }
            }
            svg {
                height: 25px;
                width: 20px;
            }
        }
    }

    .DraftEditor-root {
        font-weight: 400;
        font-size: 18px;
        font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
        line-height: 2.4rem;
        overflow: hidden;
        letter-spacing: .5px;
        padding: 0 .8rem;
        order: 3;
        min-height: 200px;
        padding-bottom: 20px;

        .public-DraftEditorPlaceholder-root {
            color: #9197a3;
            position: absolute;
            width: 100%;
        }

        .DraftEditor-editorContainer {
            position: relative;
        }

        @media(max-width: 1500px) {
            font-size: 16px;
            line-height: 2.1rem;
        }
    
        @media(max-width: 768px) {
            font-size: 14px;
            line-height: 1.9rem;
        }
    
        h1,h2,h3 {
            font-weight: 400;
            line-height: 2.4rem;
        }
    
        h1 {
            font-size: 30px;
        }
    
        h2 {
            font-size: 22px;
        }
    
        h3 {
            font-size: 20px;
        }
    
        p {
            margin-bottom: .2rem;
        }
    
        pre:not(pre > pre) {
            padding: 20px;
            background-color: rgba(242, 242, 242, 1);
            font-size: 15px;
            margin: 0;

            pre {
                margin: 0;
            }
    
            code {
                margin-bottom: 4px;
            }
        }
    
        ul, ol {
            margin: 0;
            padding: 0;    
    
            li {
                margin: 0 10px 0 30px;
                padding: 0;
    
                @media(max-width: 768px) {
                    margin: 0 0 10px 25px;
                }
            }
        }
    
        blockquote {
            font-size: 19px;
            padding: 2rem 7rem 2rem 4rem;
            font-style: italic;
            margin: 0 auto;
            font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    
    
            @media(max-width: 768px) {
                padding: 1rem 1.4rem 1rem 1rem;
                font-size: 16px;
            }
            
        }
    
        a {
            padding: 0 2px;
            text-decoration: underline;
    
            &:hover {
                color: inherit;
            }
        }
    
        figure {
            margin: 1.4rem 0;
    
            img {
                object-fit: contain;
                max-width: 100%;
            }
    
            iframe {
                border: none;
            }
        }
    }
`

export const DatePickerStyled = styled.div`
    margin: 0 0 1rem;

    .ant-picker {
        border-radius: 0px;
        width: 100%;
    }
    
    .ant-picker-input > input {
        font-size: 16px !important;
    }
`
