import React from 'react'
import '../Main.less'

function BrandRules(props) {
    const { size } = props
    return (
        <section className={`rules-wrapper ${size}`}>
            <h5 className="mb-4 text-center">聯盟行銷合作協議</h5>
            <ol className="parent">
                <li className="level-1">定義：
                    <ol>
                        <li>「聯盟行銷計畫」（Affiliate Program） 為一個創造甲方與乙方雙贏的利潤分享模式。甲方經由聯盟行銷機制與乙方取得連結，使顧客透過聯盟網站連結進入乙方網站購買產品，乙方再從確認完成交易金額中提撥部分利潤予甲方。</li>
                        <li>「甲方」係指同意此條款參與計畫的夥伴</li>
                        <li>「乙方」係指易遊網旅行社股份有限公司（Eztravel）</li>
                        <li>「顧客」係指透過聯盟行銷計畫進入乙方網站購買產品的消費者。</li>
                        <li>「連結」係指經由超文件標示語言（HTML）與全球資訊網（WWW）連接的動態。</li>
                        <li>「產品」係指任何出現在乙方網站上銷售的物件。</li>
                        <li>「乙方網站」係指「Eztravel.com.tw」，網址為「https://www.eztravel.com.tw/」</li>
                    </ol>
                </li>
                <li className="level-1">合作模式：
                    <ol>
                        <li>合約期間
                            <ol>
                                <li>本合約有效期間自本合約簽定之日起至 2026 年 3 月 31 日止。</li>
                                <li>如任一方未於本合約屆滿前一個月，以書面通知他方解約之意思表示本合約自動續約。依照跟聯盟合約簽約時間系統解約前公告（後台發信）</li>
                            </ol>
                        </li>
                        <li>凡透過乙方提供予甲方之專屬連結進入乙方網站並產生購買行為者，乙方將依扣除折價券金額後之實際銷售金額給予甲方佣金回饋。</li>
                        <li>支付佣金之計算方式如下：
                            <p>支付佣金數額 = A * R</p>
                            <p>A = 推廣產品訂單金額（以訂單實際支付金額計，須扣除折扣碼）</p>
                            <p>R = 佣金百分比率；按照每月推廣產品訂單金額計算</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>推廣產品訂單金額(A)</th>
                                        <th>佣金比例(R)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>訂房</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>國內旅遊</td>
                                        <td>4%</td>
                                    </tr>
                                    <tr>
                                        <td>國外旅遊</td>
                                        <td>4%</td>
                                    </tr>
                                    <tr>
                                        <td>機票</td>
                                        <td>0.8%</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">備註：
                                            <ol>
                                                <li>推廣產品訂單金額(A)定義：須為顧客訂單成立完成支付且已實際完成入住/行程的狀態。若為新成立訂單，須等待該筆訂單已實際完成入住/行程後才可以計入；若訂單成立後不論因任何因素取消則不應列入計算。</li>
                                                <li>佣金將每月結算一次。</li>
                                                <li>佣金比例乙方保有隨時變更調整之權利。</li>
                                            </ol>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>聯盟佣金原則上依本合約約定之報酬比例計算之以新臺幣計價；乙方保有隨時調整佣金比例的權利，但應於生效日30日前通知甲方（後台統一公告），若甲方未於10天內通知乙方終止合約即視為同意。</li>
                        <li>佣金協議或都應基於實際交易，且可歸因於甲方的營銷能力，計算方式如下：將 Cookie 置入甲方網站並連結至乙方網域，該 Cookie 包含特定的甲方 專屬追蹤碼。如訪問者透過Cookie連結進入乙方網站進行實際交易，該次交易行為將被記錄。若訪問者中途連結至其他網站，此次交易行為將不會計入。</li>
                    </ol>
                </li>
                <li className="level-1">交易與付款流程：
                    <ol>
                        <li>所有產品交易流程悉依乙方公告為之，如有違反，乙方有權拒絕該筆交易。</li>
                        <li>
                            甲方得隨時於聯盟行銷後台查詢累積佣金金額，如累積金額超過新臺幣3,000元以上，
                            甲方得於<span style={{ color: 'red' }}>當月10日至15日</span>前使用聯盟行銷後台向乙方申請提領(或保留)，如選擇提領，
                            乙方將於當月28號完成匯款至甲方指定帳戶。 如甲方申請提領時間為<span style={{ color: 'red' }}>當月16日(含)以後</span>，
                            則佣金將延至下期匯款。乙方實際支付佣金金額將扣除依法代甲方扣繳稅額及匯款手續費。 
                            甲方應自行確認匯款資訊正確並提供相關證明，如有填寫錯誤應由甲方自行負責，如造成乙方多次匯款，產生之額外費用由甲方負擔。
                        </li>
                    </ol>
                </li>
                <li className="level-1">甲方之義務：
                    <ol>
                        <li>甲方須隨時主動自行更新甲方網頁上有關乙方提供之最新連結及一切資訊。</li>
                        <li>
                            甲方如有詐欺、資料造假、蓄意破壞及擅自使用聯盟行銷軟體專利、或乙方基於聯盟行銷計畫提供的所有資訊
                            （包含會員資料、商標、網頁、圖文或影音素材等）於暴力、色情、賭博或任何違法網站，以及發放任何未經接收者同意之垃圾郵件、
                            網路訊息者，乙方得隨時逕行終止合約。甲方應負責處理解決一切衍生之事宜，並承擔一切責任及損失，如乙方因此受有損害或支付相關費用
                            （包括但不限於訴訟費、律師費、罰鍰、罰金、和解金或損害賠償等），甲方應負賠償給付之責。
                        </li>
                        <li>
                            甲方網頁內容不得有任何誇大、虛偽不實或引人錯誤之表示或表徵。倘甲方網頁內容、個人有爭議之行為或經營網站方式有違反法令或負面影響本合約聯盟行銷之情事，
                            乙方得逕行終止本合約。甲方應負責處理解決一切衍生之事宜，並承擔一切責任及損失，如乙方因此受有損害或支付相關費用（包括但不限於訴訟費、律師費、罰鍰、罰金、
                            和解金或損害賠償等），甲方應負賠償之責。
                        </li>
                        <li>
                            本合約終止後，乙方有權利要求甲方將其所使用的乙方之文字、圖示、影像移除，若甲方因為未移除乙方相關文字、圖示、影像使乙方因此受有損害或支付相關費用
                            （包括但不限於訴訟費、律師費、罰鍰、罰金、和解金或損害賠償等），甲方應負賠償給付之責任。
                        </li>
                    </ol>
                </li>

                <li className="level-1">合約限制：
                    <ol>
                        <li>甲方得經乙方許可使用乙方之文字、圖示、影像，惟此使用權不具獨佔性，且不可用作與直接導引至乙方網站交易無關之用途。所有乙方的文字、圖示、影像及商標之智慧財產權均為乙方所有，甲方不可任意修改。</li>
                        <li>
                            甲方僅得於履行本協議目的範圍內編輯、改作乙方提供的所有文字（不包含所有圖示及影像），乙方有權審核，如認有修改必要得通知甲方修改，
                            甲方不得異議。經乙方通知限期仍不改善時，乙方有權終止合約。甲方應負責處理解決一切衍生之事宜，並承擔一切責任及損失，
                            如乙方因此受有直接損害或支付相關費用（包括但不限於訴訟費、合理律師費、罰鍰、罰金、和解金或損害賠償等），甲方應負賠償之責。
                        </li>
                        
                        <li>
                            甲方應對所有透過聯盟行銷連結進入乙方網站的顧客訂購資料（包括但不限於訂單銷售報表等）負永久保密義務。如因可歸責於甲方之事由而致上開資料外洩，
                            乙方得逕行終止本合約。因甲方行為所生之相關法律責任，概由甲方自行負責，如乙方因此受有損害或支付相關費用（包括但不限於訴訟費、律師費、罰鍰、罰金、和解金或損害賠償等），甲方應負賠償責任。
                        </li>
                        <li>
                            有關本合約涉及的法律問題，概依中華民國法律為準；如因本合約而涉訟，雙方同意以臺灣台北地方法院為第一審管轄法院。
                        </li>

                        <li>
                            合約一方有破產、重整、解散、暫停營業、停止網站之經營或其它嚴重影響債信之情事發生時，本合約得自動終止。
                        </li>
                        <li>
                            合約正式終止日前已發生之佣金，依標準作業程序進行請款及撥款的動作，不因合約終止而影響該筆款項之歸屬。
                        </li>
                        <li>
                            任一方欲終止本合約，得於 30 日前以書面通知另一方後終止。本合約約定得逕行終止合約者，不在此限。
                        </li>
                        <li>
                            本合約終止後，甲方應立即停止聯盟行銷之會員帳號及一切系統平台服務。
                        </li>
                    </ol>
                </li>
                <li className="level-1">其他規定：
                    <ol>
                        <li>甲方是一個獨立的合約簽定者，與乙方的合作不牽涉任何從屬、併購、業務代表、公司職員、代理、仲介等關係。</li>
                        <li>
                            未經他方事前同意，任一方不得將本合約之權利、義務轉讓予第三人。
                        </li>
                        <li>
                            本合約若有未盡事宜，甲方得通知乙方確認，乙方如認有必要得另行公告補充，作為本合約之附件，自公告日起生效。
                        </li>
                    </ol>
                </li>
            </ol>
        </section>
    )
}

export default BrandRules
