import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { AFFILIATE, ROUTEBASE } from '../constants/Routing'
import Profile from './Affiliate/Profile'
import Links from './Affiliate/Links'
import Earning from './Affiliate/Earning/Main'
import ModPassword from './Affiliate/ModPassword'
import NotFoundPage from './NotFoundPage/NotFoundPage'
import Account from './Affiliate/Account'
import BankAndWithdraw from './Affiliate/BankAndWithdraw'
import BankForm from './Affiliate/Bank/Form'
import ConfirmValues from './Affiliate/Bank/ConfirmValues'
import VerifyBank from './Affiliate/Bank/VerifyBank'
import RuleAndTerms from '../components/Terms/Main'
import News from './Affiliate/News/Main'
import NewsPreview from './Affiliate/News/Preview'

export default function AffiliatePages(props){
    return (
        <Switch>
            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/account/profile`}
                render={(value => <Account {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/links`}
                render={(value => <Links {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/earning`}
                render={(value => <Earning {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/news`}
                render={(value => <News {...props} {...value} />)}
            />
            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/news/content`}
                render={(value => <NewsPreview {...props} {...value} />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/account/request`}
                render={(value => <BankAndWithdraw {...props} {...value} />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/account/request/rules&terms`}
                render={(value => <RuleAndTerms {...props} {...value} size="small" />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/account/request/howToVerify`}
                render={(value => <VerifyBank {...props} {...value} />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/bank/modify`}
                render={(value => <BankForm {...props} {...value} edit />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/bank/add/step1`}
                render={(value => <BankForm {...props} {...value} add />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/bank/add/step2`}
                render={(value => <Profile {...props} {...value} addBank />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/bank/add/step3`}
                render={(value => <ConfirmValues {...props} {...value} />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/bank/add/step4`}
                render={(value => <VerifyBank {...props} {...value} addBank />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/account/taxInformation`}
                render={(value => <Profile {...props} {...value} />)}
            />

            <Route
                exact
                path={`${ROUTEBASE[AFFILIATE]}/mod-password`}
                render={(value => <ModPassword {...props} {...value} />)}
            />
            <Route path="*" render={() => <NotFoundPage />} />
        </Switch>
    )
}
