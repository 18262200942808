import { MethodType } from '../constants/MethodType'
import { ENTERPRISE } from '../constants/Routing'
import store from '../store'

const api = require('./api')

export const login = async (role, params) => api.request(1, `/api/v3/${role}/login/`, MethodType.POST, params, false)

export const logout = async params => {
    const { role } = store?.getState()?.userProfile || {}

    if (!role) return { data: 'not logged in', status: 400 }

    if (role === ENTERPRISE) return api.request(1, '/api/v3/enterprise/logout/', MethodType.POST, params, true)
    return api.request(1, '/api/v3/affiliate/logout/', MethodType.POST, params, true)
}

export const refreshAuth = async params => {
    const { role } = store?.getState()?.userProfile || {}

    if (!role) return { data: 'not logged in', status: 400 }
    if (role === ENTERPRISE) return api.request(1, '/api/v3/enterprise/refresh/', MethodType.POST, params)
    return api.request(1, '/api/v3/affiliate/refresh/', MethodType.POST, params)
}

export const forgetPassword = async params => api.request(1, '/api/v3/resetpassword/', MethodType.POST, params)

export const setNewPassword = async params => api.request(1, '/api/v3/setpassword/', MethodType.POST, params)

export const checkAffiliate = async params => api.request(1, '/api/v3/affiliate/check_affiliate/', MethodType.POST, params, false)

export const verifyOtp = async params => api.request(1, '/api/v3/verifyotp/', MethodType.POST, params)

export const getOtp = async params => api.request(1, '/api/v3/getotp/', MethodType.POST, params)
