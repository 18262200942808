import React from 'react'
import { Tag, Modal, Divider } from 'antd'
import { pick } from 'lodash'
import moment from 'moment'
import MainTable from '../../../components/Util/MainTable'
import { getAffiliateListByEnterprise } from '../../../apis/affiliate'
import useParams from '../../../hook/useParams'
import SearchForm from './SearchForm'

function Personal(props) {
    const { ln_t, userInfo, setIsLoading, download, modalConf } = props
    const { nowEnterprise } = userInfo || {}
    const { enterprise } = nowEnterprise || {}
    const params = useParams({ sort: '-createdAt', isBusiness: false })
    const { fetchParams } = params

    const showMoreInfo = record => {
        Modal.info({
            ...modalConf,
            okType: 'button',
            autoFocusButton: null,
            maskClosable: true,
            title: <h4 className="text-center">{ln_t('affiliate.personalInformation')}</h4>,
            content: (
                <section className="px-4 py-2">
                    {['affiliateName', 'idNumber', 'phone', 'address', 'birthDate'].map(key => (
                        <div key={key} className="mb-1">
                            <span style={{ color: 'rgba(0,0,0,.6)' }}>{ln_t(`affiliate.info.${key}`)}：</span>
                            <span style={{ fontSize: '16px' }}>{record[key] || '-'}</span>
                        </div>
                    ))}
                    <Divider />
                    <h6 className="mb-3">{ln_t('affiliate.socialInformation')}：</h6>
                    {['fb', 'ig', 'yt', 'tiktok', 'personalBlog', 'others'].map(key => (
                        <div key={key} className="mb-1">
                            <span style={{ color: 'rgba(0,0,0,.6)' }}>{ln_t(`affiliate.info.${key}`)}：</span>
                            <span style={{ fontSize: '16px' }}>{record[key] || '-'}</span>
                        </div>
                    ))}
                    <Divider />
                    <h6 className="mb-3 d-flex justify-content-between">
                        <span>{ln_t('bank.bankInformation')}：</span>
                        <Tag color={record.isBankVerified ? 'var(--primary)' : 'red'}>
                            {ln_t(`bank.isBankVerified.${record.isBankVerified}`)}
                        </Tag>
                    </h6>
                    {['bankName', 'bankAccountName', 'bankAccount', 'bankBranchCode'].map(key => (
                        <div key={key} className="mb-1">
                            <span style={{ color: 'rgba(0,0,0,.6)' }}>{ln_t(`bank.${key}`)}：</span>
                            <span style={{ fontSize: '16px' }}>{record.banks?.[0]?.[key] || '-'}</span>
                        </div> 
                    ))}
                </section>
            )
        })
    }
      
    const columns = [
        {
            title: ln_t('affiliate.id'),
            dataIndex: 'affiliate__ouid',
            sorter: true,
            render: (e, data) => data?.ouid
        },
        {
            title: ln_t('affiliate.name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: ln_t('affiliate.fullName'),
            dataIndex: 'fullName',
            sorter: true,
        },
        {
            title: ln_t('affiliate.idNumber'),
            dataIndex: 'personalInformation',
            sorter: true,
            render: e => e.idNumber
        },
        {
            title: ln_t('affiliate.phone'),
            dataIndex: 'personalInformation',
            // sorter: true,
            render: e => e.phone
        },
        {
            title: ln_t('affiliate.address'),
            dataIndex: 'personalInformation',
            // sorter: true,
            render: e => e.address
        },
        {
            title: ln_t('affiliate.birthDate'),
            dataIndex: 'personalInformation',
            // sorter: true,
            render: e => e.birthDate
        },
        {
            title: ln_t('affiliate.email'),
            dataIndex: 'affiliate__email',
            sorter: true,
            render: (e, data) => data?.email
        },
        {
            title: ln_t('affiliate.tag'),
            dataIndex: 'tag',
            sorter: true,
            render: (e, record) => (
                <div className="d-flex justify-content-center">
                    {e?.split(',').map(tag => <Tag key={`${record.affiliate}_${tag}`}>{tag}</Tag>) || '-'}
                </div>
            )
        },
        {
            title: ln_t('affiliate.joinedDate'),
            dataIndex: 'createdAt',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        },
        {
            title: ln_t('affiliate.completedOrderCount'),
            dataIndex: 'completedOrderCount',
            sorter: true,
            type: 'number',
        },
        {
            title: ln_t('affiliate.completedOrderAmount'),
            dataIndex: 'completedOrderAmount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('affiliate.completedReferralAmount'),
            dataIndex: 'completedReferralAmount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('affiliate.tentativeOrderCount'),
            dataIndex: 'tentativeOrderCount',
            sorter: true,
            type: 'number',
        },
        {
            title: ln_t('affiliate.tentativeOrderAmount'),
            dataIndex: 'tentativeOrderAmount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('affiliate.tentativeReferralAmount'),
            dataIndex: 'tentativeReferralAmount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('affiliate.personalInformation'),
            dataIndex: 'personalInformation',
            render: e => (
                <div onClick={() => showMoreInfo(e)} className={e.isBankVerified ? 'primary-link' : 'red-link'}>
                    {ln_t(`bank.isBankVerified.${e.isBankVerified}`)}
                </div>
            )
        },
    ]

    const apiFunc = async () => getAffiliateListByEnterprise(enterprise, fetchParams)

    const handleDownload = async () => {
        setIsLoading(true)
        const result = await getAffiliateListByEnterprise(enterprise, { ...fetchParams, sort: '-createdAt', maxResult: 9999 })
        if (result?.status === 200) {
            const fakeBankInfo = {
                bankName: null,
                bankAccountName: null,
                bankAccount: null,
                bankBranchCode: null,
            }
            const x = result?.data?.results?.map(({ createdAt, ...item }) => ({
                ...item,
                ...item.personalInformation,
                ...(item.personalInformation?.banks?.[0] || fakeBankInfo),
                createdAt: moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
            }))
            const neededCols = ['affiliate', 'email', 'fullName', 'idNumber', 'phone', 'address', 'birthDate', 'tag', 'createdAt', 
                'completedOrderCount', 'completedOrderAmount', 'completedReferralAmount', 'tentativeOrderCount', 'tentativeOrderAmount',
                'tentativeReferralAmount', 'affiliateName', 'fb', 'ig', 'yt', 'tiktok', 'personalBlog', 'others', 'bankName', 'bankAccountName', 
                'bankAccount', 'bankBranchCode', 'isBankVerified']
            const colNames = Object.assign({}, ...neededCols.map(col => ({ [col]: ln_t(`affiliate.${col}`) })))
            const n = x.map(item => pick(item, neededCols))
            download([[colNames, ...n]], ln_t('breadcrumb.affiliate.list'), true)
        }
        setIsLoading(false)
    }
    
    return (
        <MainTable 
            {...props}
            className="affiliate-list"
            tableName="affiliate"
            columns={columns}
            rowKey="affiliate"
            apiFunc={apiFunc}
            params={params}
            useHeader
            headerRender={<SearchForm {...props} {...params} />}
            btnTitle={ln_t('util.download')}
            onBtnClick={handleDownload}
        />
    )
}

export default Personal
