import React from 'react'
import { Dropdown, Menu } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import './Header.less'
import '../Util/Button.less'
import { ROUTEBASE, ENTERPRISE } from '../../constants/Routing'

function EnterpriseHeader(props) {
    const { dropDownVisible, toggleDropDown, setToggle, toggle, 
        sendLogout, userInfo, resetDropDown, langOptions, changeLang, 
        history, ln_t } = props

    return (
        <div className="header">
            <div className={`navbar-header ${toggle ? '' : 'active'}`}>
                <Link to="/enterprise">
                    <div className={`logo-box ${toggle ? '' : 'active'}`}>
                        <img src="/lndata_logo.png" alt="Lndata Logo" />
                    </div>
                </Link>
                <div className="adx-bar" onClick={() => setToggle(!toggle)}>
                    <div className="bar-line" />
                    <div className="bar-line" />
                    <div className="bar-line" />
                </div>
            </div>

            <section className="header-left-section">
                <div className="main-button active mr-2" onClick={() => changeLang(langOptions[0].value)}>  
                    {langOptions[0].label}
                </div>
                <Dropdown
                    visible={dropDownVisible.user}
                    placement="bottomRight"
                    trigger={['click']}
                    overlay={(
                        <Menu className="mt-2 user-menu" triggerSubMenuAction="click">
                            <Menu.ItemGroup title={<div className="affiliate-brand"><span>{userInfo?.enterprises?.[0].name}</span></div>} />
                            <Menu.ItemGroup title={(
                                <div className="d-flex justify-content-center text-dark m-auto">
                                    <span className="cursor-pointer" onClick={() => { resetDropDown(); history.push(`${ROUTEBASE[ENTERPRISE]}/user-profile`) }}>{ln_t('user.profile')}</span> 
                                    <span className="mx-3">|</span>
                                    <span className="cursor-pointer" onClick={() => { resetDropDown(); sendLogout() }}>{ln_t('user.signOut')}</span>
                                </div>
                            )}
                            />
                        </Menu>
                    )}
                >
                    <div className={`main-button ${dropDownVisible.user ? 'active' : ''}`} onClick={() => toggleDropDown('user')}>  
                        <UserOutlined className="mr-2" />{userInfo?.username}
                    </div>
                </Dropdown>
            </section>
        </div>
    )
}

export default EnterpriseHeader
