export const ROUTEBASE = {
    enterprise: '/enterprise',
    affiliate: '/affiliate'
}
export const ROUTEHOME = {
    enterprise: '/enterprise/dashboard',
    affiliate: '/affiliate/links'
}

export const ROUTELOGIN = {
    enterprise: '/enterprise/login',
    affiliate: '/affiliate/login'
}

export const ROUTEBRAND = {
    EzTravel: '/ezTravel',
    LionTravel: '/lionTravel',
}

export const validEnterprises = [
    { id: '1', name: 'ezTravel' },
    { id: '2', name: 'lionTravel' },
    { id: '3', name: 'pizzaHut' }
]

export const ENTERPRISE = 'enterprise'
export const AFFILIATE = 'affiliate'
export const LOGIN = 'login'
