import React, { useEffect, useState, useCallback } from 'react'
import { Tabs } from 'antd'
import { Doughnut } from 'react-chartjs-2'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { fetchProductSummary } from '../../../apis/stats'
import { fetchEnterpriseProducts } from '../../../apis/product'

function ProductSummary(props) {
    const { userInfo, ln_t, className, width } = props
    const { nowEnterprise, lang } = userInfo || {}
    const { enterprise } = nowEnterprise || {}
    const [datas, setDatas] = useState({})
    const [graphHeight, setGraphHeight] = useState(0)
    const [fullData, setFullData] = useState({})
    const cats = ['OrderCount', 'OrderAmount', 'ReferralAmount']
    const labelOpts = [
        { label: ln_t('product.summary.completed'), color: 'rgba(7, 179, 161, 1)' }, 
        { label: ln_t('product.summary.tentative'), color: 'rgba(7, 179, 161, .6)' }, 
        { label: ln_t('product.summary.canceled'), color: 'rgba(7, 179, 161, .3)' }
    ]

    const emptyOpts = {
        cutoutPercentage: 75,
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        }
    }

    const graphOpts = {
        cutoutPercentage: 75,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                title(tooltipItem, data) {
                    const datas = data?.datasets?.[0]?.data
                    const total = datas?.reduce((a, b) => a + b)
                    const index = tooltipItem?.[0]?.index
                    const val = datas?.[index]
                    return `${data.labels?.[index]} (${String(((val / total) * 100).toFixed(1)).replace('.0', '')}%)`
                },
                label(tooltipItem, data) {
                    const datas = data?.datasets?.[0]?.data
                    const count = data?.datasets?.[0].label.includes('Count')
                    const index = tooltipItem?.index
                    return `${!count ? '$ ' : ''}${datas?.[index]?.toLocaleString()}${!count ? ' NTD' : ` ${ln_t('dashboard.orders')}`}`
                },
            },
            titleFontSize: 16,
            bodyFontSize: 16,
            padding: 12,
            displayColors: false,
        }
    }    

    const graphRef = useCallback(node => {
        if (!node) return
        const h = node.offsetWidth * 0.8
        setGraphHeight(h)
    }, [width, datas])

    const getDatas = async () => {
        const getProducts = await fetchEnterpriseProducts(enterprise) 
        if (getProducts?.status === 200) {
            const { results: products } = getProducts.data

            const result = await fetchProductSummary(enterprise)
            if (result?.status === 200) {
                const { results } = result.data
                const labels = [ln_t('product.summary.completed'), ln_t('product.summary.tentative'), ln_t('product.summary.canceled')]
    
                const n = Object.assign({}, ...products.map(res => {
                    const { product } = res
                    const match = results?.find(item => item.product === product)
                    const x = Object.assign({}, ...cats.map(cat => ({
                        [cat]: {
                            labels,
                            datasets: [
                                {
                                    label: cat,
                                    data: match ? Object.keys(match).filter(key => key.includes(cat) && !key.includes('diff')).map(key => match[key]) : labels.map(() => 0),
                                    backgroundColor: labelOpts.map(item => item.color)
                                }
                            ]
                        }
                    })))
                    return { 
                        [product]: x
                    }
                }))
                setDatas(n)
                const f = Object.assign({}, ...results.map(item => ({
                    [item.product]: item
                })))
                setFullData(f)
            }    
        }
    }

    useEffect(() => {
        getDatas()
    }, [lang])

    return (
        <section className={`${className} doughnut-wrapper w-100`}>
            <Tabs>
                {Object.keys(datas).map(key => (
                    <Tabs.TabPane key={key} tab={key}>
                        <div className="d-flex align-items-center mb-5">
                            {labelOpts.map(opt => (
                                <div className="d-flex align-items-center mr-4" key={`summary-label-${opt.label}`}>
                                    <div className="label-color" style={{ background: opt.color }} />
                                    <h6 className="m-0">{opt.label}</h6>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex flex-wrap justify-content-between" style={{ gap: '2rem 0' }}>
                            {cats.map((cat, index) => {
                                const match = datas[key][cat] 
                                const { datasets } = match
                                const { data } = datasets[0]
                                const total = data.reduce((a, b) => a + b)
                                const totalK = total / 1000
                                const diff = fullData?.[key]?.[`diff${cat}`] || 0
                                const diffK = Math.abs(diff) / 1000
                                return (
                                    <div className="doughnut" key={`${key}_${cat}_${graphHeight}`} ref={graphRef}>
                                        <h5 className="text-center mb-4">{ln_t(`dashboard.${datasets[0]?.label}`)}</h5>
                                        <div style={{ position: 'relative' }}>
                                            <Doughnut data={datas[key][cat]} options={graphOpts} responsive height={graphHeight} width={graphHeight} />
                                            {total 
                                                ? '' 
                                                : (
                                                    <div style={{ position: 'absolute', top: 0, left: 0 }} className="w-100">
                                                        <Doughnut 
                                                            data={{
                                                                datasets: [
                                                                    {
                                                                        data: [100],
                                                                        backgroundColor: ['rgba(0, 0, 0, .1)']
                                                                    }
                                                                ]
                                                            }} 
                                                            options={emptyOpts} 
                                                            responsive 
                                                            height={graphHeight} 
                                                            width={graphHeight}
                                                        />
                                                    </div>
                                                )}
                                            <div 
                                                className="doughnut-center" 
                                                style={{ width: `${graphHeight * 0.75}px`, height: `${graphHeight * 0.75}px` }}
                                            >
                                                <h3 className="mb-1">
                                                    {!index ? '' : <span className="mr-1" style={{ fontSize: '16px' }}>$</span>}
                                                    {totalK >= 1 
                                                        ? (
                                                            <>
                                                                {Number(totalK.toFixed(1)).toLocaleString().replace('.0', '')}
                                                                <span className="ml-1" style={{ fontSize: '16px' }}>{index ? ln_t('util.thousand') : ln_t('util.thousandOrders')}</span>
                                                            </>
                                                        ) 
                                                        : (
                                                            <>
                                                                {total}
                                                                <span className="ml-1" style={{ fontSize: '16px' }}>{index ? ln_t('util.dollar') : ln_t('util.orders')}</span>
                                                            </>
                                                        )}
                                                </h3>
                                                <h6 className="mb-1 d-flex align-items-center">
                                                    {diff 
                                                        ? diffK >= 1 
                                                            ? (
                                                                <>
                                                                    {Number(diffK.toFixed(1)).toLocaleString().replace('.0', '')}
                                                                    <span className="ml-1" style={{ fontSize: '12px' }}>{index ? ln_t('util.thousand') : ln_t('util.shortThousandOrders')}</span>
                                                                </>
                                                            ) 
                                                            : (
                                                                <>
                                                                    {Math.abs(diff).toFixed(1)}
                                                                    <span className="ml-1" style={{ fontSize: '12px' }}>{index ? ln_t('util.dollar') : ln_t('util.shortOrderCount')}</span>
                                                                </>
                                                            ) : ''}
                                                    {diff > 0 
                                                        ? <ArrowUpOutlined className="icon up" /> 
                                                        : diff 
                                                            ? <ArrowDownOutlined className="icon down" /> 
                                                            : <span className="icon">-</span>}
                                                </h6>
                                                <p>{ln_t('dashboard.fromLastMonth')}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </section>
    )
}

export default ProductSummary
