import React, { useState, useMemo, useEffect } from 'react'
import { MenuOutlined, UserOutlined, DollarCircleOutlined, LinkOutlined, WalletOutlined, ProfileOutlined, BellFilled } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Drawer, Menu, Dropdown, Select, Popover, Button } from 'antd'
import moment from 'moment'
import { ROUTEBASE, AFFILIATE, ROUTEHOME } from '../../constants/Routing'
import './Header.less'
import '../Util/Button.less'
import ActiveEvent from './ActiveEvent'
import SidebarMenu from './Menu'
import { fetchNotif, modifyNotif } from '../../apis/news'
import userProfile from '../../reducers/userProfile'

const fakeData = [
    {
        id: 6,
        isRead: false,
        title: '【飯店快閃】台北中山/西門町意舍酒店',
        descriptions: '',
        campaignStart: '2020-09-20T00:00:00+08:00',
        campaignEnd: '2020-09-20T00:00:00+08:00',
        isPublished: false,
        isDeleted: false,
        updatedTime: '2022-09-06T12:16:36.243960+08:00',
        enterprise: 1
    },
    {
        id: 5,
        isRead: false,
        title: '測試文章',
        descriptions: '測試文章',
        campaignStart: '2022-06-01T08:00:00+08:00',
        campaignEnd: '2022-09-01T08:00:00+08:00',
        isPublished: true,
        isDeleted: false,
        updatedTime: '2022-09-01T09:44:25.086723+08:00',
        enterprise: 1
    },
    {
        id: 7,
        isRead: true,
        title: 'CODE功能測試',
        descriptions: '',
        campaignStart: '2022-09-06T08:00:00+08:00',
        campaignEnd: '2022-09-30T08:00:00+08:00',
        isPublished: false,
        isDeleted: false,
        updatedTime: '2022-09-06T09:15:37.174496+08:00',
        enterprise: 1
    },
]

function AffiliateHeader(props) {
    const [visible, setVisible] = useState(false)

    const { width, userInfo, sendLogout, langOptions, 
        changeLang, ln_t, history, location } = props
    const { nowEnterprise, email, username, enterprises, roleId } = userInfo || {}
    const { logo, name } = nowEnterprise || {}
    const [notification, setNotification] = useState([])
    const [newNotification, setNewNotification] = useState(false)

    const onClose = () => {
        setVisible(false)
    }

    const onViewContent = async item => {
        const result = await fetchNotif(roleId, nowEnterprise?.enterprise, {}, item?.id)
        if (result?.status === 200) {
            history.push({
                pathname: '/affiliate/news/content',
                state: { data: result?.data }
            })
        }
    }

    const handleViewContent = async item => {
        if (item.isRead) {
            onViewContent(item)
            return
        }

        const reqBody = { isRead: true }
        const update = await modifyNotif(roleId, nowEnterprise?.enterprise, reqBody, item.id)
        if (update?.status === 200) {
            onViewContent(item)
        }
    }

    const formatDate = date => moment(date).format('YYYY-MM-DD')
    const notificationMenu = (
        <div className="news-notif-wrapper">
            <div className="notif-header">
                {ln_t('news.notification')}
                <div className="show-all" onClick={() => history.push('/affiliate/news')}>{ln_t('news.showAll')}</div>
            </div>
            {notification?.map(item => (
                <div
                    key={item.id}
                    className={`news-notif ${item.isRead ? '' : 'notif-unread'}`}
                    onClick={() => handleViewContent(item)}
                >
                    <div className="circle"><div /></div>
                    <div className="content">
                        <div className="title">{item.news?.title}</div>
                        <div className="desc">{formatDate(item.news?.updatedTime)}</div>
                    </div>
                </div>
            ))}
        </div>
    )
    useEffect(() => { 
        const enterprise = enterprises?.find(i => i.enterprise === nowEnterprise?.enterprise)
        const anyNewNotif = e => !e.isRead
        const check = enterprise?.notifications?.some(anyNewNotif) || false
        setNewNotification(check)
        setNotification(enterprise?.notifications)
    }, [userInfo])

    return (
        <div className="affiliate-header">
            <Link to={ROUTEHOME[AFFILIATE]}>
                <div className="logo-box">
                    {logo ? <img src={logo} alt={`${name}`} /> : <span>{name}</span>}
                </div>
            </Link>
            <section className="header-right d-flex">
                {width > 1020
                    ? (
                        <>
                            <div className="bell-notification">
                                <Popover content={notificationMenu} id="notification">
                                    <BellFilled onClick={() => history.push('/affiliate/news')} />
                                    {newNotification ? (<div className="red-circle" />) : ''}
                                </Popover>
                            </div>
                            <div className="main-button active mr-2" onClick={() => changeLang(langOptions[0].value)}>  
                                {langOptions[0].label}
                            </div>
                            <Dropdown
                                overlay={(
                                    <Menu className="mt-2" triggerSubMenuAction="click">
                                        {enterprises?.length > 1 
                                            ? (
                                                <Menu.Item onClick={() => history.push({ pathname: '/affiliate/switch', from: location.pathname })}>
                                                    {ln_t('util.switchEnterprise')}  
                                                </Menu.Item>
                                            ) : ''}
                                        <Menu.Item onClick={() => sendLogout()}>
                                            {ln_t('user.signOut')}  
                                        </Menu.Item>
                                    </Menu>
                                )} 
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <div className="main-button active">  
                                    {username || email?.split('@')[0]}
                                </div>
                            </Dropdown>
                        </>
                    )
                    : (
                        <>
                            <MenuOutlined
                                style={{ fontSize: '22px' }}
                                onClick={() => setVisible(true)}
                                className="mt-1 cursor-pointer"
                            />
                            <MobileMenu visible={visible} onClose={onClose} {...props} />
                        </>
                    )}
            </section>
        </div>
    )
}

function MobileMenu(props) {
    const { visible, onClose, sendLogout, userInfo, langOptions, 
        changeLang, ln_t, history, location } = props
    const { enterprises, lang } = userInfo || {}
    
    const menu = useMemo(() => (
        [
            { key: 'account', link: '/account', icon: <UserOutlined /> },
            { key: 'referralLink', link: '/links', icon: <LinkOutlined /> },
            { key: 'earning', link: '/earning', icon: <WalletOutlined /> },
            { key: 'request', link: '/request', icon: <DollarCircleOutlined /> },
            { key: 'profile', link: '/profile', icon: <ProfileOutlined /> },
        ]
    ), [])

    return (
        <Drawer
            placement="left"
            closable
            onClose={onClose}
            visible={visible}
        >
            <div className="affiliate-menu">
                <SidebarMenu {...props} />
                <ActiveEvent {...props} />
                <div className="mt-5">
                    {enterprises?.length > 1
                        ? (
                            <div className="menu-item sm" onClick={() => history.push({ pathname: '/affiliate/switch', from: location.pathname })}>
                                <span className="item-title">{ln_t('util.switchEnterprise')}</span>
                            </div>
                        ) : ''}
                    <div className="menu-item sm" onClick={() => sendLogout()}>
                        <span className="item-title">{ln_t('user.signOut')}</span>
                    </div>
                    <div className="menu-item">
                        <Select
                            options={langOptions}
                            size="small"
                            value={lang}
                            onChange={value => {
                                changeLang(value)
                                onClose()
                            }}
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default AffiliateHeader
