import React, { useEffect, useState } from 'react'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import { fetchCampaignByType, fetchRateNow } from '../../../apis/campaign'

function ActiveCamp(props) {
    const { ln_t, userInfo, setIsLoading, subTab, colTitle } = props
    const { nowEnterprise, lang } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const params = useParams({ maxResult: 1000 }, false)
    const { fetchParams, setList, setReady, ready } = params

    const defaultCols = [
        {
            title: ln_t('campaign.product'),
            dataIndex: 'product',
            fixed: 'left',
        },
        {
            title: ln_t('campaign.rateNow'),
            dataIndex: 'rateNow',
            fixed: 'left',
            className: 'rateNow',
        },
    ]    

    const [columns, setColumns] = useState([])
    const [rateNow, setRateNow] = useState([])

    const apiFunc = async () => fetchCampaignByType(enterprise, subTab, fetchParams)

    const getRateNow = async () => {
        const result = await fetchRateNow(enterprise)
        if (result?.status === 200) {
            setRateNow(result.data)
            setReady(true)
        }
    }

    const getCustomList = async (loading = true) => {
        loading && setIsLoading(true)
        const result = await apiFunc()
        if (result?.status === 200) {
            const { results } = result.data || {}
            const { products } = results[0]

            const n = products.map(product => {
                const { id, product_id } = product
                const arr = results.map(camp => {
                    const { campaignId, products: pros } = camp
                    const campMatchProduct = pros.find(pro => pro.product_id === product_id) || {}

                    return { [campaignId]: `${campMatchProduct.referralRate}%` }
                })
                const obj = Object.assign({}, ...arr)
                const now = rateNow.find(item => item.product === product_id)?.referralRate
                return {
                    productId: id,
                    product: product_id,
                    rateNow: now ? `${now}%` : '-',
                    ...obj
                }
            })

            const c = results.map(res => ({
                title: colTitle(res),
                dataIndex: res.campaignId
            }))
            setColumns([...defaultCols, ...c])
            setList(n)
        }
        loading && setIsLoading(false)
    }

    useEffect(() => {
        getRateNow()
    }, [])

    useEffect(() => {
        ready && getCustomList(false)
    }, [lang])
    
    return (
        <MainTable 
            {...props}
            className="active-campaign"
            tableName="activeCamp"
            columns={columns}
            rowKey="product"
            apiFunc={apiFunc}
            params={params}
            getCustomList={getCustomList}
            noFooter
            noPage
        />
    )
}

export default ActiveCamp
