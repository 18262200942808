import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import { ENTERPRISE, ROUTEBASE, ROUTEHOME } from '../../constants/Routing'
import './BreadCrumb.less'

const notPush = ['breadcrumb.campaigns.list',
    'breadcrumb.profile.edit',
    'breadcrumb.campaigns.detail']
const notShow = []
                   
const userType = ENTERPRISE
const homePageUrl = ROUTEHOME[userType]

const customName = []

function BreadCrumb(props) {
    const { t: ln_t } = useTranslation()
    const { location, history } = props

    const { pathname } = location || {}
    const [breadCrumb, setBreadCrumb] = useState([])

    const { state } = location || {}

    useEffect(() => {    
        const arr = []

        pathname.split('/').forEach((item, index) => {
            if (index > 1 && index < 4) {
                arr.push(item)
            }
        })
        const pathArr = pathname.split('/').filter((item, index) => (index > 0))
        const items = []
        
        for (let i = 0; i < arr.length; i++){
            const x = {}
            if (i === 0){
                x.linkUrl = `${ROUTEBASE[userType]}/${arr[i]}`
                x.name = `breadcrumb.${arr[i]}.list`
            } else {
                x.name = `breadcrumb.${arr[i - 1]}.${arr[i]}`

                if (pathArr.length > 1) {
                    const urlArr = []
                    for (let l = 0; l < pathArr.length; l++){
                        urlArr.push(`${ROUTEBASE[userType]}/${pathArr[l]}`)
                    }

                    const shortUrl = []
                    for (let l = 0; l < 2; l++){
                        shortUrl.push(`${ROUTEBASE[userType]}/${pathArr[l]}`)
                    }
 
                    if (i === 1 && arr.length !== 2) {
                        x.linkUrl = shortUrl.join('')
                    } else {
                        x.linkUrl = urlArr.join('')
                    }
                } else {
                    x.linkUrl = `${ROUTEBASE[userType]}/${arr[i - 1]}/${arr[i]}`
                }
            }
            items[i] = x
        }
        setBreadCrumb(items)
    }, [pathname])

    return (
        <Breadcrumb className="my-4">
            <Breadcrumb.Item key="breadcrumbitem-home">
                <Link to={homePageUrl}>{!pathname.includes('/campaigns') ? 'Home' : ln_t('breadcrumb.campaigns.list')}</Link>
            </Breadcrumb.Item>
            {pathname !== homePageUrl && breadCrumb.map(item => {
                const isCustom = customName.find(c => c.key === item.name)
                const { name, useLang } = isCustom || {}
                return (
                    !notShow.includes(item.name)
                        ? (
                            <Breadcrumb.Item key={`breadcrumbitem-${item.id}`}> 
                                <span
                                    className="cursor-pointer crumb-item"
                                    onClick={() => {
                                        if (!notPush.includes(item.name)) {
                                            history.push({ pathname: item.linkUrl, state: location.state })
                                        }
                                    }}
                                >{!isCustom ? ln_t(item.name) : useLang ? ln_t(`${useLang + state[name]}`) : state[name]}
                                </span>
                            </Breadcrumb.Item>
                        )
                        : ''
                )
            })}
        </Breadcrumb>
    )
}

export default withRouter(BreadCrumb)
