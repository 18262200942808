import { put, takeEvery, call, all } from 'redux-saga/effects'
import { isEqual } from 'lodash'
import { CHECKAUTH, USERPROFILE } from '../constants/ActionTypes'
import { refreshAuth } from '../apis/auth'
import { AFFILIATE, ENTERPRISE, ROUTEBRAND, ROUTEHOME, ROUTELOGIN } from '../constants/Routing'
import affiliateStore from '../store'

export function* fetchCheckAuth(props) {
    const { pathname } = window?.location || {}
    const arr = pathname.split('/')
    const userPath = arr[1]
    const isLogin = userPath === AFFILIATE 
        ? pathname.includes('/login') && arr.length >= 3
        : (pathname === ROUTELOGIN[ENTERPRISE] || pathname === `${ROUTELOGIN[ENTERPRISE]}/`)

    const homePage = userType => ROUTEHOME[userType]
    const goLoginPage = name => {
        if (!isLogin) {
            window.location.pathname = userPath === AFFILIATE 
                ? name ? `${ROUTELOGIN[AFFILIATE]}${ROUTEBRAND[name] || ''}` : ROUTELOGIN[AFFILIATE]
                : ROUTELOGIN[userPath]
        }
    }

    const { userProfile } = affiliateStore.getState() || {}
    const { role: oldRole, nowEnterprise } = userProfile || {}

    try {
        const result = yield call(refreshAuth)
        if (result?.status === 200){    
            const { userInfo } = result.data
            const { role, enterprises } = userInfo || {}

            const n = { ...userProfile, ...userInfo }
            const find = isLogin ? enterprises?.find(({ name }) => ROUTEBRAND[name] === `/${arr.at(-1)}`) 
                : enterprises?.find(item => item.enterprise === nowEnterprise?.enterprise)
            const affiliate = role === 'affiliate'
            if (affiliate && find) n.nowEnterprise = nowEnterprise

            const { isEmailVerified, nowEnterprise: newEnt } = n
            const { isAffiliateTC, isEnterpriseUserTC } = newEnt || {}
            const unqulify1 = affiliate && (isEmailVerified !== 'verified' || isAffiliateTC !== 'accepted')
            const unqulify2 = role && !affiliate && isEnterpriseUserTC !== 'accepted'
            if (unqulify1 || unqulify2) {
                yield all([
                    put({ type: CHECKAUTH.ERROR, payload: { errMessage: 'unvalid user' } }),
                    put({ type: USERPROFILE.LOGOUT, payload: { nowEnterprise } })
                ])

                return goLoginPage(nowEnterprise?.name)
            } 
            
            if (isLogin || oldRole !== role || userPath !== role) {
                window.location.pathname = homePage(role)
            }

            yield put({ type: CHECKAUTH.SUCCESS })
            if (!isEqual(userProfile, n)) yield put({ type: USERPROFILE.PATCH, payload: n })
        } else {
            const { status, data } = result || {}
            const errMessage = data || 'Server error'
            yield all([
                put({ type: CHECKAUTH.ERROR, payload: { errMessage, errStatus: status } }),
                put({ type: USERPROFILE.LOGOUT, payload: { nowEnterprise } })
            ])

            goLoginPage(nowEnterprise?.name)
        }
    } catch (err){
        const errMessage = err?.response?.data?.message || 'Server error'

        yield all([
            put({ type: CHECKAUTH.ERROR, payload: { errMessage } }),
            put({ type: USERPROFILE.LOGOUT, payload: { nowEnterprise } })
        ])

        goLoginPage(nowEnterprise?.name)
    }
}

function* mySaga() {
    yield takeEvery(CHECKAUTH.REQUEST, fetchCheckAuth)
}

export default mySaga
