import React, { useState, useEffect, useRef } from 'react'
import { ArrowRightOutlined, ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { logout } from '../../apis/auth'
import { AFFILIATE, ROUTEHOME, ROUTELOGIN } from '../../constants/Routing'
import { USERPROFILE } from '../../constants/ActionTypes'
import './Switch.less'

function Switch(props) {
    const { userInfo, dispatch, width, location, history, ln_t } = props
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    const { from: hasPrev } = location || {}

    const containerRef = useRef()

    useEffect(() => {
        const en = userInfo?.enterprises
        if (en) setList(en)
    }, [userInfo])

    const handleSwitch = async item => {
        dispatch({ type: USERPROFILE.PATCH, payload: { nowEnterprise: item } })
        history.push(ROUTEHOME[AFFILIATE])
    }

    const sendLogout = async () => {
        setLoading(true)
        const result = await logout()
        if (result?.status === 200) {
            dispatch({ type: USERPROFILE.LOGOUT, payload: { nowEnterprise: userInfo?.nowEnterprise } })
            history.push(ROUTELOGIN.affiliate)
        } else setLoading(false)
    }

    const handleScroll = direction => {
        const c = containerRef.current
        if (c) {
            const w = c.clientWidth
            const l = c.scrollLeft
            if (direction === 'right') c.scrollTo(l + w + 30, 0)
            else c.scrollTo(l - w - 30, 0)
        }
    }

    return (
        <div className="affiliate-switch">
            <h5 className="switch-title">{ln_t('util.switchEnterprise')}</h5>
            <div className="switch-wrapper">
                {list.length > 5 && width > 680 
                    ? (
                        <div className="arrow-right" onClick={() => containerRef.current.scrollLeft && handleScroll('left')}>
                            <ArrowLeftOutlined />
                        </div>
                    ) : ''}
                <div className={`switch-inner ${list.length < 5 ? 'justify-content-center' : ''}`} ref={containerRef}>
                    {list.map((item, index) => {
                        const { name, logo } = item
                        return (
                            <div className="item-wrapper" key={index}>
                                <div className="switch-item" onClick={() => handleSwitch(item)}>
                                    {logo ? <img src={logo} alt={`${name}`} /> : name}
                                </div>
                                <span>{name}</span>
                            </div>
                        )
                    })}
                </div>
                {list.length > 5 && width > 680 
                    ? (
                        <div className="arrow-right" onClick={() => handleScroll('right')}>
                            <ArrowRightOutlined />
                        </div>
                    ) : ''}
            </div>
            <div className="d-flex">
                <div 
                    className="cursor-pointer d-flex aligh-items-center" 
                    onClick={() => sendLogout()}
                >
                    <h5 className="switch-title">{ln_t('user.signOut')}</h5>
                    {loading 
                        ? <LoadingOutlined spin className="ml-3 rotate-icon" /> 
                        : ''}
                </div>
                {hasPrev && !hasPrev?.includes('/login') 
                    ? (
                        <>
                            <h5 className="mx-3 switch-title">|</h5>
                            <div
                                className="cursor-pointer"
                                onClick={() => {
                                    hasPrev ? history.goBack() : history.push(ROUTEHOME[AFFILIATE])
                                }}
                            >
                                <h5 className="switch-title">{ln_t('util.close')}</h5>
                            </div> 
                        </>
                    ) : ''}
            </div>
        </div>
    )
}

export default Switch
