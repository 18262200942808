import React from 'react'
import ProductSummary from './ProductSummary'
import ValuableAffiliate from './ValuableAffiliates'
import AffiliateProduct from './AffiliateProduct'
import OrderTranding from './OrderTrending'
import ClickTrending from './ClickTrending'
import AffiliateTrend from './AffiliateTrend'
import './Dashboard.less'

function Dashboard(props) {
    return (
        <div className="dash-container">
            <div className="inner">
                <div className="row">
                    <AffiliateProduct {...props} className="col-30" />
                    <AffiliateTrend {...props} className="col-70" />
                </div>
                <div className="row">
                    <ClickTrending {...props} className="col-50" />
                    <OrderTranding {...props} className="col-50" />
                </div>
                <ProductSummary {...props} />
                <ValuableAffiliate {...props} />
            </div>
        </div>
    )
}

export default Dashboard
