import React, { useState } from 'react'
import { DeleteFilled } from '@ant-design/icons'
import MainTable from '../../../components/Util/MainTable'
import { fetchEnterpriseProducts } from '../../../apis/product'
import useParams from '../../../hook/useParams'

function ProductManage(props) {
    const { ln_t, userInfo } = props
    const { nowEnterprise } = userInfo || {}
    const { enterpriseId } = nowEnterprise || {}
    const params = useParams({ sort: '-updatedAt' })
    const { fetchParams } = params

    const columns = [
        {
            title: ln_t('product.name'),
            dataIndex: 'product',
        },
        {
            title: ln_t('product.defaultRate'),
            dataIndex: 'referralRate',
            render: e => `${e} %`
        },
    ]

    const apiFunc = async () => fetchEnterpriseProducts(nowEnterprise?.enterprise, fetchParams)

    return (
        <MainTable 
            {...props}
            className="product-list"
            tableName="enterpriseProduct"
            columns={columns}
            rowKey="enterpriseProductId"
            apiFunc={apiFunc}
            params={params}
            useHeader
        />
    )
}

export default ProductManage
