import React from 'react'
import { withRouter } from 'react-router-dom'
import { USERPROFILE } from '../../constants/ActionTypes'
import { ENTERPRISE, ROUTEBASE, AFFILIATE, ROUTELOGIN, ROUTEBRAND } from '../../constants/Routing'
import { logout } from '../../apis/auth'
import EnterpriseHeader from '../Enterprise/Header'
import AffiliateHeader from '../Affiliate/Header'

function Header(props) {
    const { dispatch, resetDropDown, setIsLoading, history, userInfo } = props
    const path = history.location.pathname    
    const isBrand = path.startsWith(ROUTEBASE[ENTERPRISE])
    const { nowEnterprise } = userInfo || {}

    const sendLogout = async () => {
        resetDropDown()
        setIsLoading(true)
        const result = await logout()
        if (result?.status === 200) {
            const userType = isBrand ? ENTERPRISE : AFFILIATE
            const route = isBrand ? ROUTELOGIN[userType] : `${ROUTELOGIN[userType]}${ROUTEBRAND[nowEnterprise.name] || ''}`
            dispatch({ type: USERPROFILE.LOGOUT, payload: { nowEnterprise } })
            setIsLoading(false)

            history.push(route)
        } else setIsLoading(false)
    }
    
    const p = {
        ...props,
        sendLogout,
    }

    return (
        isBrand 
            ? <EnterpriseHeader {...p} /> 
            : <AffiliateHeader {...p} />
    )
}

export default withRouter(Header)
