import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Spin, message } from 'antd'
import { useParams, withRouter } from 'react-router-dom'
import { AFFILIATE, ROUTELOGIN } from '../../../constants/Routing'
import { newAffiliate } from '../../../apis/affiliate'
import useCheckEnterprise from '../../../hook/useCheckEnterprise'
import Logo from '../../../components/Affiliate/Logo'
import '../../Login/Login.less'

const marginTop = '28px'

function CreateUser(props) {
    const { location, history, ln_t, langOptions, changeLang, passwordFormat } = props

    const { enterpriseName } = useParams()
    const enterpriseId = useCheckEnterprise(enterpriseName, history)

    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm()
    const { pathname, state } = location || {}
    const { email } = state || {}

    const replacePath = string => pathname.replace(/newUser/, string)
    const goLogin = () => history.push(replacePath('login'))

    const onFinish = async fieldsValue => {
        setLoading(true)
        const { name, password } = fieldsValue
        const postData = {
            email,
            name,
            password: window.btoa(password),
            enterpriseId,
        }

        const result = await newAffiliate(postData)
        if (result?.status === 201) {
            const { data } = result
            history.push({ pathname: replacePath('otpVerify'), state: { ...state, affiliate: data } }) 
        } else message.info(result?.data.message || ln_t('message.error.serverError'))

        setLoading(false)
    }

    useEffect(() => {
        if (!email) return history.push(ROUTELOGIN[AFFILIATE])
    }, [])

    return (
        <main className="login-container">
            <section className="login-inner">
                <div 
                    onClick={() => changeLang(langOptions[0].value)}
                    className="lang-btn"
                >
                    {langOptions[0].label}
                </div>
                <div className="logo-box cursor-pointer" onClick={() => goLogin()}>
                    <Logo {...props} />
                </div>
                <div className="title-text">{ln_t('login.createUser')}</div>
                <Spin spinning={loading}>  
                    <Form
                        form={form}
                        size="large"
                        onFinish={onFinish}
                        hideRequiredMark
                    >
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: ln_t('user.validation.name') }]}
                        >
                            <Input 
                                autoComplete="off" 
                                placeholder={ln_t('user.form.name')} 
                            />
                        </Form.Item>
                        <Form.Item 
                            style={{ marginTop }}
                            name="password" 
                            rules={[
                                { required: true, message: ln_t('user.validation.newPassword') }, 
                                { pattern: passwordFormat, message: ln_t('user.validation.matchPattern') }
                            ]}
                        >
                            <Input.Password
                                placeholder={ln_t('user.form.newPassword')} 
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop }}
                            name="confirm"
                            rules={[{ required: true, message: ln_t('user.validation.confirmAgain') }, ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(ln_t('message.error.pleaseMatchNewPass'))
                                }
                            })]}
                        >
                            <Input.Password
                                placeholder={ln_t('user.form.confirmAgain')} 
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <div className="rule-box px-2" style={{ marginTop }}>
                            <span>{ln_t('user.password.length')}<br />
                                {ln_t('user.password.format')}
                            </span>
                        </div>

                        <div className="login-btn-container">
                            <Button type="button" htmlType="submit">{ln_t('util.next')}</Button>
                        </div>
                    </Form>
                </Spin>
            </section>
        </main>
    )
}

export default withRouter(CreateUser)
