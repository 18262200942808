import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import App from './pages/App'
import store, { persistor, history } from './store'
import { ROUTELOGIN } from './constants/Routing'
import * as serviceWorker from './serviceWorker'
import 'moment/locale/zh-tw'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.less'
import './index.css'
import './i18n'

function Root() {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Redirect exact from="/" to={ROUTELOGIN.affiliate} />
                        <Route
                            path="/"
                            render={props => (
                                <App {...props} isLoading={isLoading} setIsLoading={setIsLoading} />
                            )}
                        />
                    </Switch>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    )
}

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
