import axios from 'axios'
import { MethodType, TIMEOUT } from '../constants/MethodType'
import { CHECKAUTH, HANDLEERROR } from '../constants/ActionTypes'
import store, { history } from '../store'
import { AFFILIATE, ENTERPRISE, ROUTEBRAND, ROUTELOGIN } from '../constants/Routing'

const FRONTEND_API_URL = process.env.REACT_APP_FRONTEND_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL

// API呼叫統一走這裡
export const request = async (type = 1, api = '', method = MethodType.GET, params = {}, catchError = true, useParams = false, checkAgain = false) => {
    const path = history.location.pathname
    const isValidate = api.includes('/refresh/')
    const arr = path.split('/')
    const userType = arr[1]
    const isLogin = userType === AFFILIATE 
        ? path.includes('/login') && arr.length >= 3
        : (path === ROUTELOGIN[ENTERPRISE] || path === `${ROUTELOGIN[ENTERPRISE]}/`)
    const { userProfile } = store.getState() || {}
    const { nowEnterprise } = userProfile || {}

    const conf = {
        timeout: TIMEOUT,
        headers: {
            'Content-Type': 'application/json',
        },
    }
  
    const apiRoot = (type === 1) ? FRONTEND_API_URL : BACKEND_API_URL
    const data = (method === 'GET') || useParams ? 'params' : 'data'

    try {
        if (Array.isArray(api)){
            // 一次送多筆
            const jobs = []
            api.forEach(a => {
                jobs.push(axios({
                    url: `${apiRoot}${a}`,
                    method,
                    [data]: params,
                    ...conf,
                }))
            })
            const results = await Promise.all(jobs)
            return results
        }
        const result = await axios({
            url: `${apiRoot}${api}`,
            method,
            [data]: params,
            ...conf,
        })
        // console.log(`${apiRoot}${api}`)
        if (checkAgain) {
            store.dispatch({ type: CHECKAUTH.REQUEST })
        }
        return result
    } catch (error){
        if (catchError) { 
            const { message, errorCode } = error?.response?.data || {}      
            let errMsg = errorCode ? `errorCode.${errorCode}` : message || error?.response?.data
            const errStatus = error?.response?.status
            const path = history?.location?.pathname
            const expireMsg = 'errorCode.expired'

            if (errStatus === 400) {
                if (errMsg?.token?.[0] === 'This field may not be blank.') {
                    errMsg = expireMsg
                }
            }
            if (errStatus === 404) errMsg = `errorCode.404 ${api}`
            if (errStatus === 500) errMsg = `errorCode.500 ${api}`
            if (errStatus === 502) errMsg = 'errorCode.502'

            store.dispatch({ type: HANDLEERROR.CATCH,
                payload: {
                    errStatus,
                    errMessage: errMsg ? JSON.stringify(errMsg).replace(/["}\[\]{]/gi, '') : 'Server Error'
                } 
            })
        
            const status = [401]
            if ((status.includes(errStatus) || errMsg === expireMsg) && !isLogin && !isValidate) {
                window.location.pathname = userType === AFFILIATE 
                    ? nowEnterprise?.name ? `${ROUTELOGIN[AFFILIATE]}${ROUTEBRAND[nowEnterprise?.name] || ''}` : ROUTELOGIN[AFFILIATE]
                    : ROUTELOGIN[userType]
            }
        }
        return error.response
    }
}
