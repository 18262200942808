import { compose, createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from '../reducers'
import rootSaga from '../sagas'

// debug
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const storageConfig = {
    key: 'root', // 必須
    storage, // 緩存在哪裡
    whitelist: ['userProfile'] // reducer裡會儲存的,其餘不儲存
}

// create saga middleware
const sagaMiddleware = createSagaMiddleware()

// const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
// history
export const history = createBrowserHistory()
// 有緩存機制的store
const myPersistReducer = persistReducer(storageConfig, rootReducer(history))

const store = createStore(myPersistReducer, composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)))
// start saga
sagaMiddleware.run(rootSaga)

export default store
export const persistor = persistStore(store)
