import React from 'react'
import '../Main.less'

function Terms(props) {
    const { size } = props
    return (
        <section className={`rules-wrapper ${size}`}>
            <h5 className="mb-4 text-center">隱私權政策</h5>
            <ol className="parent">
                <li className="level-1">隱私權保護政策的適用範圍：
                    <p>本公司 （以下稱 ezTravel易遊網）之網站：包括 https://www.ezTravel易遊網.com.tw、ezTravel易遊網 App 及其第三方營運商透過 ezTravel易遊網 所提供的服務網站及應用軟體等。</p>
                    <ol>
                        <li>隱私權保護政策內容，包含 ezTravel易遊網 如何處理在用戶使用網站服務時收集到的身份識別資料，也包括第三方（包括合作、協力及委外廠商等） 與 ezTravel易遊網 合作時分享的任何身份識別資料。</li>
                        <li>隱私權保護政策不適用於 ezTravel易遊網 以外的公司或網站群，與非 ezTravel易遊網 所僱用或管理人員。例如您透過非 EzTravel易遊網 所提供的相關服務，這些廠商也可能蒐集您個人的資料。</li>
                        <li>對於您主動提供的個人資訊，這些非 ezTravel易遊網 相關的資訊服務廠商或連結網站有其個別的隱私權保護政策，其資料處理措施不適用於 ezTravel易遊網 隱私權保護政策。</li>
                        <li>若您個人在 ezTravel易遊網 所提供的資訊服務中（例如：社群媒體上的聊天室、討論區或留言板）中任意公開個人資料的行為，在非經加密的保護下，亦不適用於 ezTravel易遊網 隱私權保護政策。</li>
                    </ol>
                </li>
                <li className="level-1">個人資料的蒐集、處理及利用方式：
                    <ol>
                        <li>
                            個人資料蒐集目的：經營旅遊產品相關業務與資料庫管理、客戶管理與服務、消費者保護事務、統計調查與分析、
                            行銷業務（包含關係企業、合作廠商、共同行銷業務）、信用卡或轉帳卡之管理、非公務機關依法令規定或監理需要所進行之
                            個人資料蒐集處理及利用、帳務管理、會計及相關服務、財稅行政、配合財政部電子發票整合服務、其他財政服務、提供電子發票加值服務、
                            各項優惠措施或活動訊息、辦理公司內部控制及稽核、辦理市場調查、使用服務相關調查、統計、分析，以作為改善及提升本公司服務品質之參考、
                            或將去識別化之「訂單資料」提供予第三方作為行為分析、或提供予學術機構作為非營利目的之用；其他經營合於營業登記項目或組織章程所定之業務、
                            其他契約、類似契約或法律關係管理之事務或業務、其他諮詢與顧問服務等。
                        </li>
                        <li>
                            個人資料類別：包括但不限於客戶本人（或同行人）之姓名、年齡、性別、出生年月日、地址、聯絡電話、電子郵件信箱、身分證、銀行帳號、戶名、通訊資料、付款相關資訊、
                            其他足資辨識身份之證明文件、金融卡、信用卡卡號、電子發票號碼及消費時間、消費明細等發票資料、使用服務行為資料、其他（往來電子郵件、
                            網站留言、系統自動紀錄之軌跡資訊）等。
                        </li>
                        <li>個人資料利用期間：本網站會員有效期間、為蒐集之特定目的存續期間、依相關法令所規定（例如商業會計法等）、因執行業務所必須之保存期間或依個別契約就資料之保存所定之保存年限。</li>
                        <li>個人資料利用地區：本公司執行業務對應地區、伺服器主機所在地。</li>
                        <li>個人資料利用對象：本公司、本公司關係企業及本公司委外及協力廠商（例如:提供物流、金流或活動贈品 、展示品之廠商）；如為本公司與其他廠商共同蒐集者，將於該共同蒐集之活動中載明。</li>
                        <li>個人資料利用方式：依蒐集目的範圍及本隱私權政策進行利用。</li>
                        <li>
                            行使個人資料權利方式：依個人資料保護法（以下稱個資法）第 3 條規定，您對您的個人資料享有查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除之權利，
                            但有個資法第 10 條但書之情形者不在此限。若您未提供正確之個人資料，ezTravel易遊網 可能 將無法為您提供特定目的之相關業務。個人資料選填說明：本公司於蒐集個人資料時，相關網頁或文件載明為選填者，
                            僅為提供您使用本網站更多體驗，不影響您使用本網站之權益。行使個人資料權利方式：依個人資料保護法（以下稱個資法）第 3 條規定，您對您的個人資料享有查詢或請求閱覽、
                            請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除之權利，但有個資法第 10 條但書之情形者不在此限。若您未提供正確之個人資料，ezTravel易遊網 可能 將無法為您提供特定目的之相關業務。
                            個人資料選填說明：本公司於蒐集個人資料時，相關網頁或文件載明為選填者，僅為提供您使用本網站更多體驗，不影響您使用本網站之權益。
                        </li>
                        <li>
                            若您使用您的社群媒體帳戶（下稱「社群媒體帳戶」）來註冊成為本網站的使用者，或將您的帳戶與您的社群媒體帳戶連結，或使用任何 ezTravel易遊網 的社群功能，
                            我們可能會存取您在該社群媒體帳戶提供者之政策下所自願提供予該社群媒體帳戶提供者的資訊，我們將持續依照本隱私權政策來管理及使用相關個人資料。EzTravel易遊網 在您註冊帳號、
                            使用 ezTravel易遊網 相關產品、服務、活動或贈獎時，會收集您的個人識別資料，ezTravel易遊網 也可以從商業夥伴處取得個人資料。
                        </li>
                        <li>為了保障您的權益，客戶在第一次交易確認完成後，即會自動成為會員。</li>
                        <li>
                            其他除了上述，ezTravel易遊網 會保留您在上網瀏覽或查詢時，伺服器自行產生的相關記錄，包括您使用連線設備的 IP 位址、使用時間、使用的瀏覽器、
                            瀏覽及點選資料紀錄等。EzTravel易遊網 會對個別連線者的瀏覽器予以標示，歸納使用者瀏覽器在 ezTravel易遊網 所瀏覽的網頁。請您注意，與 ezTravel易遊網 連結的網站，也可能蒐集您個人的資料。
                        </li>
                        <li>
                            對於您主動提供的個人資訊，這些廣告廠商或連結網站有其個別的私權保護政策，其資料處理措施不適用 ezTravel易遊網 隱私權保護政策，
                            ezTravel易遊網 不負任何連帶責任。EzTravel易遊網 將在事前或註冊登入取得您的同意後，傳送商業性資料或電子郵件給您。 EzTravel易遊網 除了在該資料或電子郵件上註明是由 ezTravel易遊網 發送，也會在本網站、
                            電子郵件或其他方式提供您能隨時停止接收這些資料或電子郵件的方法及說明。
                        </li>
                    </ol>
                </li>
                <li className="level-1">Cookie之使用：
                    <ol>
                        <li>
                            為了提供您較佳的使用者體驗，經由瀏覽器寫入一些資訊，Cookie 包含：
                            <p>Essential Cookies – 網站運作所需要的 Cookie，使用者可瀏覽 ezTravel易遊網 並使用網站服務。</p>
                            <p>Functional Cookies – 主要用於記住使用者如何使用 ezTravel易遊網 的設定（包含輸入的資訊或選擇項目），以提供使用者個人化的功能服務。</p>
                            <p>Performance Cookie – 透過紀錄使用者操作經驗，幫助我們優化網站效能，並可能會藉由第三方技術，追蹤及分析使用資訊，以便我們可以了解使用者如何使用網站，或提供使用者遇到的錯誤訊息，讓我們可修正問題。</p>
                            <p>Advertising Cookies - 依據使用者點擊的內容，提供您感興趣的廣告內容。使用者可以在ezTravel易遊網所經營或其他網站看到我們所提供的廣告內容。</p>
                        </li>
                        <li>
                            若您選擇不同意 Cookies 的存取，您可以透過瀏覽器的設定選擇拒絕接受 Cookies ，但在您拒絕 Cookie 存取後，
                            您可能無法正常使用本網站的重要功能或瀏覽某些內容，而被要求重新輸入您的登入資料。 為使於日後的辨識，當您使用本網站服務時，
                            本公司可能會在您的電腦上設定與存取 Cookie ，蒐集有關您對網站的使用情況與可能的其他線上活動，以及不同網站與應用程式之資訊。
                            此類第三方直接從您的網路瀏覽器蒐集資料，以及該第三方就此類資料之處理，須以其隱私權政策為準。 透過執行這些以興趣為基礎的廣告選擇，您仍可能會收到廣告，但您有做取消設定，
                            網路將不會再發送符合您興趣的廣告。您也需要知道登出會員帳號或終止會員並不代表退出客製化廣告。
                        </li>
                    </ol>
                </li>
                <li className="level-1">資料之保護：
                    <ol>
                        <li>EzTravel易遊網 網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，僅有經授權之員工為提供您相關產品或服務之需求範圍，始得接觸您的個人資料且對於您的個人資料為有限之接觸，如有違反者，將會受到相關的法律處分。</li>
                        <li>
                            工為提供您相關產品或服務之需求範圍，始得接觸您的個人資料且對於您的個人資料為有限之接觸，如有違反者，將會受到相關的法律處分。
                        </li>
                        <li>
                            在傳輸過程中我們採用傳輸層安全性協定（Transport Layer Security；TLS）加密機制確保資料傳輸安全，並透過防火牆機制防止不法入侵，避免您的個人資料遭到非法存取。
                        </li>
                        <li>
                            本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
                        </li>
                    </ol>
                </li>
                <li className="level-1">網站對外的相關連結：
                    <p>
                        EzTravel易遊網 網站的網頁提供其他網站的網路連結，您也可經由 ezTravel易遊網 網站所提供的連結，點選進入其他網站，
                        惟該連結網站不適用ezTravel易遊網網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
                    </p>
                </li>
                <li className="level-1">如何存取或修改您的資料：
                    <ol>
                        <li>
                            您可以隨時登入您的會員修改基本資料或您可提出可茲確認之身份證明文件，於客服時間：平日 （週一 ~ 週五）：09:00 ~ 22:00；假日 
                            （週六/日及連續假日）：12:00 ~ 18:00，撥打客戶服務電話 +886-3-9321090（台灣市話），請求ezTravel易遊網對於所保留之會員個人資料進行以下動作：
                            <p>查詢或請求閱覽</p>
                            <p>製給複製本。</p>
                            <p>補充或更正。</p>
                            <p>停止蒐集、處理或利用。</p>
                            <p>刪除。</p>
                        </li>
                        <li>若委託他人代為申請者，請另外提出可茲確認的身分證明，以備查核。</li>
                        <li>若申請刪除其個人資料時，即視為終止會員帳戶，並停止提供 ezTravel易遊網 一切會員服務，且日後將導致您對相關會員資料無法事後查核、比對或辦理證明。</li>
                        <li>若申請停止蒐集、處理或利用其個人資料，如您於本公司、ezTravel易遊網網站尚有業務持續往來者，您知悉並同意本公司將俟所有往來業務結束後，本公司始停止對於會員個人資料蒐集、處理或利用。</li>
                        <li>您亦可在 ezTravel易遊網 的「會員服務」網頁登入您的帳號及密碼，線上即時查閱您的個人資料檔案。若會員委託他人代為登入者，會員將負完全的責任。</li>
                    </ol>
                </li>
                <li className="level-1">未成年人保護：
                    <p>
                        EzTravel易遊網 網站並非特別為成年人/兒童設計，未成年人使用 ezTravel易遊網 本網站時，若同意 ezTravel易遊網 網站蒐集、
                        利用其個人資訊時，應在法定代理人或監護人之同意下為之。法定代理人或監護人得隨時請求本公司停止特定帳號及其相關之個人資料之蒐集、處理及利用行為。
                    </p>
                </li>
                <li className="level-1">隱私權保護政策之修訂：
                    <ol>
                        <li>本隱私權政策將因應需求隨時進行修改，如有修改，ezTravel易遊網 將以您所提供之電子郵件或簡訊通知您相關之重大變更，並於 ezTravel易遊網 網站公告，以便您隨時至 ezTravel易遊網 網站閱覽檢視。</li>
                        <li>若您不同意該等變更或修改，請停止繼續使用 ezTravel易遊網 網站及服務，並依本隱私權政策規定通知本公司停止蒐集、處理及利用您的個人資料。</li>
                    </ol>
                </li>
                <li className="level-1">聯絡我們：
                    <p>
                        若您不同意上述聲明內容，請勿於 ezTravel易遊網 網站內提供您的個人資料，另 ezTravel易遊網 網站多數服務無須提供您的資料也可進行瀏覽，
                        並不影響您資訊瀏覽的權益，若您對本隱私權保護政策有任何問題，請直接與本公司聯繫。請使用聯盟行銷後台發信聯絡我們。
                    </p>
                </li>
            </ol>
        </section>
    )
}

export default Terms
