import { useState, useEffect } from 'react'
  
const useDetectReload = (callback = () => console.log('...'), warningText = 'Changes you made may not be saved.') => {
    const [blockNavigation, setBlockNavigation] = useState(false)

    useEffect(() => {
        if (typeof window === 'undefined') return
        
        const handleWindowClose = e => {
            e.preventDefault()
            console.log('handleWindowClose', blockNavigation)
            // eslint-disable-next-line no-return-assign
            return (e.returnValue = blockNavigation ? warningText : '')
        }

        if (blockNavigation) {
            window.addEventListener('beforeunload', handleWindowClose)
        }
  
        return () => {
            window.removeEventListener('beforeunload', handleWindowClose)
            callback()
        }
    }, [window, blockNavigation])

    return { blockNavigation, setBlockNavigation }
}

export default useDetectReload
