import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const fetchNews = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/news/`, MethodType.GET, params)

export const fetchSingleNews = async (enterpriseId, id) => api.request(1, `/api/v3/enterprise/${enterpriseId}/news/${id}`, MethodType.GET)

export const addNews = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/news/`, MethodType.POST, params)

export const modNews = async (enterpriseId, id, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/news/${id}/`, MethodType.PATCH, params)

export const fetchNewsCategory = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/newsCategory/`, MethodType.GET, params)

export const addNewsCategory = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/newsCategory/`, MethodType.POST, params)

// eslint-disable-next-line max-len
export const fetchNotif = async (affiliateId, enterpriseId, params, id) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/news/${id || ''}`, MethodType.GET, params, true, false, true)

export const modifyNotif = async (affiliateId, enterpriseId, params, id) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/news/${id}/`, MethodType.PATCH, params)
