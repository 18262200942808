import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Modal, Tabs } from 'antd'
import { DeleteOutlined, EditFilled } from '@ant-design/icons'
import UpcomingCamp from './UpcomingCamp'
import ActiveCamp from './ActiveCamp'
import CampHistory from './CampHistory'
import './tab.less'
import { deleteCampaign } from '../../../apis/campaign'

const { TabPane } = Tabs

function Campaign(props) {
    const { history, ln_t, location, userInfo, modalConf } = props
    const { nowEnterprise } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const { state } = location || {}
    const { subTab: tab } = state || {}

    const [subTab, setSubTab] = useState()

    const onTabChange = key => {
        setSubTab(key)
        history.replace({ state: { ...state, subTab: key } })
    }

    const handleDel = (item, deleteCallback) => {
        const { campaignId, name } = item
        Modal.confirm({
            ...modalConf,
            title: `${ln_t('util.confirmDelete')} ${name}？`,
            onOk: async () => {
                const result = await deleteCampaign(enterprise, campaignId) 
                if (result?.status === 200 && deleteCallback) deleteCallback()
            }
        })
    }

    const colTitle = (res, editable) => {
        const { name, startDate, endDate } = res
        const { deleteCallback, handleEdit } = editable || {}
        if (name === 'default') return ln_t('campaign.default')
        const format = time => moment(time).format('YYYY-MM-DD')
        return (
            <div className="d-flex fd-col align-items-enter">
                <span className="d-flex align-items-center justify-content-center">
                    {name}
                    {editable 
                        ? (
                            <>
                                <EditFilled className="mx-2 cursor-pointer" onClick={() => handleEdit && handleEdit()} />
                                <DeleteOutlined className="cursor-pointer" onClick={() => handleDel(res, deleteCallback)} />
                            </>
                        ) : ''}
                </span>
                <span style={{ fontSize: '12px' }}>{format(startDate)} - {format(endDate)}</span>
            </div>
        )
    }

    const childProps = {
        ...props,
        subTab,
        colTitle
    }

    const tabMenu = [
        {
            title: ln_t('campaign.tabMenu.activeCampaign'), 
            key: 'active', 
            render: <ActiveCamp {...childProps} />
        },
        {
            title: ln_t('campaign.tabMenu.upComingCampaign'), 
            key: 'upcoming', 
            render: <UpcomingCamp {...childProps} />
        },
        {
            title: ln_t('campaign.tabMenu.campaignHistory'), 
            key: 'history', 
            render: <CampHistory {...childProps} />
        },
    ]

    useEffect(() => {
        setSubTab(tab || tabMenu[0].key)
    }, [])

    return (
        <div className="card-container">
            <Tabs activeKey={subTab} onChange={onTabChange} type="card">
                {tabMenu.map(item => {
                    const { key, title, render } = item || {}
                    return (
                        <TabPane key={key} tab={title}>
                            {key === subTab ? render : ''}
                        </TabPane>
                    )
                })}
            </Tabs>
        </div>
    )
}

export default Campaign
