import React, { useState, useEffect } from 'react'
import { fetchEnterpriseUserProfile } from '../../../apis/user'
import './UserProfile.less'
import { ROUTEBASE, ENTERPRISE } from '../../../constants/Routing'

function UserProfile(props) {
    const { userInfo, setIsLoading, history, ln_t } = props
    const [data, setData] = useState({})

    const getUserProfile = async (enterpriseId, userId) => {
        setIsLoading(true)
        const result = await fetchEnterpriseUserProfile(enterpriseId, userId)
        if (result?.status === 200) {
            setData(result.data)
        }
        setIsLoading(false)
    }

    const handleMod = field => {
        const n = {
            pathname: `${ROUTEBASE[ENTERPRISE]}/user-profile/mod-${field}`,
            state: { name: data.name }
        }
        history.push(n)
    }

    useEffect(() => {
        console.log('...')
        const { roleId, nowEnterprise } = userInfo || {}
        if (roleId && nowEnterprise) getUserProfile(nowEnterprise?.enterprise, roleId)
    }, [])

    return (
        <div className="profile-container">
            {!Object.keys(data).length ? ''
                : (
                    <div className="profile-inner">
                        <div className="d-flex align-items-end mb-2">
                            <h3 className="mb-0">{data?.name || '-'}</h3>
                            {userInfo.isAdmin ? <span className="ml-2">({ln_t('util.role.true')})</span> : ''}
                        </div>
                        <h5>{data?.email || '-'}</h5>
                        <h6 className="text-blue">
                            <span className="cursor-pointer" onClick={() => handleMod('name')}>{ln_t('user.changeName')}</span>
                            <span className="mx-2">|</span>
                            <span className="cursor-pointer" onClick={() => handleMod('password')}>{ln_t('user.changePassword')}</span>
                        </h6>
                    </div>
                )}
        </div>
    )
}

export default UserProfile
