import React, { useEffect, useState } from 'react'
import { Form, DatePicker } from 'antd'
import moment from 'moment'
import Selector from '../../../components/Util/Selector'

function SearchForm(props) {
    const [form] = Form.useForm()
    const { handleFetchParams, ln_t, className } = props
    const [searchType, setSearchType] = useState()

    const typeOptions = [
        { value: 'orderDate', label: ln_t('order.orderDate') },
        { value: 'expectedToConfirm', label: ln_t('order.expectedToPay') },
    ]

    const statusOptions = [
        { value: 'PAID', label: ln_t('util.orderStatus.PAID') },
        { value: 'CONFIRMED', label: ln_t('util.orderStatus.CONFIRMED') },
        { value: 'CANCELED', label: ln_t('util.orderStatus.CANCELED') },
    ]

    const onFinish = fieldValues => {
        const { orderStatus } = fieldValues
        let n = {}
        const format = time => time.format('YYYY-MM-DD')
        if (orderStatus) n = { ...n, orderStatus }
        if (fieldValues[searchType]?.length === 2) {
            const [dateForm, dateTo] = fieldValues[searchType]
            n = { 
                ...n, 
                [`${searchType}_after`]: format(dateForm), 
                [`${searchType}_before`]: format(dateTo)
            }
        }
        handleFetchParams('init', n)
    }

    const handleTypeChange = value => {
        setSearchType(value)
        const n = Object.assign({}, ...typeOptions.map(opt => ({ [opt.value]: undefined }))) 
        form.setFieldsValue(n)
    }

    useEffect(() => { setSearchType(typeOptions[0].value) }, [])

    return (
        <Form 
            form={form}
            className={`d-flex flex-wrap round-selector align-items-start ${className}`}
            onFinish={onFinish}
        >
            <Selector 
                options={typeOptions} 
                value={searchType} 
                onChange={handleTypeChange} 
                className="mb-1 mr-1"
                showSearch={false}
                style={{ width: '140px' }}
            />
            <Form.Item noStyle name={searchType}>
                <DatePicker.RangePicker 
                    ranges={{
                        [ln_t('date.oneWeek')]: [moment().subtract(1, 'week'), moment()],
                        [ln_t('date.oneMonth')]: [moment().subtract(1, 'month'), moment()],
                        // '3 months': [moment().subtract(3, 'months'), moment()],
                        // '6 months': [moment().subtract(6, 'months'), moment()],
                        // '1 year': [moment().subtract(364, 'days'), moment()],
                    }} 
                    className="mb-1 mr-2"
                />
            </Form.Item>
            <Form.Item label={ln_t('order.orderStatus')} name="orderStatus" className="mb-1 mr-2">
                <Selector 
                    options={statusOptions} 
                    placeholder={`${ln_t('util.search')}...`} 
                    allowClear 
                    style={{ width: '140px' }} 
                />
            </Form.Item>
            <button className="main-button mb-2" type="submit">{ln_t('util.search')}</button>
        </Form>
    )
}

export default SearchForm
