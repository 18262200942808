import React from 'react'

const styleToHTML = style => {
    if (style?.includes('rgb')) {
        return <span style={{ color: style }} />
    }
}

const entityToHTML = (entity, originalText) => {
    const { data, type } = entity || {}
    const iframeWrapperStyle = (alignment, size = { height: 200, width: 350 }) => ({
        margin: alignment === 'center' ? '0 auto' : '0',
        width: size.width - 10,
        height: size.height - 10,
    })

    const scale = (size = { height: 200, width: 350 }) => size.width / size.height
    if (type === 'LINK') {
        return <a href={data.url} target="_blank" rel="noreferrer">{originalText}</a>
    }
    if (type === 'IMAGE') {
        const { src, title, size, alignment } = data
        return (
            <div 
                style={{ 
                    ...iframeWrapperStyle(alignment, size),
                }} 
                data-scale={scale(size)}
                className="flex-col-center iframe-wrapper"
            >
                <img 
                    width="100%"
                    src={src} 
                    alt={title}
                    data-size={JSON.stringify(size)}
                    data-alignment={alignment}
                />
                <span className="alt-text">{title}</span>
            </div>
        )
    }
    if (type === 'draft-js-iframely-plugin-embed') {
        const { html, size, title, alignment } = data
        return (
            <div 
                style={{ 
                    ...iframeWrapperStyle(alignment, size),
                }} 
                data-scale={scale(size)}
                className="flex-col-center iframe-wrapper"
            >
                <iframe 
                    width="100%"
                    height="100%"
                    src={html} 
                    data-size={JSON.stringify(size)}
                    data-alignment={alignment}
                    title={title}
                />
                <span className="alt-text">{title}</span>
            </div>
        )
    }
    return originalText
}

const blockToHTML = block => {
    if (block.type === 'unstyled' && !block.text) {
        return <div className="break" />
    }
    if (block.type === 'atomic') {
        block.text = '' 
    }
    if (block.type === 'draft-js-iframely-plugin-embedder') {
        return <input placeholder="please enter iframe src url" />
    }
}

const htmlToStyle = (nodeName, node, currentStyle) => {
    if (nodeName === 'span' && node.style.color) {
        return currentStyle.add(node.style.color)
    } 
    return currentStyle
}

const htmlToEntity = (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
        return createEntity(
            'LINK',
            'MUTABLE',
            {
                url: node.href
            }
        )
    }
    const { alt, src, dataset, title } = node || {}
    const { size = '{"width":350,"height":200}', alignment } = dataset || {}
    if (nodeName === 'img') {
        return createEntity(
            'IMAGE',
            'IMMUTABLE',
            {
                title: alt,
                src,
                size: JSON.parse(size),
                alignment,
            }
        )
    }
    if (nodeName === 'iframe') {
        return createEntity(
            'draft-js-iframely-plugin-embed',
            'IMMUTABLE',
            {
                title,
                html: src,
                size: JSON.parse(size),
                alignment,
            }
        )
    }
}

const htmlToBlock = (nodeName, node) => {
    if (node.classList.contains('iframe-wrapper')) {
        return {
            type: 'atomic',
            text: '',
            data: {}
        }
    }
}
export const convertPlugins = {
    toHtml: {
        styleToHTML,
        entityToHTML,
        blockToHTML
    },
    fromHtml: {
        htmlToStyle,
        htmlToEntity,
        htmlToBlock
    }
}

// console.log(convertToHTML(convertPlugins)(contentState))
