import React, { useState, useEffect } from 'react'
import { LinkOutlined, UserOutlined, DollarCircleOutlined, WalletOutlined, ProfileOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { ROUTEBASE, AFFILIATE } from '../../constants/Routing'
import { fetchSummary } from '../../apis/affiliateWeb'
import './InfoCard.less'
import ActiveEvent from './ActiveEvent'
import SidebarMenu from './Menu'

const formatNumber = number => number.toLocaleString()

function InfoCard(props) {
    const { userInfo, ln_t, location, width } = props
    const { roleId, nowEnterprise, username, email } = userInfo || {}
    const { pathname } = location || {}

    const [summary, setSummary] = useState({})

    const menu = [
        { key: 'account', link: '/account', icon: <UserOutlined /> },
        { key: 'referralLink', link: '/links', icon: <LinkOutlined /> },
        { key: 'earning', link: '/earning', icon: <WalletOutlined /> },
        { key: 'request', link: '/request', icon: <DollarCircleOutlined /> },
        { key: 'profile', link: '/profile', icon: <ProfileOutlined /> },
    ]

    const getSummary = async () => {
        const result = await fetchSummary(roleId, nowEnterprise?.enterprise)
        if (result?.status === 200) {
            setSummary(result?.data?.[0] || {})
        }
    }

    useEffect(() => {
        if (roleId && nowEnterprise) getSummary()
    }, [nowEnterprise]) 

    return (
        <section className="affiliate-main">
            <div className="affiliate-card">
                <div className="card-inner">
                    <div className="card-top">
                        <span className="card-name overflow-dot">{username || email?.split('@')[0]}</span>
                        <div className="total-earning">
                            <span>{ln_t('affiliate.summary.total')}</span>
                            <span className="title-big">NT${formatNumber(Math.round(summary.total * 100) / 100 || 0)}</span>
                        </div>
                    </div>
                    <div className="card-bottom">
                        <div className="d-flex justify-content-between">
                            <span>{ln_t('affiliate.summary.available')}</span>
                            <span>NT${formatNumber(Math.round(summary.available_earning * 100) / 100 || 0)}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>{ln_t('affiliate.summary.pending')}</span>
                            <span>NT${formatNumber(Math.round(summary.pending_earning * 100) / 100 || 0)}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>{ln_t('affiliate.summary.withdrawn')}</span>
                            <span>NT${formatNumber(Math.round(summary.withdrawn_earning * 100) / 100 || 0)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <SidebarMenu {...props} />
            {width > 1020 ? <ActiveEvent {...props} /> : ''}
        </section>
    )
}

export default InfoCard
