import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Modal, Button } from 'antd'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import { fetchOrderDetails } from '../../../apis/order'

const formatAccount = account => {
    const l = account.length
    return account.split('').map((item, index) => {
        if (((index + 1) % 4) || index + 1 === l) return item
        return `${item} - `
    }).join('')
}

function OrderDetailTable(props) {
    const { ln_t, userInfo, isModalVisible, setModalVisible, changeModal, dataParam, confirmedDate, showBack } = props
    const { nowEnterprise } = userInfo || {}

    const params = useParams({ sort: '-confirmedDate', ...dataParam })

    const { fetchParams, handleFetchParams } = params

    const columns = [
        {
            title: ln_t('affiliate.id'),
            dataIndex: 'ouid',
        },
        {
            title: ln_t('affiliate.name'),
            dataIndex: 'name',
        },
        {
            title: ln_t('order.id'),
            dataIndex: 'transactionId',
        },
        {
            title: ln_t('order.date'),
            dataIndex: 'orderDate',
            type: 'time',
            format: 'MM/DD/YYYY'
        },
        {
            title: ln_t('order.orderStatus'),
            dataIndex: 'orderStatus',
            render: e => ln_t(`util.orderStatus.${e}`)
        },
        {
            title: ln_t('order.description'),
            dataIndex: 'description',
        },
        {
            title: ln_t('order.orderAmount'),
            dataIndex: 'amount',

            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('order.referralAmount'),
            dataIndex: 'referralAmount',
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('order.expectedToPay'),
            dataIndex: 'expectedToConfirm',
            type: 'time',
            format: 'MM/DD/YYYY'
        },
        {
            title: ln_t('order.updatedAt'),
            dataIndex: 'updatedAt',
            type: 'time',
            format: 'MM/DD/YYYY'
        },
    ]

    const apiFunc = async () => fetchOrderDetails(nowEnterprise?.enterprise, fetchParams)

    return (
        <>
            {
                showBack ? (<div className="primary-link" onClick={() => changeModal(ln_t('payment.transcationDetail'), 'transcationDetail', { confirmedDate })}>{ln_t('payment.goBack')}</div>)
                    : ''
            }
            <MainTable 
                {...props}
                className="order-detail"
                tableName="orderDetail"
                columns={columns}
                rowKey="orderDetailId"
                apiFunc={apiFunc}
                params={params}
            />
        </>
    )
}

export default OrderDetailTable
