import React, { useEffect, useState } from 'react'
import { Form, DatePicker } from 'antd'
import moment from 'moment'
import { fetchAllCampaignByEnterprise } from '../../../apis/campaign'
import Selector from '../../../components/Util/Selector'

const { RangePicker } = DatePicker

function SearchForm(props) {
    const [form] = Form.useForm()

    const { ln_t, handleFetchParams, className, userInfo } = props
    const { nowEnterprise, lang } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const [campList, setCampList] = useState([])
    const [init, setInit] = useState(true)

    const onFinish = fieldValues => {
        const { orderDate } = fieldValues
        let n = {}
        const format = time => time.format('YYYY-MM-DD')
        if (orderDate?.length === 2) {
            const [orderDate_after, orderDate_before] = orderDate
            n = { orderDate_after: format(orderDate_after), orderDate_before: format(orderDate_before) }
        }
        handleFetchParams('init', n)
    }

    const handleCampChange = value => {
        const obj = JSON.parse(value)
        const { startDate, endDate } = obj || {}
        form.setFieldsValue({ orderDate: (endDate && startDate) ? [moment(startDate), moment(endDate)] : undefined })
    }

    const getCampList = async () => {
        const result = await fetchAllCampaignByEnterprise(enterprise)
        if (result?.status === 200) {
            const { results } = result.data
            const n = results.map(camp => ({ label: camp.name, value: JSON.stringify({ startDate: camp.startDate, endDate: camp.endDate, campaignId: camp.campaignId }) }))
            n[0] = { label: `${ln_t('campaign.allCampaigns')}`, value: JSON.stringify({}) }
            setCampList(n)
            setInit(false)
        }
    }

    useEffect(() => {
        getCampList()
    }, [])

    useEffect(() => {
        if (init) return
        const n = [...campList]
        n[0] = { label: `${ln_t('campaign.allCampaigns')}`, value: JSON.stringify({}) }
        setCampList(n)
    }, [lang])

    return (
        <Form 
            form={form}
            className={`d-flex flex-wrap round-selector ${className}`}
            onFinish={onFinish}
            initialValues={{ campaign: JSON.stringify({}) }}
        >
            <Form.Item name="campaign" className="mb-1 mr-3">
                <Selector 
                    options={campList} 
                    placeholder={`${ln_t('campaign.placeholder.selectCampaign')}`} 
                    onChange={value => handleCampChange(value)}
                    style={{ width: '240px' }}
                />
            </Form.Item>
            <Form.Item label={ln_t('util.date')} name="orderDate" className="mb-1 mr-2">
                <RangePicker ranges={{
                    '1 week': [moment().subtract(1, 'week'), moment()],
                    '1 month': [moment().subtract(1, 'month'), moment()],
                    '3 months': [moment().subtract(3, 'months'), moment()],
                    '6 months': [moment().subtract(6, 'months'), moment()],
                    '1 year': [moment().subtract(364, 'days'), moment()],
                }}
                />
            </Form.Item>
            <button className="main-button mb-2" type="submit">{ln_t('util.search')}</button>
        </Form>
    )
}

export default SearchForm
