import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const getPaymentRequest = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/paymentRequest/`, MethodType.GET, params)

export const getPaymentHistory = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/paymentHistory/`, MethodType.GET, params)

export const getPaymentHistoryTotalAmount = async enterpriseId => api.request(1, `/api/v3/enterprise/${enterpriseId}/paymentHistoryTotalAmount/`, MethodType.GET)

export const paying = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/paymentRequest/paying/`, MethodType.PATCH, params)

export const getTransactionDetails = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/transactionDetails/`, MethodType.GET, params)
