import React, { useState } from 'react'
import { message, Button } from 'antd'
import { EditFilled } from '@ant-design/icons'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import { fetchBankVerifyAResuestList, verifyBankAccounts } from '../../../apis/bankVerify'
import BusinessVerifyForm from './BusinessForm'

function BusinessBankVerify(props) {
    const { ln_t, userInfo } = props
    const { nowEnterprise, isAdmin } = userInfo || {}
    const { enterprise } = nowEnterprise || {}

    const params = useParams({ sort: '-createdAt', isBusiness: true })
    const { fetchParams, selectedItem, handleFetchParams } = params

    const [modalVisible, setModalVisible] = useState(false)
    const [editDatas, setEditDatas] = useState()

    const handleEdit = record => {
        setEditDatas(record)
        setModalVisible(true)
    }
    
    const columns = [
        {
            title: ln_t('affiliate.id'),
            dataIndex: 'ouid',
            sorter: true,
        },
        {
            title: ln_t('affiliate.name'),
            dataIndex: 'affiliateName',
            sorter: true,
        },
        {
            title: ln_t('affiliate.email'),
            dataIndex: 'email',
            sorter: true,
        },
        {
            title: ln_t('affiliate.accountName'),
            dataIndex: 'bankAccountName',
            sorter: true,
        },
        {
            title: ln_t('payment.bank'),
            dataIndex: 'bank',
            sorter: true,
        },
        {
            title: ln_t('payment.branchCode'),
            dataIndex: 'bankBranchCode',
            sorter: true,
        },

        {
            title: ln_t('payment.accountNumber'),
            dataIndex: 'bankAccount',
            sorter: true,
        },
        {
            title: ln_t('affiliate.companyName'),
            dataIndex: 'personalInformation',
            sorter: true,
            render: e => e.companyName
        },
        {
            title: ln_t('affiliate.taxID'),
            dataIndex: 'personalInformation',
            sorter: true,
            render: e => e.taxID
        },
        {
            title: ln_t('affiliate.companyPhone'),
            dataIndex: 'personalInformation',
            sorter: true,
            render: e => e.companyPhoneNumber
        },
        {
            title: ln_t('affiliate.companyAddress'),
            dataIndex: 'personalInformation',
            sorter: true,
            render: e => e.companyAddress
        },
        {
            title: ln_t('affiliate.companyWebsite'),
            dataIndex: 'personalInformation',
            sorter: true,
            render: e => e.companyWebsite
        },
        {
            title: ln_t('affiliate.contactName'),
            dataIndex: 'fullName',
            sorter: true,
        },
        {
            title: ln_t('affiliate.info.phone'),
            dataIndex: 'phone',
            sorter: true,
        },
        {
            title: ln_t('verify.requestedDate'),
            dataIndex: 'createdAt',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        },   
        isAdmin ? {
            title: ln_t('util.edit'),
            dataIndex: 'id',
            render: (e, record) => (
                <div className="item-funcs">
                    <EditFilled onClick={() => handleEdit(record)} />
                </div>
            )
        } : {}     
    ]

    const handleVerify = async () => {
        const n = selectedItem.map(item => ({ bankId: item.id }))
        const result = await verifyBankAccounts(enterprise, n)
        if (result?.status === 200) handleFetchParams('init')
    }

    const apiFunc = async () => fetchBankVerifyAResuestList(enterprise, fetchParams)
  
    return (
        <>
            <MainTable 
                {...props}
                className="bankVerify-list"
                tableName="bankVerify"
                columns={columns}
                rowKey="ouid"
                apiFunc={apiFunc}
                params={params}
                useSelection={isAdmin}
            />
            {isAdmin ? (
                <>
                    <div className="text-center mt-5">
                        <Button 
                            type="button"
                            onClick={() => handleVerify()}
                            disabled={!selectedItem?.length}
                        >
                            {ln_t('util.verify')}
                        </Button>
                    </div>
                    <BusinessVerifyForm 
                        {...props}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        handleFetchParams={handleFetchParams}
                        setEditDatas={setEditDatas}
                        editDatas={editDatas}
                    />
                </>
            ) : ''}
        </>
    )
}

export default BusinessBankVerify
