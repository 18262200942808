import { MethodType } from '../constants/MethodType'

const api = require('./api')

export const getAffiliateListByCamp = async (enterpriseId, params) => api.request(1, `/api/v2/enterprise/${enterpriseId}/campaign_affiliate/`, MethodType.GET, params)

export const getAffiliateListByEnterprise = async (enterpriseId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/affiliate/`, MethodType.GET, params)

export const modEnterpriseAffiliate = async (enterpriseId, affiliateId, params) => api.request(1, `/api/v3/enterprise/${enterpriseId}/affiliate/${affiliateId}/`, MethodType.PATCH, params)

export const modCampAffiliate = async (enterpriseId, campId, params) => api.request(1, `/api/v2/enterprise/${enterpriseId}/campaigns/${campId}/update_campaign_affiliate/`, MethodType.PATCH, params)

export const delCampAffiliate = async params => api.request(1, '/api/v2/affiliate/assigncampaign/', MethodType.DELETE, params)

export const delEnterpriseAffiliate = async (enterpriseId, affiliateId, params) => api.request(1, `/api/v2/enterprise/${enterpriseId}/affiliates/${affiliateId}/`, MethodType.DELETE, params)

export const newAffiliate = async params => api.request(1, '/api/v3/affiliate/', MethodType.POST, params)

export const modStatusTc = async (affiliateId, enterpriseId, params) => api.request(1, `/api/v3/affiliate/${affiliateId}/enterprise/${enterpriseId}/affent/update_tc/`, MethodType.PATCH, params)
