import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EzTravelRules from './ezTravel/BrandRules'
import EzTravelTerms from './ezTravel/Terms'
import LionTravelRules from './lionTravel/BrandRules'
import LionTravelTerms from './lionTravel/Terms'
import PizzaHutRules from './pizzaHut/BrandRules'
import PizzaHutTerms from './pizzaHut/Terms'

function RuleAndTerms(props) {
    const { activeKey, onTabChange, size, userInfo } = props
    const { t: ln_t } = useTranslation()
    const { enterpriseName } = useParams()
    const { nowEnterprise } = userInfo || {}
    const enterprise = (enterpriseName || nowEnterprise?.name || '').toLowerCase()
    
    const setting = {
        eztravel: { rules: <EzTravelRules size={size} />, term: <EzTravelTerms size={size} /> },
        liontravel: { rules: <LionTravelRules size={size} />, term: <LionTravelTerms size={size} /> },
        pizzahut: { rules: <PizzaHutRules size={size} />, term: <PizzaHutTerms size={size} /> },
    }

    return (
        <Tabs activeKey={activeKey} onChange={onTabChange} type="card">
            <Tabs.TabPane key="brandRules" tab={ln_t('user.brandRules')}>
                {setting[enterprise]?.rules}
            </Tabs.TabPane>
            <Tabs.TabPane key="terms" tab={ln_t('user.termsAndCondition')}>
                {setting[enterprise]?.term}
            </Tabs.TabPane>
        </Tabs>
    )
}

export default RuleAndTerms
