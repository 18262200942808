import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { pick } from 'lodash'
import moment from 'moment'
import MainTable from '../../../components/Util/MainTable'
import useParams from '../../../hook/useParams'
import OrderDetailTable from './OrderDetailTable' 
import { getPaymentRequest, getPaymentHistoryTotalAmount, paying } from '../../../apis/payment'
import { fetchOrderDetails } from '../../../apis/order'

const formatAccount = account => {
    const l = account.length
    return account.split('').map((item, index) => {
        if (((index + 1) % 4) || index + 1 === l) return item
        return `${item} - `
    }).join('')
}

function UnpaidList(props) {
    const { ln_t, setIsLoading, userInfo, modalConf, download } = props
    const { nowEnterprise, isAdmin } = userInfo || {}
    
    const params = useParams({ sort: '-withdrawalDate' })
    const { fetchParams, selectedItem, handleFetchParams, setSelectedItem } = params
    // const { nowEnterprise } = userInfo || {}
    // const { fetchParams } = params
    const [modalTitle, setModalTitle] = useState()
    const [dataType, setDataType] = useState()
    const [dataParam, setDataParam] = useState()
    const [isModalVisible, setModalVisible] = useState(false)
    const [unpaidTotalAmount, setUnpaidTotalAmount] = useState(0)

    const changeModal = (title, dataType, params) => {
        setDataType(dataType)
        setDataParam(params)
        setModalTitle(title)
        setModalVisible(true)
    }

    const handleOk = () => {
        setModalVisible(false)
    }

    const handleSubmit = () => {
        if (!selectedItem.length){
            Modal.warning({
                ...modalConf,
                title: ln_t('message.title.noPaymentData'),
                content: ln_t('message.info.selectPaymentFirst'),
            })
        } else {
            const submitData = async () => {
                setIsLoading(true)
                const payItems = selectedItem.map(items => pick(items, ['affiliate', 'withdrawalDate']))
                const result = await paying(nowEnterprise?.enterprise, payItems)
                if (result?.status === 200) {
                    setSelectedItem([])
                    handleFetchParams('init')
                }
                
                setIsLoading(false)  
            }
    
            Modal.confirm({
                ...modalConf,
                title: ln_t('message.info.confirmPayment'),
                content: (
                    <>
                        {ln_t('payment.totalPayCount')}：{selectedItem.length}
                        <br />
                        {ln_t('payment.totalPayAmount')}：NT ${unpaidTotalAmount.toLocaleString()}
                    </>
                ),
                onOk: async () => submitData()
            })
        }
    }

    const handleDownload = async params => {
        setIsLoading(true)
        const result = await fetchOrderDetails(nowEnterprise?.enterprise, { sort: '-updatedAt', maxResult: 1000, ...params })
        if (result?.status === 200) {
            const formatDate = date => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '')
            const formattedResult = result.data.results.map(({ orderDate, expectedToConfirm, createdAt, updatedAt, withdrawalDate, confirmedDate, ...other }) => ({
                ...other,
                orderDate: formatDate(orderDate),
                expectedToConfirm: formatDate(expectedToConfirm),
                createdAt: formatDate(createdAt),
                updatedAt: formatDate(updatedAt),
                withdrawalDate: formatDate(withdrawalDate),
                confirmedDate: formatDate(confirmedDate),
            }))
            const neededCols = ['ouid', 'name', 'transactionId', 'orderDate', 'orderStatus', 'product', 
                'description', 'amount', 'referralRate', 'referralAmount', 'expectedToConfirm', 'createdAt', 'updatedAt', 
                'withdrawalDate', 'status', 'confirmedDate', 'affiliateBankAccount', 'confirmedByName']
            const customNaming = { ouid: ln_t('affiliate.id'), name: ln_t('affiliate.name') }
            const colNames = Object.assign({}, ...neededCols.map(col => ({ [col]: customNaming[col] || ln_t(`order.${col}`) })))
            const n = formattedResult.map(item => pick(item, neededCols))
            download([[colNames, ...n]], `${ln_t('payment.tabMenu.newRequest')}_${ln_t('payment.orderDetails')}`, true)
        }
        setIsLoading(false)
    }

    const columns = [
        {
            title: ln_t('affiliate.id'),
            dataIndex: 'ouid',
            sorter: true,
        },
        {
            title: ln_t('affiliate.accountName'),
            dataIndex: 'bankAccountName',
            sorter: true,
        },
        {
            title: ln_t('payment.bank'),
            dataIndex: 'bank',
            sorter: true,
        },    
        {
            title: ln_t('payment.branchCode'),
            dataIndex: 'branchCode',
            sorter: true,
        },            
        {
            title: ln_t('payment.accountNumber'),
            dataIndex: 'affiliateBankAccount',
            sorter: true,
            render: (e, record) => (e ? formatAccount(e) : '-')
        },
        {
            title: ln_t('payment.referralAmount'),
            dataIndex: 'referralAmount',
            sorter: true,
            type: 'number',
            prefix: '$'
        },
        {
            title: ln_t('payment.withdrawalDate'),
            dataIndex: 'withdrawalDate',
            sorter: true,
            type: 'time',
            format: 'MM/DD/YYYY'
        },     
        {
            title: ln_t('payment.orderDetails'),
            dataIndex: 'orderCount',
            render: (e, record) => (
                e === 0 ? '' 
                    : (
                        <div className="primary-link" onClick={() => changeModal(ln_t('payment.orderDetail'), 'orderDetail', { affiliate: record.affiliate, withdrawalDate: record.withdrawalDate })}>
                            {e} {ln_t('payment.detailUnit')}
                        </div>
                    )),
        },                     
        {
            title: ln_t('util.download'),
            dataIndex: 'orderCount',
            render: (e, record) => (
                e === 0 ? '-' 
                    : (
                        <div className="primary-link" onClick={() => handleDownload({ affiliate: record.affiliate, withdrawalDate: record.withdrawalDate })}>
                            {ln_t('util.download')}
                        </div>
                    )),
        },                     
    ]

    useEffect(() => {
        // console.log(selectedItem)
        let totalAmount = 0
        selectedItem.forEach(item => {
            totalAmount += item.referralAmount
        })
        setUnpaidTotalAmount(totalAmount)
    }, [selectedItem])

    const apiFunc = async () => getPaymentRequest(nowEnterprise?.enterprise, fetchParams)

    const footer = () => (
        <div className="text-right">{`${ln_t('payment.unpaidTotalAmount')}：NT $${Number(unpaidTotalAmount).toLocaleString()}`}</div>
    )

    return (
        <>
            <MainTable 
                {...props}
                className="new-payment"
                tableName="newPayment"
                columns={columns}
                rowKey="affiliate_withdrawalDate"
                apiFunc={apiFunc}
                params={params}
                useHeader={isAdmin}
                btnTitle={ln_t('payment.confirm')}
                onBtnClick={handleSubmit}
                useSelection={isAdmin}
                footer={footer}
            />
            <Modal 
                title={modalTitle} 
                visible={isModalVisible} 
                onCancel={handleOk} 
                width={1300} 
                footer={false}
            >
                {
                    isModalVisible 
                        ? <OrderDetailTable {...props} dataParam={dataParam} changeModal={changeModal} showBack={false} />  
                        : '' 
                }
            </Modal>
        </>
    )
}

export default UnpaidList
